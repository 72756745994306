import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import moment from "moment";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";

require('dotenv').config();

class OSClientsDetail extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            showDialog: false,
            detail: '',
            client_name: '',

            case: [],
            pending: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - OS Client';


        this.setState({
            loading: true,
        });
        this.__fetchData(false);

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __fetchData = update => {
        const id = this.props.match.params.id;
        let route = '/reports/'+id+'/os-clients';

        Api.get(route).then(resp => {
            if (resp.data) {
                let data = resp.data;
                let name = '';
                if(data.case.length > 0 ) {
                    name = data.case[0].client_name;
                }else if(data.pending.length > 0) {
                    name = data.pending[0].client_name;
                }
                this.setState({
                    case: data.case,
                    client_name: name,
                    pending: data.pending,
                    loading: false,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'on progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };


    handleExport = () => {
        const id = this.props.match.params.id;
        let route = '/reports/'+id+'/os-clients?export=true';

        Api.get(route).then(resp => {
            if (resp.data) {
                let url = resp.data.url;
                window.open(url);
                this.setState({
                    url: resp.data && resp.data.url,
                    loadingButton: false,
                })

            }
        }).catch(err => {
            console.log(err);
            this.setState({
                loadingButton: false,
            })
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">OUSTANDING LIST {this.state.client_name} </h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/os-clients" >OS Clients</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5 text-right">
                    <Button
                        variant='contained'
                        color='primary'
                        className='mb-3 mb-md-0'
                        onClick={() => this.handleExport()}
                    >
                        Export { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                    </Button>
                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <h2 className='section-title'>ON GOING CASE(S)</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>No</TableCell>
                                    <TableCell className='text-center'>No Case</TableCell>
                                    <TableCell className='text-center'>Vessel Name</TableCell>
                                    <TableCell className='text-center'>Casualty</TableCell>
                                    <TableCell className='text-center'>Appointment Date</TableCell>
                                    <TableCell className='text-center'>Type</TableCell>
                                    <TableCell className='text-center'>Status</TableCell>
                                    <TableCell className='text-center'>Survey Status</TableCell>
                                    <TableCell className='text-center'>Comment Client </TableCell>
                                    <TableCell className='text-center'>Last Project Comment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.case.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={10} style={{ textAlign: "center" }}>No Case</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.case.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={'/projects/detail/'+row.project_id} target='_blank' className='text-uppercase'>{row.case_number === null ? 'NA' : row.case_number}[{row.project_number === null ? 'NA' : row.project_number}]</a>
                                                </TableCell>
                                                <TableCell>{row.vessel_name === null ? 'NA' : row.vessel_name}</TableCell>
                                                <TableCell>{row.casualty === null ? 'NA' : row.casualty}</TableCell>
                                                <TableCell>{row.instruction_date && moment(row.instruction_date,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                                <TableCell>{row.project_type}[{row.detail_type}]</TableCell>
                                                <TableCell>
                                                    <span className={'label-status label-'+this.filterStatus(row.project_status !== null && row.project_status)}>{row.project_status === null ? '' : row.project_status}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'label-status label-'+this.filterStatus(row.survey_status !== null && row.project_status)}>{row.survey_status === null ? '' : row.survey_status}</span>
                                                </TableCell>
                                                <TableCell>{row.client_comment === null ? 'NA' : row.client_comment}</TableCell>
                                                <TableCell>{row.last_comment === null ? 'NA' : row.last_comment} <br/><a href={'/clients/edit/'+row.client_id} className='btn btn-blue'>COMMENT</a> </TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>

                        <hr />
                        <h2 className='section-title'>OUTSTANDING INVOICE (S)</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>No</TableCell>
                                    <TableCell className='text-center'>No Case</TableCell>
                                    <TableCell className='text-center'>Vessel Name</TableCell>
                                    <TableCell className='text-center'>Casualty</TableCell>
                                    <TableCell className='text-center'>Type</TableCell>
                                    <TableCell className='text-center'>Invoice No</TableCell>
                                    <TableCell className='text-center'>Date Issued Invoice</TableCell>
                                    <TableCell className='text-center'>Shared%</TableCell>
                                    <TableCell className='text-center'>Amount</TableCell>
                                    <TableCell className='text-center'>Status</TableCell>
                                    <TableCell className='text-center'>Remark </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={11} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.pending.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={11} style={{ textAlign: "center" }}>No Invoice</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.pending.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={'/projects/detail/'+row.project_id} target='_blank' className='text-uppercase'>{row.case_number === null ? 'NA' : row.case_number}[{row.project_number === null ? 'NA' : row.project_number}]</a>
                                                </TableCell>
                                                <TableCell>{row.vessel_name === null ? 'NA' : row.vessel_name}</TableCell>
                                                <TableCell>{row.casualty === null ? 'NA' : row.casualty}</TableCell>
                                                <TableCell>{row.project_type}[{row.detail_type}]</TableCell>
                                                <TableCell>{row.invoice_number === null ? 'NA' : row.invoice_number}</TableCell>
                                                <TableCell>{row.date_issued && moment(row.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                                <TableCell>{row.share_percentage === null ? 'NA' : row.share_percentage}</TableCell>
                                                <TableCell>
                                                    {row.currency === 'IDR' && 'Rp '}
                                                    {row.currency === 'USD' && '$ '}
                                                    <NumberFormat thousandSeparator={true} value={row.total_amount} displayType={'text'} renderText={value => value}/></TableCell>
                                                <TableCell>
                                                    <span className={'label-status label-'+this.filterStatus(row.status_invoice !== null && row.status_invoice)}>{row.status_invoice === null ? '' : row.status_invoice}</span>

                                                </TableCell>
                                                <TableCell>{row.remarks === null ? 'NA' : row.remarks}</TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                </div>



                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default OSClientsDetail;
