import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Api from "../../../libraries/api";
import Collapse from '@material-ui/core/Collapse';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import moment from "moment";
import {history} from "../../../shared/configure-store";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


require('dotenv').config();

class CaseMonth extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,

            open: '',

            case_number: '',
            project_number: '',
            ext_project_number: '',
            vessel_name: '',
            casualty: '',
            client_id: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            projectStatus: '',
            surveyorId: '',

            clients: [],
            project_status: [],
            surveyors: [],
            report_status: [],
            approval_status: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - New Case Month';

        this.__fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __fetchData = update => {
        this._isMounted = true;

        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let route = '';

        if(search) {
            route = '/projects?dashboard=IssueReportWeek&limit='+this.state.perPage+sort+search;
        }else {
            route = '/projects?dashboard=IssueReportWeek&limit='+this.state.perPage+'&page='+page+sort+search;
        }


        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {

                    this.setState({
                        rows: resp.data,
                        perPage: resp.meta.pagination.per_page,
                        currentPage: resp.meta.pagination.current_page,
                        currentPageTable: resp.meta.pagination.current_page - 1,
                        total: resp.meta.pagination.total,
                        loading: false,
                        loadingButton: false,
                        showDialogFilter: false,
                        isEdit: resp.meta.custom.isEdit,
                        isDelete: resp.meta.custom.isDelete,
                        isCreate: resp.meta.custom.isCreate,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/projects/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                    showDialog: false,
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                    showDialog: false
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    setOpen = (index) => {
        if(this.state.open === index) {
            this.setState({
                open : false,
            });
        }else {
            this.setState({
                open : index,
            });
        }

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Issued Report This Week</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Projects</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <TableContainer>
                            <Table className="table-list" size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell> </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('caseNumber')} >Case No
                                            {this.state.orderBy === 'caseNumber' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('type')} >Project Type
                                            {this.state.orderBy === 'type' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('vesselName')} >Vessel
                                            {this.state.orderBy === 'vesselName' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>

                                        <TableCell className="clickable" onClick={() => this.handleSort('updated_at')} >Last Status Date
                                            {this.state.orderBy === 'updated_at' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={5} style={{ textAlign: "center" }}>No project</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map((row,idx) => (
                                                <React.Fragment key={idx}>
                                                    <TableRow
                                                        key={row.id}
                                                        selected={row.selected}>
                                                        <TableCell>
                                                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen('open-'+idx)}>
                                                                {this.state.open === 'open-'+idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={"/projects/detail/" + row.id} target="_blank">
                                                                {row.caseNumber === null ? '' : row.caseNumber}{row.projectNumber === null ? '' : '['+row.projectNumber+']'}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{row.type === null ? '' : row.type.name}{row.detailType === null ? '' : '['+row.detailType.name+']'}</TableCell>
                                                        <TableCell>{row.vesselName === null ? '' : row.vesselName}</TableCell>
                                                        <TableCell>{row.updated_at === null ? '' : moment(row.updated_at).format('DD MMM YYYY')}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}  colSpan={5}>
                                                            <Collapse in={this.state.open === 'open-'+idx} timeout="auto" >
                                                                <div className='table-card'>
                                                                    <p><strong>Casualties: </strong>{row.casualty}</p>
                                                                    <p><strong>Survey Date: </strong>{row.dateSurveyStatus === null ? '' : moment(row.dateSurveyStatus).format('DD MMM YYYY')}</p>
                                                                    <p><strong>Survey Status: </strong>
                                                                        <span className='label-status label-green d-inline mr-2'>{row.surveyStatus === null ? '' : row.surveyStatus.name}</span>
                                                                        {row.dateSurveyStatus ? row.dateSurveyStatus : ''}</p>
                                                                    <p><strong>Project Status: </strong>
                                                                        {row.projectStatus &&
                                                                        <span className='label-status label-blue d-inline mr-2'>{row.projectStatus === null ? '' : row.projectStatus.name}</span>
                                                                        }
                                                                        {row.dateStatus ? row.dateStatus : ''}</p>
                                                                    <p><strong>Comment: </strong><br />{row.comment ? row.comment.map((item, idx) => (
                                                                        <span className='d-block' key={idx}>- {item.comment}</span>
                                                                    )): ''}</p>
                                                                </div>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>

                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default CaseMonth;
