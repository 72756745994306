import React,{Component} from 'react';
import img from '../../../images/home.png';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import iconNewW from '../../../images/blue-2.png';
import iconNewM from '../../../images/blue-3.png';
import iconReport from '../../../images/icon-3.png';
import iconApproval from '../../../images/icon-4.png';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import user from "../../../images/user.png";

import Api from "../../../libraries/api";
import moment from "moment/moment";

class Home extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            loading6: false,
            loading7: false,
            loading8: false,
            loading9: false,
            loading10: false,
            loading11: false,
            loading12: false,

            data1: 0,
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            data6: 0,
            data7: 0,
            data8: 0,
            data9: 0,
            data10: 0,
            data11: 0,
            data12a: 0,
            data12b: 0,

            logins: [],
            totalLogins: 0,
            perPageLogins: 10,
            currentPageLogins: 1,
            currentPageTableLogins: 0,
            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Timeline';

        this.setState({
            loading: true,
            loading1: true,
            loading2: true,
            loading3: true,
            loading4: true,
            loading5: true,
            loading12: true,

        });

        this.__fetchData(false);
        this.__fetchDataLogin(false);

        Api.get('/dashboards/project-comment-seven-days').then(resp => {

            if (resp.data.length > 0) {
                this.setState({
                    data1: resp.data[0].total,
                    loading1: false
                })

            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading1: false
            })
        });


    }

    __fetchData = update => {
        this.setState({
            loading: true,
        });
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';

        let route = '';

        if(search) {
            route = '/internal/client-comments?sortedBy=desc&orderBy=created_at&limit='+this.state.perPage+sort+search;
        }else {
            route = '/internal/client-comments?sortedBy=desc&orderBy=created_at&limit='+this.state.perPage+'&page='+page+sort+search;
        }

        Api.get(route).then(resp => {
            if (resp.data) {
                let data = this.state.rows;
                Array.prototype.push.apply(data,resp.data);

                console.log(data);
                this.setState({
                    rows: data,
                    perPage: resp.meta.pagination.per_page,
                    currentPage: resp.meta.pagination.current_page,
                    currentPageTable: resp.meta.pagination.current_page - 1,
                    total: resp.meta.pagination.total,
                    loading: false,
                    isEdit: resp.meta.custom.isEdit,
                    isDelete: resp.meta.custom.isDelete,
                    isCreate: resp.meta.custom.isCreate,
                })

            }
        }).catch(err => {
            console.log(err);
        });
    };

    __fetchDataLogin = update => {
        this.setState({
            loading2: true,
        });
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let route = '/activity-login/clients?sortedBy=desc&orderBy=created_at&limit='+this.state.perPage+'&page='+page;

        Api.get(route).then(resp => {
            if (resp.data) {
                let data = this.state.logins;
                Array.prototype.push.apply(data,resp.data);

                this.setState({
                    logins: data,
                    perPageLogins: resp.meta.pagination.per_page,
                    currentPageLogins: resp.meta.pagination.current_page,
                    currentPageTableLogins: resp.meta.pagination.current_page - 1,
                    totalLogins: resp.meta.pagination.total,
                    loading2: false,
                })

            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleChangePage = () => {
        this.setState({
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangePageLogin = () => {
        this.setState({
            loading2: true,
        }, () => {
            this.__fetchDataLogin(true);
        });
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    render() {

        return (

            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">AIS Timeline Updates</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                        </ol>
                    </nav>


                    <div className="row mt-4">
                        <div className='col-md-9 '>
                            <div className='comment-wrap home'>

                                <div className='comment-container'>
                                    { this.state.loading ?
                                        <div className='row mb-2'>
                                            <div className='col img-avatar'>
                                                <Skeleton variant='circle' width={60} height={60} />
                                            </div>
                                            <div className='col'>
                                                <Skeleton variant='text' />
                                                <Skeleton variant='text' />
                                            </div>
                                        </div> :
                                        this.state.rows.length === 0 ?
                                            <div className='col img-avatar mb-2'>
                                                <p>no comment</p>
                                            </div>
                                            :
                                            this.state.rows.map(row => (
                                                <div className='comment-list row pt-3' key={row.id}>
                                                    {row.user !== null ?
                                                    <div className='col img-avatar'>
                                                        <img src={row.user ? row.user.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+row.user.avatar : user : user} alt='avatar' className='img-fluid' />
                                                    </div> :
                                                        <div className='col img-avatar'>
                                                            <span>CPA</span>
                                                        </div>
                                                    }

                                                    <div className='col-md-10'>
                                                        {row.user !== null &&
                                                        <h4>{row.user ? row.user.name : ''}</h4>
                                                        }
                                                        {row.client !== null &&
                                                        <h4>{row.client ? row.client.name : ''}</h4>
                                                        }
                                                        <p className='comment'>
                                                            <strong>Updates/comment:</strong> {row.comment}</p>
                                                        <time>{moment(row.created_at).format('DD MMM YYYY HH:mm:ss')}</time>
                                                        {row.image &&
                                                        <img
                                                            src={process.env.REACT_APP_API_STORAGE_PATH + row.image}
                                                            alt='avatar' className='img-fluid'/>
                                                        }
                                                    </div>
                                                    {/*<div className='col-12 mt-3 pb-3'>*/}
                                                    {/*    <div className='row'>*/}
                                                    {/*        <div className='col-6 col-md-4'>*/}
                                                    {/*            <p>*/}
                                                    {/*                <strong className='d-block'>Case/Project number:</strong>*/}
                                                    {/*            <Link to={ + row.project.data.length > 0 ? '/projects/detail/'+row.project.data[0].id : '/'} target='_blank'>*/}
                                                    {/*                {row.project.data.length > 0 ? row.project.data[0].caseNumber : ''}{row.project.data.length > 0 ? '['+row.project.data[0].projectNumber+']' : ''}*/}
                                                    {/*            </Link>*/}
                                                    {/*            </p>*/}
                                                    {/*            <p>*/}
                                                    {/*                <strong className='d-block'> Type:</strong>*/}
                                                    {/*                {row.project.data.length > 0 ? row.project.data[0].type : ''} - {row.project.data.length > 0 ? row.project.data[0].detailType : ''}*/}
                                                    {/*            </p>*/}


                                                    {/*        </div>*/}
                                                    {/*        <div className='col-6 col-md-4'>*/}
                                                    {/*            <p>*/}
                                                    {/*                <strong className='d-block'>Vessel:</strong> {row.project.data.length > 0 ? row.project.data[0].vesselName : ''}*/}
                                                    {/*            </p>*/}
                                                    {/*            <p><strong className='d-block'>Underwriter:</strong>*/}
                                                    {/*                {*/}
                                                    {/*                    row.project.data.length > 0 &&*/}
                                                    {/*                    row.project.data[0].underWriters.length > 0 &&*/}
                                                    {/*                    row.project.data[0].underWriters.map((option, id) => (*/}
                                                    {/*                        <span className='d-block' key={id}>- {option.names}</span>*/}
                                                    {/*                    ))*/}
                                                    {/*                }*/}
                                                    {/*            </p>*/}
                                                    {/*            <p>*/}
                                                    {/*                <strong className='d-block'>Casualty:</strong> {row.project.data[0].casualty !== null ? row.project.data[0].casualty.substring(0, 20)+'...' : ''}*/}
                                                    {/*            </p>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='col-6 col-md-4'>*/}

                                                    {/*            <p>*/}
                                                    {/*                <strong className='d-block'>Project Status:</strong>*/}
                                                    {/*                <span className={'label-status d-inline-block label-'+this.filterStatus(row.project.data.length > 0 ? row.project.data[0].projectStatusBar : '')}>{row.project.data.length > 0 ? row.project.data[0].projectStatusBar : ''}</span>*/}

                                                    {/*            </p>*/}
                                                    {/*            <p>*/}
                                                    {/*                <strong className='d-block'>Survey Status:</strong>*/}
                                                    {/*                <span className={'label-status d-inline-block label-'+this.filterStatus(row.project.data.length > 0 ? row.project.data[0].surveyStatus : '')}>{row.project.data.length > 0 ? row.project.data[0].surveyStatus : ''}</span>*/}
                                                    {/*            </p>*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            ))
                                    }
                                    <div className='text-center'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className=""
                                            onClick={this.handleChangePage}
                                        >
                                            Load More
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 '>
                            <h2 className="page-title">Login Activity</h2>
                            <div className='comment-wrap home'>

                                <div className='comment-container'>
                                    { this.state.loading2 ?
                                        <div className='row mb-2'>
                                            <div className='col'>
                                                <Skeleton variant='text' />
                                                <Skeleton variant='text' />
                                            </div>
                                        </div> :
                                        this.state.logins.length === 0 ?
                                            <div className='col img-avatar mb-2'>
                                                <p>no data</p>
                                            </div>
                                            :
                                            this.state.logins.map(row => (
                                                <div className='comment-list row pt-3' key={row.id}>

                                                    <div className='col-12'>
                                                        {row.name !== null &&
                                                        <h4>{row.name ? row.name : ''}</h4>
                                                        }
                                                        {row.type !== null &&
                                                        <h4>Type: {row.type ? row.type : ''}</h4>
                                                        }
                                                        <p className='comment'>
                                                            <strong>Last login:</strong>
                                                        <time> {moment(row.login_at).format('DD MMM YYYY HH:mm:ss')}</time>
                                                        </p>

                                                    </div>

                                                </div>
                                            ))
                                    }
                                    <div className='text-center'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className=""
                                            onClick={this.handleChangePageLogin}
                                        >
                                            Load More
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        )

    }
}

export default Home;