import React, {Component} from 'react';
import ContentDashboard from "../content-dashboard";
import Charts from "../content-dashboard/chartsNew";
import NotFound from "../not-found";
import {Switch} from 'react-router-dom'
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import Users from "../users";
import UsersAdd from "../users/add";
import UsersEdit from "../users/edit";
import Roles from "../roles";
import RolesEdit from "../roles/edit";
import Types from "../types";
import TypesAdd from "../types/add";
import TypesEdit from "../types/edit";

import AuthHelper from "../../../libraries/auth-helper";

import {PrivateRoute} from '../../../libraries/route-handling'
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";
import TypesDetail from "../types-detail";
import TypesDetailAdd from "../types-detail/add";
import TypesDetailEdit from "../types-detail/edit";
import Clients from "../clients";
import ClientsAdd from "../clients/add";
import ClientsEdit from "../clients/edit";
import ClientsType from "../clients/types";
import Projects from "../projects";
import ProjectsAdd from "../projects/add";
import ProjectsEdit from "../projects/edit";
import ProjectsDetail from "../projects/detail";
import ProjectsFinance from "../projects/finance";
import OSClients from "../os-clients";
import OSClientsDetail from "../os-clients/detail";
import TimelineClient from "../home/timeline";
import ToDoList from "../to-do-list";

import ProjectsFilter from "../projects-filter";
import ProjectsWeek from "../projects-filter/case-week";
import ProjectsMonth from "../projects-filter/case-month";
import ProjectsIssued from "../projects-filter/issued";
import ReportWeek from "../projects-filter/report-week";
import ReportMonth from "../projects-filter/report-month";
import Reports from "../projects-filter/reports";
import Approval from "../projects-filter/approval";
import PendingIssued from "../projects-filter/pending-issued";
import PendingPayment from "../projects-filter/pending-payment";

import MyOnGoing from "../tasks/my";
import SurveyorOnGoing from "../tasks/surveyor";
import AdminOnGoing from "../tasks/admin";

import Home from "../home";
import Events from "../events";
import Invoices from "../invoices";
import IssuedWeek from "../projects-filter/issued-week";
import IssuedMonth from "../projects-filter/issued-month";
import Complete from "../projects-filter/complete";
import Profile from "../profile/edit";

import OperationStatement from "./statement";
import OperationStatementDetail from "./statement-detail";
import DashboardFinance from "./finance";
import DashboardFinanceDetail from "./finance-detail";
import DashboardInvoiceDetail from "./invoice-detail";

const styleSide = {
    //maxWidth: '280px',
    maxWidth: '0',
    background: '#fbfbfb'
};

const styleDashboard = {
    zIndex: '5',
    position: 'relative',
};

class Dashboard extends Component {

    /*constructor(props) {

        super(props);

    }*/

    componentDidMount() {
        if(AuthHelper.isLoggedIn()){
            this.props.setProfile(AuthHelper.getProfile());
        }else{
            history.push('/login');
        }
    }

    render() {
        return (

            <div>
                <Navbar />

                <main className={"dashboard " + (this.props.toggle_sidebar_state ? "show" : "")  }>

                    <div className="container-fluid">

                        <div className="row">

                            <div className="col-xl px-0" style={styleSide}>

                                <Sidebar/>

                            </div>

                            <div className="col-xl dashbboard-container" style={styleDashboard}>

                                <Switch>

                                    <PrivateRoute exact path="/" component={Home}/>
                                    <PrivateRoute exact path="/timeline-client" component={TimelineClient}/>
                                    <PrivateRoute exact path="/profile" component={Profile}/>
                                    <PrivateRoute exact path="/dashboard" component={ContentDashboard}/>
                                    <PrivateRoute exact path="/operation-statement" component={OperationStatement}/>
                                    <PrivateRoute path="/operation-statement/:year/:month" component={OperationStatementDetail}/>
                                    <PrivateRoute exact path="/dashboard-finance" component={DashboardFinance}/>
                                    <PrivateRoute exact path="/dashboard-finance/:year/:month" component={DashboardFinanceDetail}/>
                                    <PrivateRoute path="/dashboard-finance/invoice/:year/:month" component={DashboardInvoiceDetail}/>

                                    <PrivateRoute exact path="/users" component={Users}/>
                                    <PrivateRoute path="/users/add" component={UsersAdd}/>
                                    <PrivateRoute path="/users/edit/:id" component={UsersEdit}/>

                                    <PrivateRoute exact path="/roles" component={Roles}/>
                                    <PrivateRoute path="/roles/permission/:id" component={RolesEdit}/>
                                    <PrivateRoute exact path="/types" component={Types}/>
                                    <PrivateRoute path="/types/add" component={TypesAdd}/>
                                    <PrivateRoute path="/types/edit/:id" component={TypesEdit}/>
                                    <PrivateRoute exact path="/types-detail" component={TypesDetail}/>
                                    <PrivateRoute path="/types-detail/add" component={TypesDetailAdd}/>
                                    <PrivateRoute path="/types-detail/edit/:id" component={TypesDetailEdit}/>

                                    <PrivateRoute exact path="/clients" component={Clients}/>
                                    <PrivateRoute path="/clients/add" component={ClientsAdd}/>
                                    <PrivateRoute exact path="/clients/type" component={Clients}/>
                                    <PrivateRoute exact path="/clients/type/:type" component={ClientsType}/>
                                    <PrivateRoute path="/clients/edit/:id" component={ClientsEdit}/>

                                    <PrivateRoute exact path="/projects" component={Projects}/>
                                    <PrivateRoute path="/projects/add" component={ProjectsAdd}/>
                                    <PrivateRoute path="/projects/edit/:id" component={ProjectsEdit}/>
                                    <PrivateRoute path="/projects/detail/:id" component={ProjectsDetail}/>
                                    <PrivateRoute path="/projects/finance/:id" component={ProjectsFinance}/>

                                    <PrivateRoute exact path="/projects-filter" component={ProjectsFilter} />
                                    <PrivateRoute exact path="/projects-week" component={ProjectsWeek} />
                                    <PrivateRoute exact path="/projects-month" component={ProjectsMonth} />
                                    <PrivateRoute exact path="/report-week" component={ReportWeek} />
                                    <PrivateRoute exact path="/report-month" component={ReportMonth} />
                                    <PrivateRoute exact path="/approval" component={Approval} />
                                    <PrivateRoute exact path="/issued" component={ProjectsIssued} />
                                    <PrivateRoute exact path="/report" component={Reports} />

                                    <PrivateRoute exact path="/my" component={MyOnGoing} />
                                    <PrivateRoute exact path="/surveyor" component={SurveyorOnGoing} />
                                    <PrivateRoute exact path="/admin" component={AdminOnGoing} />

                                    <PrivateRoute exact path="/invoices" component={Invoices} />
                                    <PrivateRoute exact path="/pending-issued" component={PendingIssued} />
                                    <PrivateRoute exact path="/pending-payment" component={PendingPayment} />
                                    <PrivateRoute exact path="/issued-week" component={IssuedWeek} />
                                    <PrivateRoute exact path="/issued-month" component={IssuedMonth} />
                                    <PrivateRoute exact path="/complete" component={Complete} />

                                    <PrivateRoute exact path="/events" component={Events}/>
                                    <PrivateRoute exact path="/charts" component={Charts}/>
                                    <PrivateRoute exact path="/to-do-list" component={ToDoList}/>

                                    <PrivateRoute exact path="/os-clients" component={OSClients}/>
                                    <PrivateRoute path="/os-clients/:id" component={OSClientsDetail}/>

                                    <PrivateRoute component={NotFound}/>
                                </Switch>

                            </div>

                        </div>

                    </div>

                </main>

            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

        toggle_sidebar_state: state.toggle_sidebar_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

