import React,{Component} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from 'react-select';
import TextField from "@material-ui/core/TextField";
import user from "../../../images/default.png";
import {Link, withRouter} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import StatusProject from "../../../images/status-project.png";
import StatusFinance from "../../../images/status-finance.png";
import editImage from "../../../images/new.png";
import StatusOS from "../../../images/status-os.png";
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import MomentUtils from "@date-io/moment/build/index";
import moment from "moment/moment";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import _ from 'lodash';

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorModal = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorComment = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class ProjectsFinance extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingCommentList: false,
            loadingInvoice: false,
            loadingButton: false,
            loadingProject: false,
            loadingSurvey: false,
            loadingComment: false,
            loadingSave: false,
            showDialog: false,
            showDialogDelete: false,
            errors: {},

            clients: [],
            permissions: [],

            date_status: null,
            comment: '',

            case_number: '',
            project_number: '',
            ext_project_number: '',
            case_type: '',
            type: '',
            detail_type: '',
            vessel_name: '',
            vessel_type: '',

            currency: '',
            currency_rate: '',
            service_fee: '',
            expense_reference: '',
            expense_changes: '',
            discount: '',
            tax_percentage: '',
            tax: '',
            total_invoice: '',
            total_diskon: '',
            total_paid: '',
            total_balance: '',
            pph_23_status: '',

            user_comment: '',
            user_id_current: '',

            comment_list: [],

            projects_id: '',
            invoice_number: '',
            invoice_title: '',
            date_issued: null,
            currency_inv: '',
            currency_rate_inv: '',
            client_id: '',
            amount: '',
            total_amount: '',
            amount_words: '',
            share_percentage: '',
            tax_invoice_number: '',
            date_of_tax: null,
            vat_amount: '',
            diskon: 0,
            date_of_tax_21: null,
            tax_23: '',
            date_of_tax_23: null,
            tax_23_remarks: '',
            payer: '',
            bank_receiver: '',
            payment_date: null,
            remarks: '',

            invoiceRow: [],

            top_project_status: '',
            top_finance_status: '',
            top_os: 0,

            showDisable: false,
            editInvoice: false,
            fileInvoice: '',
            fileInvoiceName: '',
            fileReport: '',
            fileReportName: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this);

        this.handleComment = this.handleComment.bind(this);
        this.handleFile = this.handleFile.bind(this);

    }

    componentDidMount() {
        const id = this.props.match.params.id;
        document.title = 'Abadi - Finance Project';

        this.setState({
            loading: true,
            loadingInvoice: true,
            loadingCommentList: true,
            permissions: AuthHelper.getProfile().permissions,
        });

        Api.get('/clients?limit=0').then(resp => {

            if (resp.data) {
                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });

                this.setState({
                    clients: data,
                }, () => this.__fetchInvoice())

            }

        }).catch(err => {
            console.log(err);
        });

        this.__fetchProject();


        Api.get('/expenses?search=projects_id:'+id).then(resp => {

            if (resp.data) {

                this.setState({
                    expense_reference: resp.meta ? parseFloat(resp.meta.custom.totalOut) : 0,
                })
            }

        }).catch(err => {
            console.log(err);
        });

        this.__fetchComment();

    }

    __fetchProject = () => {
        const id = this.props.match.params.id;
        this.setState({
            loading: true,
        });

        Api.get('/projects/'+id).then(resp => {

            if (resp.data) {
                let data = resp.data;

                this.setState({
                    loading: false,
                    case_number: data.caseNumber,
                    project_number: data.projectNumber,
                    ext_project_number: data.extProjectNumber,
                    case_type: data.caseType,
                    type: data.type ? data.type.name : '',
                    detail_type: data.detailType ? data.detailType.id :'',
                    vessel_name: data.vesselName,
                    vessel_type: data.vesselType,
                    casualty: data.casualty,
                    currency: data.currency,
                    currency_rate: data.currencyRate,
                    service_fee: parseFloat(data.serviceFee),
                    expense_changes: data.expenseChanges,
                    discount: data.discount,
                    tax_percentage: data.taxPercentage,
                    tax: data.tax,
                    //total_invoice: data.totalInvoice,
                    //total_balance: data.totalBalance,

                    top_project_status: data.projectStatusBar ? data.projectStatusBar.project_status : '',
                    top_finance_status: data.financeStatus ? data.financeStatus : '',
                    top_os: data.OSAmount ? data.OSAmount : 0,
                })
            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false,
            });
        });
    };

    __fetchComment = () => {
        const id = this.props.match.params.id;
        Api.get('/comments?search=projects_id:'+id+'&limit=0').then(resp => {

            if (resp.data) {

                this.setState({
                    comment_list: resp.data,
                    loadingCommentList: false
                });

                if(this.props.profile_state) {
                    this.setState({
                        user_id_current: this.props.profile_state.id,
                    })
                }
            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loadingCommentList: false,
            });
        });

    };

    convertStringToNumber = (str) =>{
        if(str){
            return parseFloat(str);
        }
        return 0;
    };

    __fetchInvoice = () => {
        const id = this.props.match.params.id;
        Api.get('/invoices?search=projects_id:'+id+'&limit=0').then(resp => {
            if (resp.data) {

                let temp = 0;
                let tax = 0;
                let temp_tax = parseFloat(tax);
                let service_fee = this.state.service_fee;
                let expense_changes = this.state.expense_changes;
                let temp_invoice = 0;
                let total_diskon = this.state.total_diskon;
                let temp_diskon = 0;

                if(resp.data.length > 0) {
                    _.forEach(resp.data, function(v){
                        let amt = parseFloat(v.amount !== null ? v.amount : 0);
                        temp = temp + amt;
                        temp_diskon = temp_diskon + parseFloat(v.diskon);
                        temp_tax = temp_tax + parseFloat(v.vatAmount);
                        //lepas VAT otomatis
                       //v.vatAmount = parseFloat(v.sharePercentage) / 100 * parseFloat(tax);
                        v.tax23 = (parseFloat(service_fee) + parseFloat(expense_changes)) * parseFloat(v.sharePercentage)/100 * 0.02;
                        temp_invoice = temp_invoice + parseFloat(v.totalAmount);
                        v.survey_fee =  parseFloat(v.sharePercentage)/100 * parseFloat(service_fee);
                        v.expenses =  parseFloat(v.sharePercentage)/100 * parseFloat(expense_changes);
                    });
                }
                console.log(temp_invoice)


                this.setState({
                    total_paid: temp,
                    tax: temp_tax,
                    total_invoice: temp_invoice,
                    total_balance: temp_invoice - temp,
                    total_diskon: temp_diskon,
                    invoiceRow: resp.data,
                    loadingInvoice: false,
                });
            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loadingCommentList: false,
            });
        });

    };

    filterName = (id) => {

        let all = this.state.clients.filter((item) => item.id === id);

        if(all.length > 0)
            return all[0].name;
    };

    filterPermission = (name) => {
        let all = this.state.permissions.filter((item) => item === name);

        if(all.length > 0)
            return true;
    };


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        },() => this.sumBalance());

    };

    handleRemove (e, prop){

        e.preventDefault();
        this.setState({

            [prop]: null

        });

    };


    sumBalance = () => {

        let service_fee = this.convertStringToNumber(this.state.service_fee),
            expense_changes = this.convertStringToNumber(this.state.expense_changes),
            total_paid = this.convertStringToNumber(this.state.total_paid),
            discount = this.convertStringToNumber(this.state.discount),
            share_percentage = this.convertStringToNumber(this.state.share_percentage)
        ;
        let tax = this.convertStringToNumber(this.state.tax);

        if(this.state.showDisable) {
            tax = 0.1 * (service_fee + expense_changes);
        }

        let total_invoice = (service_fee + expense_changes + tax) - discount;
        let total_balance = total_invoice - total_paid;
        let vatAmount = parseFloat(share_percentage) / 100 * parseFloat(tax);
        let tax23 = (parseFloat(service_fee) + parseFloat(expense_changes)) * parseFloat(share_percentage)/100 * 0.02;
        let totalAmount = parseFloat(share_percentage)/100 * parseFloat(total_invoice);
        let surveyFee = parseFloat(share_percentage)/100 * parseFloat(service_fee);
        let expenses = parseFloat(share_percentage)/100 * parseFloat(expense_changes);

        this.setState({
            tax: tax,
            //total_invoice: total_invoice,
            //total_balance: total_balance,
            //total_amount: totalAmount,
            //lepas VAT otomatis
            //vat_amount: vatAmount,
            tax_23: tax23,
            survey_fee: surveyFee,
            expenses: expenses,
        });
    };


    handleChangeClient (e, prop){

        this.setState({

            [prop]: e

        });

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    filterStatus = (name) => {
        let text = '';
        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'pending':
                text = 'yellow';
                break;
            case 'paid':
                text = 'green';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = '';
        }

        return text;
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            currency: this.state.currency,
            currency_rate: this.state.currency_rate,
            service_fee: this.state.service_fee,
            expense_changes: this.state.expense_changes,
            discount: this.state.discount,
            /*tax_percentage: this.state.tax_percentage,*/
            tax: this.state.tax,
            total_invoice: this.state.total_invoice,
            total_paid: this.state.total_paid,
            total_balance: this.state.total_balance,
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            this.showMessage(true, 'Summary updated');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });


    };

    handleSubmitInvoice = (e) => {

        e.preventDefault();
        if (!validatorModal.allValid()) {

            this.setState({
                    errors: validatorModal.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );
        let status = 'pending';
        let statusPPH = 'not_received';

        if(this.state.payment_date !== null) {
            status = 'paid';
        }

        if(this.state.date_of_tax_23 !== null) {
            statusPPH = 'Not received';
        }

        let tax = this.state.tax;
        let service_fee = this.state.service_fee;
        let expense_changes = this.state.expense_changes;
        let total_invoice = this.state.total_invoice;

        let vatAmount = parseFloat(this.state.share_percentage) / 100 * parseFloat(tax);
        let tax23 = (parseFloat(service_fee) + parseFloat(expense_changes)) * parseFloat(this.state.share_percentage)/100 * 0.02;
        let surveyFee =  parseFloat(this.state.share_percentage)/100 * parseFloat(service_fee);
        let expenses =  parseFloat(this.state.share_percentage)/100 * parseFloat(expense_changes);

        let totalAmount = parseFloat(expenses) +  parseFloat(surveyFee) + parseFloat(this.state.vat_amount ? this.state.vat_amount : 0) - parseFloat(this.state.diskon  ? this.state.diskon : 0);

        let params = {
            projects_id: this.props.match.params.id,
            invoice_number: this.state.invoice_number,
            invoice_title: this.state.invoice_title,
            date_issued: this.state.date_issued === null ? null : moment(this.state.date_issued).format('YYYY-MM-DD'),
            currency: this.state.currency_inv,
            currency_rate: this.state.currency_rate_inv,
            client_id: this.state.client_id !== null ? this.state.client_id.value : '',
            amount: this.state.amount,
            total_amount: totalAmount,
            amount_words: this.state.amount_words,
            pph_23_status: statusPPH,
            share_percentage: this.state.share_percentage,
            tax_invoice_number: this.state.tax_invoice_number,
            vat_amount: this.state.vat_amount,
            diskon: (this.state.diskon === null || this.state.diskon === 'null') ? 0 : this.state.diskon,
            /*date_of_tax: this.state.date_of_tax === null ? null : moment(this.state.date_of_tax).format('YYYY-MM-DD'),*/
            /*date_of_tax_21: this.state.date_of_tax_21 === null ? null : moment(this.state.date_of_tax_21).format('YYYY-MM-DD'),*/
            tax_23: this.state.tax_23,
            date_of_tax_23: this.state.date_of_tax_23 === null ? null : moment(this.state.date_of_tax_23).format('YYYY-MM-DD'),
            tax_23_remarks: this.state.tax_23_remarks,
            payer: this.state.payer !== null ? this.state.payer.value : '',
            bank_receiver: this.state.bank_receiver,
            payment_date: (this.state.payment_date === null || this.state.payment_date === 'null' || this.state.payment_date === "0000-00-00" ) ? null : moment(this.state.payment_date).format('YYYY-MM-DD'),
            remarks:  this.state.remarks,
            survey_fee:  this.state.survey_fee,
            expenses:  this.state.expenses,
            status_invoice: status,
            file_report: this.state.fileReport,
            file_invoice: this.state.fileInvoice,
        };

        let formData = new FormData();
        formData.append('projects_id', this.props.match.params.id);
        formData.append('invoice_number', this.state.invoice_number);
        formData.append('invoice_title',  this.state.invoice_title);
        formData.append('date_issued', this.state.date_issued === null ? null : moment(this.state.date_issued).format('YYYY-MM-DD'));
        formData.append('currency', this.state.currency_inv);
        formData.append('currency_rate', this.state.currency_rate_inv);
        formData.append('client_id', this.state.client_id !== null ? this.state.client_id.value : '');
        formData.append('amount', this.state.amount);
        formData.append('total_amount', totalAmount);
        formData.append('amount_words', this.state.amount_words);
        formData.append('pph_23_status', this.state.pph_23_status);
        formData.append('share_percentage', this.state.share_percentage);
        formData.append('tax_invoice_number', this.state.tax_invoice_number);
        formData.append('vat_amount', this.state.vat_amount);
        formData.append('diskon', this.state.diskon);
        formData.append('tax_23', this.state.tax_23);
        formData.append('date_of_tax_23', this.state.date_of_tax_23 === null ? null : moment(this.state.date_of_tax_23).format('YYYY-MM-DD'));
        formData.append('tax_23_remarks', this.state.tax_23_remarks);
        formData.append('payer', this.state.payer !== null ? this.state.payer.value : '');
        formData.append('bank_receiver', this.state.bank_receiver);
        formData.append('payment_date', this.state.payment_date === null ? null : moment(this.state.payment_date).format('YYYY-MM-DD'));
        formData.append('remarks', this.state.remarks);
        formData.append('survey_fee', this.state.survey_fee);
        formData.append('expenses', this.state.expenses);
        formData.append('status_invoice', status);
        formData.append('file_invoice', this.state.fileInvoice);
        formData.append('file_report', this.state.fileReport);

        if(this.state.editInvoice) {
                Api.putFile('/invoices/'+this.state.invoice_id,{
                    method: 'POST',
                    body: formData
                }).then(resp => {

                this.setState({
                        loadingButton: false,
                        showDialog :false,
                        invoice_number: '',
                    invoice_title: '',
                        client_id: '',
                        date_issued: null,
                        currency_inv: '',
                        currency_rate_inv: '',
                        amount: '',
                        share_percentage: '',
                        payer: '',
                        total_amount: '',
                        amount_words: '',
                        pph_23_status: '',
                        tax_invoice_number: '',
                       /* date_of_tax: null,*/
                        vat_amount: '',
                        diskon: 0,
                       /* date_of_tax_21: null,*/
                        tax_23: '',
                        date_of_tax_23: null,
                        tax_23_remarks: '',
                        payment_date: null,
                        bank_receiver: '',
                        remarks: '',
                    status_invoice: '',
                    survey_fee: '',
                    expenses: '',
                    }
                );
                this.__fetchProject();
                this.__fetchInvoice();
                this.showMessage(true, 'Invoice updated');

            }).catch(err => {

                if(ErrorHandling.checkErrorTokenExpired(err)){

                }else{

                    this.setState({
                            errors: err.errors,
                            loadingButton: false
                        }
                    );

                    this.showMessage(false, 'Invalid format data');
                }
            });
        }else {
            Api.post('/invoices', params, false).then(resp => {

                this.setState({
                        loadingButton: false,
                        showDialog :false,
                        invoice_number: '',
                    invoice_title: '',
                        client_id: '',
                        date_issued: null,
                        currency_inv: '',
                        currency_rate_inv: '',
                        amount: '',
                        share_percentage: '',
                        payer: '',
                        total_amount: '',
                        amount_words: '',
                        pph_23_status: '',
                        tax_invoice_number: '',
                        /*date_of_tax: null,*/
                        vat_amount: '',
                    diskon: 0,
                        /*date_of_tax_21: null,*/
                        tax_23: '',
                        date_of_tax_23: null,
                        tax_23_remarks: '',
                        payment_date: null,
                        bank_receiver: '',
                        remarks: '',
                    status_invoice: '',
                    survey_fee: '',
                    expenses: '',
                    }
                );
                this.__fetchProject();
                this.__fetchInvoice();
                this.showMessage(true, 'Invoice added');

            }).catch(err => {

                if(ErrorHandling.checkErrorTokenExpired(err)){

                }else{

                    this.setState({
                            errors: err.errors,
                            loadingButton: false
                        }
                    );

                    this.showMessage(false, 'Invalid format data');
                }
            });
        }




    };


    handleFile = e => {
        console.log(e.target.files[0]);
        if(e.target) {
            this.setState({
                fileInvoice: e.target.files[0],
                fileInvoiceName: e.target.files[0].name,
            });
        }

    };
    handleFileReport = e => {
        if(e.target) {
            this.setState({
                fileReport: e.target.files[0],
                fileReportName: e.target.files[0].name,
            });
        }

    };

    handleGo  = (link) => {
        history.push(link);
    };

    handleDeleteFileInvoice = () => {

        Api.post('/invoices/'+this.state.invoice_id+'/delete-file-invoice', '', false).then(resp => {

            this.showMessage(true, 'File successfully deleted');


        }).catch(err => {

            this.showMessage(true, 'File successfully deleted');

        });
        this.setState({
            fileInvoiceName: ''
            }
        );
    }
    handleDeleteFileReport = () => {

        Api.post('/invoices/'+this.state.invoice_id+'/delete-file-report', '', false).then(resp => {

            this.showMessage(true, 'File successfully deleted');


        }).catch(err => {

            this.showMessage(true, 'File successfully deleted');

        });
        this.setState({
            fileReportName: ''
            }
        );
    }

    handleComment = (e) => {
        e.preventDefault();

        if (!validatorComment.allValid()) {

            this.setState({
                    errors: validatorComment.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
            loadingComment: true,
            }
        );

        let params = {
            projects_id: this.props.match.params.id,
            user_id: this.state.user_id_current,
            type: 'comment',
            comment: this.state.user_comment,
        };

        Api.post('/comments', params, false).then(resp => {

            this.setState({
                loadingComment: false,
                },
                () =>  this.__fetchComment()
            );

            this.showMessage(true, 'Comment posted');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingComment: false
                    },
                    () =>  this.__fetchComment()
                );

                this.showMessage(false, 'Invalid format data');
            }
        });
    };

    handleOpen = (e, row) => {
        e.preventDefault();

        if(row) {
            let tax = this.state.tax;
            let service_fee = this.state.service_fee;
            let expense_changes = this.state.expense_changes;
            let total_invoice = this.state.total_invoice;

            let vatAmount = parseFloat(row.sharePercentage) / 100 * parseFloat(tax);
            let tax23 = (parseFloat(service_fee) + parseFloat(expense_changes)) * parseFloat(row.sharePercentage)/100 * 0.02;
            let surveyFee =  parseFloat(row.sharePercentage)/100 * parseFloat(service_fee);
            let expenses =  parseFloat(row.sharePercentage)/100 * parseFloat(expense_changes);
            //old formula
            // let totalAmount = (parseFloat(row.sharePercentage)/100 * parseFloat(total_invoice)) + parseFloat(expense_changes) - parseFloat(row.diskon ? row.diskon : 0);
            let totalAmount = parseFloat(expenses) +  parseFloat(surveyFee) + parseFloat(row.vatAmount) - parseFloat(row.diskon ? row.diskon : 0);

            let payer = {};
            let client = {};

            if(row.client) {
                client =  {
                    label: row.client.name,
                    value: row.client.id,
                }
            }

            if(row.payer) {
                payer =  {
                    label: row.payer.name,
                    value: row.payer.id,
                }
            }
            this.setState({
                invoice_id: row.id !== null ? row.id : '',
                invoice_number: row.invoiceNumber !== null ? row.invoiceNumber : '',
                invoice_title: row.invoiceTitle !== null ? row.invoiceTitle : '',
                client_id: client,
                date_issued: row.dateIssued !== null ? row.dateIssued : null,
                currency_inv: row.currency !== null ? row.currency : '',
                currency_rate_inv: row.currencyRate !== null ? row.currencyRate : '',
                amount: row.amount !== null ? row.amount : '',
                share_percentage: row.sharePercentage !== null ? row.sharePercentage : '',
                payer: payer,
                total_amount: totalAmount,
                amount_words: row.amountWords !== null ? row.amountWords : '',
                tax_invoice_number: row.taxInvoiceNumber !== null ? row.taxInvoiceNumber : '',
                date_of_tax: row.dateOfTax !== null ? row.dateOfTax : null,
                vat_amount: row.vatAmount,
                diskon: (row.diskon === null || row.diskon === 'null') ? 0 : row.diskon,
                //lepas VAT otomatis
                //vat_amount: vatAmount,
                date_of_tax_21: row.dateOfTax21 !== null ? row.dateOfTax21 : null,
                tax_23: tax23,
                pph_23_status: row.pph23Status !== null ? row.pph23Status : 'Not received',
                date_of_tax_23: (row.dateOfTax23 !== null && row.dateOfTax23 !== 'null' && row.dateOfTax23 !== "0000-00-00" ) ? row.dateOfTax23 : null,
                tax_23_remarks: row.tax23Remarks !== null ? row.tax23Remarks : '',
                payment_date: (row.paymentDate !== null && row.paymentDate !== 'null' && row.paymentDate !== "0000-00-00" ) ? row.paymentDate : null,
                bank_receiver: row.bankReceiver !== null ? row.bankReceiver : '',
                remarks: row.remarks !== null ? row.remarks : '',
                fileReport: row.fileReport !== null ? row.fileReport : '',
                fileInvoice: row.fileInvoice !== null ? row.fileInvoice : '',
                survey_fee: surveyFee,
                expenses: expenses,
                fileReportName: row.fileReport !== null ? row.fileReport : '',
                fileInvoiceName: row.fileInvoice !== null ? row.fileInvoice : '',
                editInvoice: true,
            }, () => {

                this.setState({
                    showDialog :true,
                });
            })
        }else {
            this.setState({
                invoice_id: '',
                invoice_number: '',
                client_id: '',
                date_issued: null,
                currency_inv: '',
                currency_rate_inv: '',
                amount: '',
                share_percentage: '',
                payer: '',
                total_amount: '',
                amount_words: '',
                tax_invoice_number: '',
                date_of_tax: null,
                vat_amount: '',
                diskon: '',
                date_of_tax_21: null,
                tax_23: '',
                date_of_tax_23: null,
                tax_23_remarks: '',
                payment_date: null,
                bank_receiver: '',
                remarks: '',
                editInvoice: false,
                fileReport: '',
                fileInvoice: '',
                fileInvoiceName: '',
                fileReportName: '',
            }, () => {
                this.setState({
                    showDialog :true,
                });
            })
        }


    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleOpenDelete = (row) => {
        this.setState({
            showDialogDelete :true,
            idDelete: row.id,
            currDelete: row.invoiceNumber,
        })
    };

    handleCloseDelete = () => {
        this.setState({
            showDialogDelete :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/invoices/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                    showDialogDelete: false,
                    }
                );

                this.showMessage(true, 'Invoice successfully deleted');
                this.__fetchInvoice();
                this.__fetchProject();

            }).catch(err => {

                this.setState({
                    showDialogDelete: false
                    }
                );

                this.showMessage(true, 'Invoice successfully deleted');
                this.__fetchInvoice();
                this.__fetchProject();
            });
        }
    };

    handleShowEdit = () => {

        console.log(this.state.showDisable)
        this.setState({

            showDisable:(!this.state.showDisable)

        })

    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator
                isNumericString
            />
        );
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Detail Finance</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/projects" >Project</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Finance</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-md-4 mt-md-3 pl-lg-5">
                    <div className={"card-abadi "+this.filterStatus(this.state.top_project_status)}>
                        <div className="icon-img">
                            <img src={StatusProject} alt='status' className="img-fluid" />
                        </div>
                        <div className="detail">
                            <p>Project Status</p>
                            <h3>{this.state.top_project_status}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-md-3">
                    <div className={"card-abadi "+this.filterStatus(this.state.top_finance_status)}>
                        <div className="icon-img">
                            <img src={StatusFinance} alt='status' className="img-fluid" />
                        </div>
                        <div className="detail">
                            <p>Finance Status</p>
                            <h3>{this.state.top_finance_status}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-md-3">
                    <div className="card-abadi green">
                        <div className="icon-img">
                            <img src={StatusOS} alt='status' className="img-fluid" />
                        </div>
                        <div className="detail">
                            <p>OS Invoice Amount</p>
                            <h3>
                                <NumberFormat thousandSeparator={true} value={this.state.total_balance === null ? 0 : this.state.total_balance} displayType={'text'} fixedDecimalScale={true} decimalScale={2} renderText={value => <span>{value}</span>}/>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-md mt-3 pl-lg-5 detail-wrap">
                    <div className="btn-group mb-3 mb-lg-4">
                        <button className="btn-tab" onClick={() => this.handleGo('/projects/detail/'+this.props.match.params.id)}>
                            Project Detail
                        </button>
                        <button className="btn-tab active">
                            Finance Detail
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                this.state.loading ?
                                    <div className="table-wrapper detail mb-3">
                                        <h2 className="title-wrap">Information</h2>
                                        <Skeleton variant="rect" className="mb-3" height={130} />
                                    </div> :
                                    <div className="table-wrapper detail mb-3">
                                        <h2 className="title-wrap">Project Information</h2>
                                        <div className="card-detail">
                                            <h3>Internal Data</h3>
                                            <p>Case number: <span>{this.state.case_number}</span></p>
                                            <p>Project number: <span>{this.state.project_number}</span></p>
                                            <p>Project type: <span>{this.state.type}</span></p>
                                            <p>Vessel name: <span>{this.state.vessel_name}</span></p>
                                            <p>Vessel type: <span>{this.state.vessel_type}</span></p>
                                            <p>Casualty: <span>{this.state.casualty}</span></p>
                                            <p>Date Issued: <span>{moment(this.state.dateStatus).format('MMM, DD YYYY')}</span></p>
                                        </div>
                                    </div>
                            }
                        </div>

                        <div className="col-12">
                            <div className="table-wrapper detail mb-3">
                                <h2 className="title-wrap">Summary Finance</h2>
                                <div className="card-detail">
                                    <h3>Summary Finance</h3>
                                    <form name="edit" id="editSummaryFinance" className="row colored" noValidate>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Service Fee</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='service_fee'
                                                           name="service_fee"
                                                           label="Service Fee"
                                                           onChange={(e) => this.handleChange(e, 'service_fee')}
                                                           value={this.state.service_fee}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                {validator.message('service_fee', this.state.service_fee, 'required')}
                                                <div className='text-danger'>{this.state.errors.service_fee}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Currency</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="currency"
                                                           name="currency"
                                                           label="Currency"
                                                           onChange={(e) => this.handleChange(e, 'currency')}
                                                           value={this.state.currency}
                                                           fullWidth
                                                >
                                                    <MenuItem value="IDR">
                                                        IDR
                                                    </MenuItem>
                                                    <MenuItem value="USD">
                                                        USD
                                                    </MenuItem>
                                                    <MenuItem value="SGD">
                                                        SGD
                                                    </MenuItem>
                                                </TextField>
                                                {validator.message('currency', this.state.currency, 'required')}
                                                <div className='text-danger'>{this.state.errors.currency}</div>
                                            </div>
                                        </div>
                                       {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Currency Rate</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='currency_rate'
                                                           name="currency_rate"
                                                           label="Currency Rate"
                                                           onChange={(e) => this.handleChange(e, 'currency_rate')}
                                                           value={this.state.currency_rate}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.currency_rate}</div>
                                            </div>
                                        </div>*/}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Expense Charged</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='expense_changes'
                                                           name="expense_changes"
                                                           label="Expense Charged"
                                                           onChange={(e) => this.handleChange(e, 'expense_changes')}
                                                           value={this.state.expense_changes}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                {validator.message('expense_changes', this.state.expense_changes, 'required')}
                                                <div className='text-danger'>{this.state.errors.expense_changes}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Expense Reference</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='expense_reference'
                                                           name="expense_reference"
                                                           label="Expense Reference"
                                                           onChange={(e) => this.handleChange(e, 'expense_reference')}
                                                           value={this.state.expense_reference}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                           disabled
                                                />
                                                {validator.message('expense_reference', this.state.expense_reference, 'required')}
                                                <div className='text-danger'>{this.state.errors.expense_reference}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>VAT Amount</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='tax'
                                                           name="tax"
                                                           label="VAT Amount"
                                                           onChange={(e) => this.handleChange(e, 'tax')}
                                                           value={this.state.tax}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                           // InputProps={{
                                                           //     inputComponent: this.NumberFormatCustom,
                                                           //     endAdornment: (
                                                           //         <InputAdornment position="end">
                                                           //             <IconButton
                                                           //                 aria-label="Toggle password visibility"
                                                           //                 onClick={this.handleShowEdit}
                                                           //             >
                                                           //                 <i className="fas fa-edit"> </i>
                                                           //             </IconButton>
                                                           //         </InputAdornment>
                                                           //     )
                                                           // }}
                                                           disabled={true}
                                                />
                                                {validator.message('tax', this.state.tax, 'required')}
                                                <div className='text-danger'>{this.state.errors.tax}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Total Paid</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='total_paid'
                                                           name="total_paid"
                                                           label="Total Paid"
                                                           onChange={(e) => this.handleChange(e, 'total_paid')}
                                                           value={this.state.total_paid}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           disabled
                                                           fullWidth
                                                />
                                                {validator.message('total_paid', this.state.total_paid, 'required')}
                                                <div className='text-danger'>{this.state.errors.total_paid}</div>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6">
                                            <div className="form-group">
                                                <label>Discount</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='discount'
                                                           name="discount"
                                                           label="Discount"
                                                           onChange={(e) => this.handleChange(e, 'discount')}
                                                           value={this.state.discount}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                {validator.message('discount', this.state.discount, 'required')}
                                                <div className='text-danger'>{this.state.errors.discount}</div>
                                            </div>
                                        </div>*/}
                                       {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tax Percentage</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='tax_percentage'
                                                           name="tax_percentage"
                                                           label="Tax Percentage"
                                                           onChange={(e) => this.handleChange(e, 'tax_percentage')}
                                                           value={this.state.tax_percentage}
                                                           fullWidth
                                                />
                                                {validator.message('tax_percentage', this.state.tax_percentage, 'required')}
                                                <div className='text-danger'>{this.state.errors.tax_percentage}</div>
                                            </div>
                                        </div>*/}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Total Outstanding Balance</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='total_balance'
                                                           name="total_balance"
                                                           label="Total Outstanding Balance"
                                                           onChange={(e) => this.handleChange(e, 'total_balance')}
                                                           value={this.state.total_balance}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                           disabled
                                                />
                                                {validator.message('total_balance', this.state.total_balance, 'required')}
                                                <div className='text-danger'>{this.state.errors.total_balance}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Total Invoice</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='total_invoice'
                                                           name="total_invoice"
                                                           label="Total Invoice"
                                                           onChange={(e) => this.handleChange(e, 'total_invoice')}
                                                           value={this.state.total_invoice}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                           disabled
                                                />
                                                {validator.message('total_invoice', this.state.total_invoice, 'required')}
                                                <div className='text-danger'>{this.state.errors.total_invoice}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Total Discount</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='total_diskon'
                                                           name="total_diskon"
                                                           label="Total Discount"
                                                           onChange={(e) => this.handleChange(e, 'total_diskon')}
                                                           value={this.state.total_diskon}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                           disabled
                                                />
                                                <div className='text-danger'>{this.state.errors.total_diskon}</div>
                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="py-2 px-4 m-0"
                                                onClick={this.handleSubmit}
                                                disabled={this.state.loadingButton && 'disabled' }
                                            >
                                                Save Changes{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    this.filterPermission('list-comments') &&
                    <div className="col-md mt-3 comment-wrap">
                        <h3>Comment</h3>

                        <div className="comment-container">
                            { this.state.loadingCommentList ?
                                <div className="row mb-2">
                                    <div className="col img-avatar">
                                        <Skeleton variant="circle" width={60} height={60} />
                                    </div>
                                    <div className="col">
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </div>
                                </div> :
                                this.state.comment_list.length === 0 ?
                                    <div className="col img-avatar mb-2">
                                        <p>no comment</p>
                                    </div>
                                    :
                                    this.state.comment_list.map(row => (
                                        <div className="row mb-2" key={row.id}>
                                            <div className="col img-avatar">
                                                <img src={row.user ? row.user.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+row.user.avatar : user : user} alt='avatar' className="img-fluid" />
                                            </div>
                                            <div className="col">
                                                <h4>{row.user ? row.user.name : ''}</h4>
                                                <p>{row.comment}</p>
                                                <time>{moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}</time>
                                            </div>
                                        </div>
                                    ))
                            }
                            {
                                this.filterPermission('create-comments') &&
                                <form>
                                    <input
                                        name="user_comment"
                                        onChange={(e) => this.handleChange(e, 'user_comment')}
                                        value={this.state.user_comment}
                                        placeholder="Enter your message"
                                    />
                                    <button onClick={this.handleComment} >
                                        { this.state.loadingComment ? <i className="fa fa-spinner fa-spin"> </i>
                                            : <SendIcon />
                                        }
                                    </button>
                                </form>
                            }

                            {validatorComment.message('user_comment', this.state.user_comment, 'required')}
                            <div className='text-danger mb-2'>{this.state.errors.user_comment}</div>
                        </div>

                        {
                            this.filterPermission('create-invoices') &&
                            <div className="card-edit mt-3">
                                <div className="row justify-content-center">
                                    <div className="col-10 text-center">
                                        <p className='text-white'>Silahkan membuat invoice baru dengan klik button disini</p>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="full mb-3 mt-2"
                                            onClick={this.handleOpen}
                                        >
                                            New Invoice
                                        </Button>
                                    </div>
                                    <div className="col-10">
                                        <img src={editImage} alt='edit' className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

                {
                    this.filterPermission('list-invoices') &&
                    <div className="col-12 pl-lg-5">
                        <div className="table-wrapper detail mb-3">
                            <div className="row align-items-center mb-3">
                                <div className="col-md-6">
                                    <h2 className="title-wrap mb-0">Summary Invoice</h2>
                                </div>
                                <div className="col-md-6 text-right">
                                    {/*<Button
                                        variant="contained"
                                        color="primary"
                                        className="mr-2"
                                        onClick={this.handleOpen}
                                    >
                                        New Invoice
                                    </Button>*/}
                                </div>
                            </div>
                            <Table className="table-list" size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Shared</TableCell>
                                        <TableCell>Payment Status</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell style={{width:'150px'}}>Invoice No</TableCell>
                                        <TableCell>Issued Date</TableCell>
                                        <TableCell>Invoice Title</TableCell>
                                        <TableCell>Bank Receiver</TableCell>
                                        <TableCell>Payment Date</TableCell>
                                        <TableCell>Invoice Amount</TableCell>
                                        <TableCell>Amount Paid</TableCell>
                                        <TableCell>Payer</TableCell>
                                        <TableCell>Files</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loadingInvoice ? (
                                        <TableRow>
                                            <TableCell colSpan={13} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.invoiceRow.length === 0 ? (
                                            <TableRow style={{ height: 33 * 13}}>
                                                <TableCell colSpan={13} style={{ textAlign: "center" }}>No invoice</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.invoiceRow.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>{row.sharePercentage === null ? '' : row.sharePercentage}</TableCell>
                                                    <TableCell><label className={'label-status label-'+this.filterStatus(row.statusInvoice)}>{row.statusInvoice}</label></TableCell>
                                                    <TableCell>{row.client === null ? '' : row.client.name}</TableCell>
                                                    <TableCell>{row.invoiceNumber === null ? '' : row.invoiceNumber}</TableCell>
                                                    <TableCell>{row.dateIssued === null ? '' : moment(row.dateIssued, 'YYYY-MM-DD').format('DD MMM YYYY')}</TableCell>
                                                    <TableCell>{row.invoiceTitle === null ? '' : row.invoiceTitle}</TableCell>
                                                    <TableCell>{row.bankReceiver === null ? '' : row.bankReceiver}</TableCell>

                                                    <TableCell>{(row.paymentDate === null || row.paymentDate === 'null' || row.paymentDate === '0000-00-00') ? '' : moment(row.paymentDate, 'YYYY-MM-DD').format('DD MMM YYYY')}</TableCell>
                                                    <TableCell className='text-right'>{row.currency === 'USD' && '$'}{row.currency === 'IDR' && 'Rp'} <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  value={row.totalAmount === null ? 0 : row.totalAmount} displayType={'text'} renderText={value => <div>{value}</div>}/></TableCell>
                                                    <TableCell className='text-right'>{row.currency === 'USD' && '$'}{row.currency === 'IDR' && 'Rp'} <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  value={row.amount === null ? 0 : row.amount} displayType={'text'} renderText={value => <div>{value}</div>}/></TableCell>
                                                    <TableCell>{row.payer === null ? '' : row.payer.name}</TableCell>
                                                    <TableCell>
                                                        <span>File Invoice:</span>
                                                        {row.fileInvoice &&
                                                        <a href={process.env.REACT_APP_API_STORAGE_PATH + row.fileInvoice} target='_blank'>
                                                            <i className="fas fa-file"> </i> {row.fileInvoice}
                                                        </a>
                                                        }

                                                        <br />
                                                        <span>File Report:</span>
                                                        {row.fileReport &&
                                                        <a href={process.env.REACT_APP_API_STORAGE_PATH + row.fileReport}
                                                           target='_blank'>
                                                            <i className="fas fa-file"> </i> {row.fileReport}
                                                        </a>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="d-flex">
                                                            <button className="btn-icon mr-2" onClick={(e) => this.handleOpen(e, row)}>
                                                                <i className="fas fa-edit"> </i>
                                                            </button>
                                                            <button className="btn-icon" onClick={() => this.handleOpenDelete(row)}>
                                                                <i className="fas fa-trash-alt"> </i>
                                                            </button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                        <div className="table-wrapper detail">
                            <div className="row align-items-center mb-3">
                                <div className="col-md-6">
                                    <h2 className="title-wrap mb-0">Summary Tax</h2>
                                </div>
                            </div>
                            <Table className="table-list" size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Faktur Pajak</TableCell>
                                        <TableCell>VAT Amount</TableCell>
                                        <TableCell>Tax 23 Amount</TableCell>
                                        <TableCell>Tax 23 Date Recieved</TableCell>
                                        <TableCell>Tax 23 Status</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loadingInvoice ? (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.invoiceRow.length === 0 ? (
                                            <TableRow style={{ height: 33 * 10}}>
                                                <TableCell colSpan={7} style={{ textAlign: "center" }}>No invoice</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.invoiceRow.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>{row.taxInvoiceNumber === null ? '' : row.taxInvoiceNumber}</TableCell>
                                                    <TableCell className='text-right'>{row.currency === 'USD' && '$'}{row.currency === 'IDR' && 'Rp'} <NumberFormat thousandSeparator={true} decimalScale={2} value={row.vatAmount === null ? 0 : row.vatAmount} displayType={'text'} renderText={value => <span>{value}</span>}/></TableCell>
                                                    <TableCell className='text-right'>{row.currency === 'USD' && '$'}{row.currency === 'IDR' && 'Rp'} <NumberFormat thousandSeparator={true} decimalScale={2} value={row.tax23 === null ? 0 : row.tax23} displayType={'text'} renderText={value => <span>{value}</span>}/></TableCell>
                                                    <TableCell>{(row.dateOfTax23 === null || row.dateOfTax23 === 'null' || row.dateOfTax23 === '0000-00-00') ? '' : moment(row.dateOfTax23).format('YYYY-MM-DD')}</TableCell>
                                                    <TableCell>{row.vatAmount === null ? 'No Tax 23': (row.dateOfTax23 === null || row.dateOfTax23 === 'null' || row.dateOfTax23 === '0000-00-00') ? 'Not received' : 'Received'}</TableCell>
                                                    <TableCell>{row.remarks === null ? '' : row.remarks}</TableCell>
                                                    <TableCell>
                                                        <div className="d-flex">
                                                            <button className="btn-icon mr-2" onClick={(e) => this.handleOpen(e, row)}>
                                                                <i className="fas fa-edit"> </i>
                                                            </button>
                                                            <button className="btn-icon" onClick={() => this.handleOpenDelete(row)}>
                                                                <i className="fas fa-trash-alt"> </i>
                                                            </button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>
                    </div>
                }


                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.state.editInvoice ? 'Edit' : 'Create'} Invoice</DialogTitle>
                    <DialogContent>
                        <form name="invoice" id="newInvoice" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Invoice No</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='invoice_number'
                                               name="invoice_number"
                                               label="Invoice No"
                                               onChange={(e) => this.handleChange(e, 'invoice_number')}
                                               value={this.state.invoice_number}
                                               fullWidth
                                    />
                                    {validatorModal.message('invoice_number', this.state.invoice_number, 'required')}
                                    <div className='text-danger'>{this.state.errors.invoice_number}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Invoice Title</label>
                                    <TextField variant="outlined"
                                               select
                                               id="invoice_title"
                                               name="invoice_title"
                                               label="Invoice Title"
                                               onChange={(e) => this.handleChange(e, 'invoice_title')}
                                               value={this.state.invoice_title}
                                               fullWidth
                                    >
                                        <MenuItem value="Proforma Invoice">
                                            Proforma Invoice
                                        </MenuItem>
                                        <MenuItem value="Final Invoice">
                                            Final Invoice
                                        </MenuItem>
                                    </TextField>


                                    <div className='text-danger'>{this.state.errors.invoice_title}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" style={{position:'relative', zIndex:2}}>
                                    <label>Payer</label>
                                    <Select options = {this.state.clients}
                                            onChange={(e) => this.handleChangeClient(e,'payer')}
                                            value={this.state.payer}
                                    />
                                   {/* <TextField variant="outlined"
                                               select
                                               id="payer"
                                               name="payer"
                                               label="Payer"
                                               onChange={(e) => this.handleChange(e, 'payer')}
                                               value={this.state.payer}
                                               fullWidth
                                    >
                                        {this.state.clients.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>*/}
                                    <div className='text-danger'>{this.state.errors.payer}</div>
                                    <button className='btn-link' onClick={(e) => this.handleRemove(e, 'payer')}>Remove Payer</button>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Share Percentage</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='share_percentage'
                                               name="share_percentage"
                                               label="Share Percentage"
                                               onChange={(e) => this.handleChange(e, 'share_percentage')}
                                               value={this.state.share_percentage}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.share_percentage}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Bank Receiver</label>
                                    <TextField variant="outlined"
                                               select
                                               id="bank_receiver"
                                               name="bank_receiver"
                                               label="Bank Receiver"
                                               onChange={(e) => this.handleChange(e, 'bank_receiver')}
                                               value={this.state.bank_receiver}
                                               fullWidth
                                    >
                                        <MenuItem value="Permata USD">
                                            Permata USD
                                        </MenuItem>
                                        <MenuItem value="Permata IDR">
                                            Permata IDR
                                        </MenuItem>
                                        <MenuItem value="OCBC USD">
                                            OCBC USD
                                        </MenuItem>
                                        <MenuItem value="BCA IDR">
                                            BCA IDR
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.bank_receiver}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date Issued Invoice</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Date Issued Invoice"
                                            value={this.state.date_issued}
                                            onChange={(date) => this.handleDate(date, 'date_issued')}
                                            format={'DD MMM YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className='text-danger'>{this.state.errors.date_issued}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date of Payment</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Payment Date"
                                            value={this.state.payment_date}
                                            onChange={(date) => this.handleDate(date, 'payment_date')}
                                            format={'DD MMM YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className='text-danger'>{this.state.errors.payment_date}</div>
                                    <button className='btn-link' onClick={(e) => this.handleRemove(e, 'payment_date')}>Remove Date</button>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" style={{position:'relative', zIndex:2}}>
                                    <label>Invoice Receiver/Client</label>
                                    <Select options = {this.state.clients}
                                            onChange={(e) => this.handleChangeClient(e,'client_id')}
                                            value={this.state.client_id}
                                    />
                                    <div className='text-danger'>{this.state.errors.client_id}</div>
                                    <button className='btn-link' onClick={(e) => this.handleRemove(e, 'client_id')}>Remove Client</button>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Amount Paid</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='amount'
                                               name="amount"
                                               label="Amount Paid"
                                               onChange={(e) => this.handleChange(e, 'amount')}
                                               value={this.state.amount}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.amount}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Invoice Currency</label>
                                    <TextField variant="outlined"
                                               select
                                               id="currency_inv"
                                               name="currency_inv"
                                               label="Currency"
                                               onChange={(e) => this.handleChange(e, 'currency_inv')}
                                               value={this.state.currency_inv}
                                               fullWidth
                                    >
                                        <MenuItem value="IDR">
                                            IDR
                                        </MenuItem>
                                        <MenuItem value="USD">
                                            USD
                                        </MenuItem>
                                        <MenuItem value="SGD">
                                            SGD
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.currency_inv}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tax 23 Amount</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='tax_23'
                                               name="tax_23"
                                               label="Tax 23 Amount"
                                               onChange={(e) => this.handleChange(e, 'tax_23')}
                                               value={this.state.tax_23}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               fullWidth
                                               disabled
                                    />
                                    <div className='text-danger'>{this.state.errors.tax_23}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Survey Fee</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='survey_fee'
                                               name="survey_fee"
                                               label="Survey Fee"
                                               onChange={(e) => this.handleChange(e, 'survey_fee')}
                                               value={this.state.survey_fee}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               disabled
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.survey_fee}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date of Tax 23 Payment</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Date of Tax 23 Payment"
                                            value={this.state.date_of_tax_23}
                                            onChange={(date) => this.handleDate(date, 'date_of_tax_23')}
                                            format={'DD MMM YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className='text-danger'>{this.state.errors.date_of_tax_23}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tax 23 Status</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='pph_23_status'
                                               name="pph_23_status"
                                               label="Tax 23 Status"
                                               onChange={(e) => this.handleChange(e, 'pph_23_status')}
                                               value={this.state.pph_23_status}
                                               disabled
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.pph_23_status}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Expenses</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='expenses'
                                               name="expenses"
                                               label="Expenses"
                                               onChange={(e) => this.handleChange(e, 'expenses')}
                                               value={this.state.expenses}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               disabled
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.expenses}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Remarks</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='remarks'
                                               name="remarks"
                                               label="Remarks"
                                               onChange={(e) => this.handleChange(e, 'remarks')}
                                               value={this.state.remarks}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.remarks}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>VAT Amount</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='vat_amount'
                                               name="vat_amount"
                                               label="VAT Amount"
                                               onChange={(e) => this.handleChange(e, 'vat_amount')}
                                               value={this.state.vat_amount}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vat_amount}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Faktur Pajak</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='tax_invoice_number'
                                               name="tax_invoice_number"
                                               label="Faktur Pajak"
                                               onChange={(e) => this.handleChange(e, 'tax_invoice_number')}
                                               value={this.state.tax_invoice_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.tax_invoice_number}</div>
                                </div>
                            </div>
                            {/*<div className="col-md-6">
                                <div className="form-group">
                                    <label>Currency Rate</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='currency_rate_inv'
                                               name="currency_rate_inv"
                                               label="Currency Rate"
                                               onChange={(e) => this.handleChange(e, 'currency_rate_inv')}
                                               value={this.state.currency_rate_inv}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.currency_rate_inv}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Amount in words</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='amount_words'
                                               name="amount_words"
                                               label="Amount in words"
                                               onChange={(e) => this.handleChange(e, 'amount_words')}
                                               value={this.state.amount_words}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.amount_words}</div>
                                </div>
                            </div>*/}

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Total Invoice</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='total_amount'
                                               name="total_amount"
                                               label="Total Invoice"
                                               onChange={(e) => this.handleChange(e, 'total_amount')}
                                               value={this.state.total_amount}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               fullWidth
                                               disabled
                                    />
                                    <div className='text-danger'>{this.state.errors.total_amount}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Discount</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='diskon'
                                               name="diskon"
                                               label="Discount"
                                               onChange={(e) => this.handleChange(e, 'diskon')}
                                               value={this.state.diskon}
                                               InputProps={{
                                                   inputComponent: this.NumberFormatCustom,
                                               }}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.diskon}</div>
                                </div>
                            </div>


                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>File Invoice</label>
                                    <input
                                        type="file"
                                        id='fileInvoice'
                                        name="fileInvoice"
                                        onChange={this.handleFile}
                                    />
                                    <button className="btn-icon" onClick={() => this.handleDeleteFileInvoice()}>
                                        <i className="fas fa-trash-alt"> </i>
                                    </button>
                                    <span>{this.state.fileInvoiceName}</span>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>File Report</label>
                                    <input
                                        type="file"
                                        id='fileReport'
                                        name="fileReport"
                                        onChange={this.handleFileReport}
                                    />
                                    <button className="btn-icon" onClick={() => this.handleDeleteFileReport()}>
                                        <i className="fas fa-trash-alt"> </i>
                                    </button>
                                    <span>{this.state.fileReportName}</span>
                                </div>
                            </div>


                            {/*<div className="col-md-6">
                                <div className="form-group">
                                    <label>Date of Tax Invoice</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Date of Tax Invoice"
                                            value={this.state.date_of_tax}
                                            onChange={(date) => this.handleDate(date, 'date_of_tax')}
                                            format={'DD MMM YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className='text-danger'>{this.state.errors.date_of_tax}</div>
                                </div>
                            </div>*/}
                            {/*<div className="col-md-6">
                                <div className="form-group">
                                    <label>Date of Tax 21 Payment</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Date of Tax 21 Payment"
                                            value={this.state.date_of_tax_21}
                                            onChange={(date) => this.handleDate(date, 'date_of_tax_21')}
                                            format={'DD MMM YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className='text-danger'>{this.state.errors.date_of_tax_21}</div>
                                </div>
                            </div>*/}


                           {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tax 23 Remarks</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='tax_23_remarks'
                                               name="tax_23_remarks"
                                               label="Tax 23 Remarks"
                                               onChange={(e) => this.handleChange(e, 'tax_23_remarks')}
                                               value={this.state.tax_23_remarks}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.tax_23_remarks}</div>
                                </div>
                            </div>*/}


                        </form>

                    </DialogContent>
                    <DialogActions className="justify-content-center pb-3">
                        <Button
                            variant="contained"
                            className="mr-3"
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className=""
                            onClick={this.handleSubmitInvoice}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogDelete}
                    onClose={this.handleCloseDelete}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">DELETE TYPE: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this type?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            className="mr-3"
                            onClick={this.handleCloseDelete}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className=""
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled' }
                        >
                            Delete{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectsFinance));
