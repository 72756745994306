import React,{Component} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../../libraries/api';
import {toast, ToastContainer} from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import {history} from '../../../shared/configure-store';
import queryString from 'query-string';
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();


const styleWidth = {
    minWidth: 'none',
};

const styleWidth1 = {
    minWidth: 'none',
};

const styleMax = {
    width: 'auto',
};

class Surveyor extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            orderByStatus: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,

            case_number: '',
            project_number: '',
            ext_project_number: '',
            vessel_name: '',
            casualty: '',
            client_id: '',
            typeId: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            projectStatus: '',
            surveyorId: '',

            types: [],
            clients: [],
            project_status: [],
            report_status: [],
            approval_status: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Taks';
        let profile = AuthHelper.getProfile();
        let role = profile.roles.data[0].name;
        let filter = 'ss';
        if(role === 'ops') {
            filter = '&projectStatus=On%20Progress&adminId='+profile.id;
            this.__fetchData(filter)
        }else if(role === 'surveyor') {
            filter = '&projectStatus=On%20Progress&surveyorId='+profile.id;
            this.__fetchData(filter)
        }
    }

    __fetchData = filter => {

        let route = '/projects?limit=0'+filter;

        Api.get(route).then(resp => {
            if (resp.data) {
                this.setState({
                    rows: resp.data,
                    loading: false,
                })

            }
        }).catch(err => {
            console.log(err);
        });
    };


    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;
        let sortStatus = '';

        if(val === 'projectStatusBar'){
            sortStatus = 'projectStatusBar';
        }else if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderByStatus: sortStatus,
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChangeClient (e, prop){

        this.setState({

            [prop]: e

        });

    };
    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleOpenFilter = () => {
        this.setState({
            showDialogFilter :true,
        })
    };

    handleCloseFilter = () => {
        this.setState({
            showDialogFilter :false,
        })
    };

    handleSubmitFilter = () => {

        this.__fetchData(false);

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    render() {
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>My Task</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>My Task</li>
                        </ol>
                    </nav>

                </div>
                <div className='col-12 mt-3 px-lg-5'>
                    <div className='table-wrapper'>
                        <TableContainer className='border-top pt-3'>
                            <h2 className='title-wrap'>My Task</h2>
                            <Table className='table-list' size='small' >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Case No</TableCell>
                                        <TableCell style={styleWidth1}>Underwritter</TableCell>
                                        <TableCell>Project Type</TableCell>
                                        <TableCell>Vessel</TableCell>
                                        <TableCell style={styleMax} >Casualties</TableCell>
                                        <TableCell style={styleWidth1}>Survey Status</TableCell>
                                        <TableCell style={styleWidth}>Project Status</TableCell>
                                        <TableCell style={styleMax}>Last Status </TableCell>
                                        <TableCell >Days
                                        </TableCell>
                                        <TableCell style={styleWidth}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.rows.length === 0 ?
                                        <TableRow style={{ height: 33 * 3}}>
                                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>No project</TableCell>
                                        </TableRow> :
                                        this.state.rows.map(row => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    <Link to={'/projects/detail/' + row.id} target='_blank'>
                                                        {row.caseNumber === null ? '' : row.caseNumber}{row.projectNumber === null ? '' : '['+row.projectNumber+']'}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {row.underWriters &&
                                                    row.underWriters.map((row, id) => (
                                                        <small key={id}>{row.name}, </small>
                                                    ))
                                                    }
                                                </TableCell>
                                                <TableCell>{row.type === null ? '' : row.type.name}{row.detailType === null ? '' : '['+row.detailType.name+']'}</TableCell>
                                                <TableCell>{row.vesselName === null ? '' : row.vesselName}</TableCell>
                                                <TableCell>{row.casualty === null ? '' : row.casualty}</TableCell>
                                                <TableCell>
                                                    {row.surveyStatus &&
                                                    <small>
                                                        <span className={'label-status label-'+this.filterStatus(row.surveyStatus !== null && row.surveyStatus.project_status)}>{row.surveyStatus === null ? '' : row.surveyStatus.name}</span>
                                                        {row.dateSurveyStatus === null ? '' : moment(row.dateSurveyStatus).format('DD MMM YYYY')}
                                                    </small>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {row.projectStatus &&
                                                    <small>
                                                        <span className={'label-status label-'+this.filterStatus(row.projectStatus !== null && row.projectStatus.project_status)}>{row.projectStatus === null ? '' : row.projectStatus.name}</span>
                                                        {row.dateStatus === null ? '' : moment(row.dateStatus).format('DD MMM YYYY')}
                                                    </small>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {row.comment === null ? '' : row.comment.length > 0 && row.comment[0].comment.substring(0, 50)}
                                                    <small className='d-block'>{row.updated_at === null ? '' : moment(row.updated_at).format('DD MMM YYYY')}</small>
                                                </TableCell>
                                                <TableCell>{row.numberOfDays}</TableCell>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        <Link to={'/projects/detail/' + row.id} className='btn-icon mr-2'>
                                                            <i className='fas fa-eye'> </i>
                                                        </Link>
                                                        <Link to={'/projects/edit/' + row.id} className='btn-icon mr-2'>
                                                            <i className='fas fa-edit'> </i>
                                                        </Link>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                </div>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default Surveyor;
