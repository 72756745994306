import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import _ from 'lodash';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Skeleton from "@material-ui/lab/Skeleton";
import user from "../../../images/default.png";
import moment from "moment";
import SendIcon from "@material-ui/icons/Send";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validator1 = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorComment = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class ClientsEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            id: '',
            username: '',

            type: '',
            detail_client_type: '',
            name: '',
            email: '',
            telephone: '',
            fax: '',
            address: '',
            city: '',
            province: '',
            zip: '',
            country: '',
            notes: '',
            active: '',
            pic: [],
            picName: '',
            picPhone: '',
            picEmail: '',
            npwp_no: '',
            npwp_company_name: '',
            npwp_address: '',
            director_name: '',
            company_phone: '',
            company_email: '',
            showPassword: false,
            password: '',

            comment_list: [],
            loadingCommentList: false,
            userId: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Edit Client';

        const id = this.props.match.params.id;

        Api.get('/clients/'+id).then(resp => {

            if (resp.data) {
                let data = resp.data;

                this.setState({
                    id: data.id,
                    active: data.active,
                    name: data.name,
                    username: data.username,
                    type: data.type,
                    detail_client_type: data.detailClientType,
                    email: data.email,
                    telephone: data.telephone,
                    fax: data.fax,
                    address: data.address,
                    city: data.city,
                    province: data.province,
                    zip: data.zip,
                    country: data.country,
                    notes: data.website,
                    pic: data.pic,
                    npwp_no: data.npwpNo,
                    npwp_company_name: data.npwpCompanyName,
                    npwp_address: data.npwpAddress,
                    director_name: data.directorName,
                    company_phone: data.companyPhone,
                    company_email: data.companyEmail,
                    picName: data.pic_name,
                    picPhone: data.pic_phone,
                    picEmail: data.pic_email,
            });

            }

        }).catch(err => {
            console.log(err);
        });
        let profile = AuthHelper.getProfile();
        if(profile) {
            this.setState({
                userId: profile.id,

            });
        }

        this.__fetchComment();

    }

    __fetchComment = () => {
        const id = this.props.match.params.id;

        Api.get('/client-comments?search=client_id:'+id+'&client=true&limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    comment_list: resp.data,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleComment = (e) => {
        e.preventDefault();

        if (!validatorComment.allValid()) {

            this.setState({
                    errors: validatorComment.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingComment: true,
            }
        );

        let profile = AuthHelper.getProfile();
        console.log(profile);

        let param = {
            user_id: this.state.userId,
            comment: this.state.user_comment,
            client_id: this.props.match.params.id,
            client: true
        };

        Api.post('/client-comments', param, false).then(resp => {

            if(resp.data) {
                this.__fetchComment();
                this.setState({
                    loadingComment: false
                });
                this.showMessage(true, 'Comment posted');

            }

        }).catch(err => {
            this.setState({
                errors: err.errors,
                loadingComment: false
            });

            this.showMessage(false, 'Invalid format data');
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmitPassword = (e) => {
        e.preventDefault();
        const id = this.props.match.params.id;

        if (!validator1.allValid()) {

            this.setState({
                    errors: validator1.getErrorMessages()
                }
            );
            return false;
        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            username : this.state.username,
            password : this.state.password,
        };

        Api.patch('/clients/'+id+'/password', params, false).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            history.push('/clients');

            this.showMessage(true, 'Password updated');

        }).catch(err => {
            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });
    }
    handleSubmit = (e) => {

        e.preventDefault();

        const id = this.props.match.params.id;
        console.log(validator.getErrorMessages())
        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let send = [];

        if(this.state.pic.length > 0) {
            _.forEach(this.state.pic, function(v){
                send.push(v)
            });
        }


        let params = {
            type : this.state.type,
            detail_client_type : this.state.detail_client_type,
            name : this.state.name,
            email : this.state.email,
            telephone : this.state.telephone,
            fax : this.state.fax,
            address : this.state.address,
            city : this.state.city,
            province : this.state.province,
            zip : this.state.zip,
            country : this.state.country,
            website : this.state.notes,
            active : this.state.active,
            pic: send,
            npwp_no: this.state.npwp_no,
            npwp_company_name: this.state.npwp_company_name,
            npwp_address: this.state.npwp_address,
            director_name: this.state.director_name,
            company_phone: this.state.company_phone,
            company_email: this.state.company_email,
            pic_name: this.state.picName,
            pic_phone: this.state.picPhone,
            pic_email: this.state.picEmail,
        };

        Api.patch('/clients/' + id, params, false).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            history.push('/clients');

            this.showMessage(true, 'Client updated');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleAdd = (e) => {
        e.preventDefault();

        this.setState({
                errors: {},
            }
        );

        let exp = this.state.pic;
        let temp = {
            id : '',
            name : '',
            phone : '',
            email : '',
        };

        exp.push(temp);
        this.setState({
                pic: exp,
            }
        );

    };

    handleDelete = (id) => {
        this.setState({
                errors: {},
            }
        );

        let data = this.state.pic.filter((e, idx) =>idx !== id);

        this.setState({
            pic: data,
        });
    };

    handleChangePIC = (e, prop, id) => {
        let data = this.state.pic;
        _.forEach(data, function(v,k){

            if(k === id){
                v[prop] = e.target.value;
            }
        });

        this.setState({
            errors: {},
            pic: data,
        });
    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };


    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Edit Client</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/clients" >Clients</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className='row'>
                        <div className="col-md-8">
                            <div className="table-wrapper">
                                <form name="edit" id="editClient" className="row" noValidate>
                                    <div className="col-12">
                                        <div className="group-title">
                                            <h3>Client Type</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Type</label>
                                            <TextField variant="outlined"
                                                       select
                                                       id="type"
                                                       name="type"
                                                       label="Type"
                                                       onChange={(e) => this.handleChange(e, 'type')}
                                                       value={this.state.type}
                                                       fullWidth
                                            >
                                                <MenuItem value="Underwriter">
                                                    Underwriter
                                                </MenuItem>
                                                <MenuItem value="Broker">
                                                    Broker
                                                </MenuItem>
                                                <MenuItem value="Shipowner">
                                                    Shipowner
                                                </MenuItem>
                                                <MenuItem value="Adjuster">
                                                    Adjuster
                                                </MenuItem>
                                            </TextField>
                                            {validator.message('type', this.state.type, 'required')}
                                            <div className='text-danger'>{this.state.errors.type}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Detail Client Type</label>
                                            <TextField variant="outlined"
                                                       select
                                                       id="detail_client_type"
                                                       name="detail_client_type"
                                                       label="Detail Type"
                                                       onChange={(e) => this.handleChange(e, 'detail_client_type')}
                                                       value={this.state.detail_client_type}
                                                       fullWidth
                                            >
                                                <MenuItem value="H&M">
                                                    H&M
                                                </MenuItem>
                                                <MenuItem value="P&I">
                                                    P&I
                                                </MenuItem>
                                            </TextField>
                                            <div className='text-danger'>{this.state.errors.detail_client_type}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <TextField variant="outlined"
                                                       select
                                                       id="active"
                                                       name="active"
                                                       label="Status"
                                                       onChange={(e) => this.handleChange(e, 'active')}
                                                       value={this.state.active}
                                                       fullWidth
                                            >
                                                <MenuItem value="1">
                                                    Active
                                                </MenuItem>
                                                <MenuItem value="0">
                                                    Inactive
                                                </MenuItem>
                                            </TextField>
                                            {validator.message('active', this.state.active, 'required')}
                                            <div className='text-danger'>{this.state.errors.active}</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="group-title">
                                            <h3>Company</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='name'
                                                       name="name"
                                                       label="Company Name"
                                                       onChange={(e) => this.handleChange(e, 'name')}
                                                       value={this.state.name}
                                                       className='text-uppercase'
                                                       fullWidth
                                            />
                                            {validator.message('name', this.state.name, 'required')}
                                            <div className='text-danger'>{this.state.errors.name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='email'
                                                       name="email"
                                                       label="Email"
                                                       onChange={(e) => this.handleChange(e, 'email')}
                                                       value={this.state.email}
                                                       fullWidth
                                            />
                                            {validator.message('email', this.state.email, 'email')}
                                            <div className='text-danger'>{this.state.errors.email}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='address'
                                                       name="address"
                                                       label="Address"
                                                       onChange={(e) => this.handleChange(e, 'address')}
                                                       value={this.state.address}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.address}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='city'
                                                       name="city"
                                                       label="City"
                                                       onChange={(e) => this.handleChange(e, 'city')}
                                                       value={this.state.city}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.city}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Province</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='province'
                                                       name="province"
                                                       label="Province"
                                                       onChange={(e) => this.handleChange(e, 'province')}
                                                       value={this.state.province}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.province}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Zip</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='zip'
                                                       name="zip"
                                                       label="Zip"
                                                       onChange={(e) => this.handleChange(e, 'zip')}
                                                       value={this.state.zip}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.zip}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='country'
                                                       name="country"
                                                       label="Country"
                                                       onChange={(e) => this.handleChange(e, 'country')}
                                                       value={this.state.country}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.country}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Telephone</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='telephone'
                                                       name="telephone"
                                                       label="Telephone"
                                                       onChange={(e) => this.handleChange(e, 'telephone')}
                                                       value={this.state.telephone}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.telephone}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fax</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='fax'
                                                       name="fax"
                                                       label="Fax"
                                                       onChange={(e) => this.handleChange(e, 'fax')}
                                                       value={this.state.fax}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.fax}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <TextField 
                                                className='multiline-no-padding'
                                                variant="outlined"
                                                type='text'
                                                id='notes'
                                                name="notes"
                                                label="Notes"
                                                onChange={(e) => this.handleChange(e, 'notes')}
                                                value={this.state.notes}
                                                inputProps={{ maxLength: 1500 }}
                                                fullWidth
                                                multiline
                                            />
                                            <div className='text-danger'>{this.state.errors.notes}</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="group-title">
                                            <h3>PIC</h3>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>PIC Name | Bagian | Tahun</label>
                                                    <TextField
                                                        className='multiline-no-padding'
                                                        variant="outlined"
                                                        type='text'
                                                        id='picName'
                                                        name="picName"
                                                        label="PIC Name | Bagian | Tahun"
                                                        onChange={(e) => this.handleChange(e, 'picName')}
                                                        value={this.state.picName}
                                                        inputProps={{ maxLength: 1500 }}
                                                        fullWidth
                                                        multiline
                                                    />
                                                    <div className='text-danger'>{this.state.errors.picName}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>PIC Phone</label>
                                                    <TextField variant="outlined"
                                                               type='text'
                                                               id='picPhone'
                                                               name="picPhone"
                                                               label="PIC Phone"
                                                               onChange={(e) => this.handleChange(e, 'picPhone')}
                                                               value={this.state.picPhone}
                                                               fullWidth
                                                    />
                                                    <div className='text-danger'>{this.state.errors.picPhone}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>PIC Email</label>
                                                    <TextField variant="outlined"
                                                               type='text'
                                                               id='picEmail'
                                                               name="picEmail"
                                                               label="PIC Email"
                                                               onChange={(e) => this.handleChange(e, 'picEmail')}
                                                               value={this.state.picEmail}
                                                               fullWidth
                                                    />
                                                    <div className='text-danger'>{this.state.errors.picEmail}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <button onClick={this.handleAdd} className='btn-link px-0'>Add More PIC</button>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>PIC Name | Bagian | Tahun</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>PIC Phone</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>PIC Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {
                                            this.state.pic.length > 0 &&
                                            this.state.pic.map(function (item, idx) {
                                                return <div className="row" key={idx}>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <TextField 
                                                                className='multiline-no-padding'
                                                                variant="outlined"
                                                                type='text'
                                                                id={'name-'+idx}
                                                                name={'name-'+idx}
                                                                label="PIC Name | Bagian | Tahun"
                                                                onChange={(e) => this.handleChangePIC(e, 'name', idx)}
                                                                value={item.name}
                                                                inputProps={{ maxLength: 1500 }}
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <TextField variant="outlined"
                                                                       type='text'
                                                                       id={'phone-'+idx}
                                                                       name={'phone-'+idx}
                                                                       label="PIC Phone"
                                                                       onChange={(e) => this.handleChangePIC(e, 'phone', idx)}
                                                                       value={item.phone}
                                                                       fullWidth
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <TextField variant="outlined"
                                                                       type='text'
                                                                       id={'email-'+idx}
                                                                       name={'email-'+idx}
                                                                       label="PIC Email"
                                                                       onChange={(e) => this.handleChangePIC(e, 'email', idx)}
                                                                       value={item.email}
                                                                       fullWidth
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }.bind(this))
                                        }
                                    </div>


                                    <div className="col-12">
                                        <div className="group-title">
                                            <h3>NPWP</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>No NPWP</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='npwp_no'
                                                       name="npwp_no"
                                                       label="No NPWP"
                                                       onChange={(e) => this.handleChange(e, 'npwp_no')}
                                                       value={this.state.npwp_no}
                                                       fullWidth
                                            />
                                            {validator.message('npwp_no', this.state.npwp_no, 'numeric')}
                                            <div className='text-danger'>{this.state.errors.npwp_no}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>NPWP Company Name</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='npwp_company_name'
                                                       name="npwp_company_name"
                                                       label="Company Name"
                                                       onChange={(e) => this.handleChange(e, 'npwp_company_name')}
                                                       value={this.state.npwp_company_name}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.npwp_company_name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>NPWP Address</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='npwp_address'
                                                       name="npwp_address"
                                                       label="NPWP Address"
                                                       onChange={(e) => this.handleChange(e, 'npwp_address')}
                                                       value={this.state.npwp_address}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.npwp_address}</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="group-title">
                                            <h3>Director</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Director Name</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='director_name'
                                                       name="director_name"
                                                       label="Director Name"
                                                       onChange={(e) => this.handleChange(e, 'director_name')}
                                                       value={this.state.director_name}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.director_name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='company_phone'
                                                       name="company_phone"
                                                       label="Phone"
                                                       onChange={(e) => this.handleChange(e, 'company_phone')}
                                                       value={this.state.company_phone}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.director_name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='company_email'
                                                       name="company_email"
                                                       label="Email"
                                                       onChange={(e) => this.handleChange(e, 'company_email')}
                                                       value={this.state.company_email}
                                                       fullWidth
                                            />
                                            <div className='text-danger'>{this.state.errors.director_name}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Button
                                            variant="contained"
                                            className="mr-3"
                                            onClick={() => this.handleGo('/clients')}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className=""
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton && 'disabled' }
                                        >
                                            Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    </div>
                                    <div className="col-12">
                                        <div className="group-title">
                                            <h3>CPA Username and Password</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='username'
                                                       name="username"
                                                       label="Username"
                                                       onChange={(e) => this.handleChange(e, 'username')}
                                                       value={this.state.username}
                                                       fullWidth
                                            />
                                            {validator1.message('username', this.state.username, 'username')}
                                            <div className='text-danger'>{this.state.errors.username}</div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Password</label>

                                            <TextField variant='outlined'
                                                       type={this.state.showPassword ? 'text' : 'password'}
                                                       id='adornment-password'
                                                       name='password'
                                                       label='Password'
                                                       placeholder='Your password here..'
                                                       onChange={(e) => this.handleChange(e,'password')}
                                                       value={this.state.password}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position='end'>
                                                                   <IconButton
                                                                       className='btn-input'
                                                                       aria-label='Toggle password visibility'
                                                                       onClick={this.handleClickShowPassword}
                                                                   >
                                                                       {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                                           <i className='fas fa-eye-slash'> </i>}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                           )
                                                       }}
                                                       fullWidth
                                            />
                                            {validator1.message('password', this.state.password, 'required')}
                                            <div className='text-danger'>{this.state.errors.password}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className=""
                                            onClick={this.handleSubmitPassword}
                                            disabled={this.state.loadingButton && 'disabled' }
                                        >
                                            Update Password{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-4 comment-wrap">
                            <h3>Comment</h3>

                            <div className='comment-container'>
                                { this.state.loadingCommentList ?
                                    <div className='row mb-2'>
                                        <div className='col img-avatar'>
                                            <Skeleton variant='circle' width={60} height={60} />
                                        </div>
                                        <div className='col'>
                                            <Skeleton variant='text' />
                                            <Skeleton variant='text' />
                                        </div>
                                    </div> :
                                    this.state.comment_list.length === 0 ?
                                        <div className='col img-avatar mb-2'>
                                            <p>no comment</p>
                                        </div>
                                        :
                                        this.state.comment_list.map(row => (
                                            <div className='row mb-2' key={row.id}>
                                                <div className='col img-avatar'>
                                                    <img src={row.user ? row.user.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+row.user.avatar : user : user} alt='avatar' className='img-fluid' />
                                                </div>
                                                <div className='col'>
                                                    <h4>{row.user ? row.user.name : ''}</h4>
                                                    <p>{row.comment}</p>
                                                    {row.image &&
                                                    <img
                                                        src={process.env.REACT_APP_API_STORAGE_PATH + row.image}
                                                        alt='avatar' className='img-fluid'/>
                                                    }
                                                    <time>{moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')}</time>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>

                            <form>
                                <input
                                    name='user_comment'
                                    onChange={(e) => this.handleChange(e, 'user_comment')}
                                    value={this.state.user_comment}
                                    placeholder='Enter your message'
                                />
                                <button onClick={this.handleComment} >
                                    { this.state.loadingComment ? <i className='fa fa-spinner fa-spin'> </i>
                                        : <SendIcon />
                                    }
                                </button>
                            </form>
                        </div>
                    </div>

                </div>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default ClientsEdit;
