import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Api from "../../../libraries/api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';


class ContentDashboard extends Component {
    constructor(props) {

        super(props);
        
        this.default_type_id = { id: 'all', name: 'Semua Detail Tipe Project' };
        
        this.state = {
            loading: false,
            years: [],
            year: '',
            total_year: '',
            detailTypes: [],
            detail_type_ids: [],
            detail_type_labels: [this.default_type_id],
            data: {},
            jan: {},
            feb: {},
            mar: {},
            apr: {},
            may: {},
            jun: {},
            jul: {},
            aug: {},
            sep: {},
            okt: {},
            nov: {},
            dec: {},
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Dashboard Statement';

        this.setState({
            loading: true,
        });

        let rows = [];
        let year = moment().year();
        for (let i=2002; i <= year; i++) {
            rows.push(i);
        }
        this.__fetchData();
        this.fetchDetailTypes();

        this.setState({
            years: rows,
            year: year,
        });

    }

    handleChange (e, prop){
        this.setState({
            loading: true,
            year: e.target.value
        }, () => {
            this.__fetchData();
        });
    };

    handleChangeCheckboxAll = () => {
        this.setState({ 
            detail_type_ids: [],
            detail_type_labels: [this.default_type_id],
        }, () => {
            this.__fetchData();
        });
    };

    handleChangeCheckbox = (e) => {
        let detail_type_ids = this.state.detail_type_ids;
        const value = e.target.value;
        const idx = detail_type_ids.indexOf(value);

        if (idx !== -1) detail_type_ids.splice(idx, 1);
        else detail_type_ids.push(value);

        this.setState({
            detail_type_ids,
            detail_type_labels: this.state.detailTypes.filter((item) => detail_type_ids.includes(item.id)),
        }, () => {
            this.__fetchData();
        });
    };

    fetchDetailTypes = () => {
        Api.get('/detailtypes?limit=0').then(resp => {
            if (resp.data) {
                const allowedDetailTypes = ['Report Preliminary', 'Approval First Letter'];
                const filteredDetailTypes = resp.data.filter((item) => allowedDetailTypes.includes(item?.name));

                this.setState({ detailTypes: filteredDetailTypes });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    __fetchData = () => {
        let params = `?year=${this.state.year || ''}`;
        if (!this.state.detail_type_ids?.length) params += `&detail_types=all`;
        if (this.state.detail_type_ids?.length) {
            this.state.detail_type_ids.forEach((item) => {
                params += `&detail_types[]=${item}`;
            });
        } 

        Api.get(`/reports/statement${params}`).then(resp => {
            if (resp.data) {
                this.setState({
                    data: resp.data,
                    jan: resp.data.Jan,
                    feb: resp.data.Feb,
                    mar: resp.data.Mar,
                    apr: resp.data.Apr,
                    may: resp.data.Mei,
                    jun: resp.data.Jun,
                    jul: resp.data.Jul,
                    aug: resp.data.Agu,
                    sep: resp.data.Sep,
                    okt: resp.data.Okt,
                    nov: resp.data.Nov,
                    dec: resp.data.Des,
                    total_year: resp.data.total_year,
                    loading: false
                });
            }
        }).catch(err => {
            this.setState({ loading: false });
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Operation Statement</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Operation Statement</li>
                        </ol>
                    </nav>
                    <h3 className='title-wrap'>OPERATIONAL STATEMENT</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label>Pilih Tahun</label>
                                <TextField
                                    variant="outlined"
                                    select
                                    id="year"
                                    name="year"
                                    label="Tahun"
                                    onChange={(e) => this.handleChange(e, 'year')}
                                    value={this.state.year}
                                    fullWidth
                                >
                                    {this.state.years.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                </TextField>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className='form-group'>
                                <label>Pilih Detail Tipe Project</label>
                                <Select 
                                    id='detail_type_ids'
                                    name='detail_type_ids'
                                    label='Detail Tipe Project'
                                    variant='outlined'
                                    onChange={this.handleChangeMultipleCheckbox}
                                    value={this.state.detail_type_labels}
                                    fullWidth
                                    multiple
                                    renderValue={(selected) => {
                                        const labels = selected.map((item) => item.name);
                                        return labels.join(", ");
                                    }}
                                >
                                    <MenuItem key={0} value={this.default_type_id.id}>
                                        <Checkbox onChange={this.handleChangeCheckboxAll} value={this.default_type_id.id} checked={this.state.detail_type_ids.length ? false : true}/>
                                        <ListItemText primary={this.default_type_id.name} />
                                    </MenuItem>
                                    {this.state.detailTypes.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            <Checkbox
                                                onChange={this.handleChangeCheckbox} 
                                                value={option.id} 
                                                checked={this.state.detail_type_ids.length ? this.state.detail_type_ids.findIndex(item => item == option.id) >= 0 : false}
                                                data-label={option.name}
                                            />
                                            <ListItemText primary={option.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>Month</TableCell>
                                    <TableCell colSpan='3' className='text-center'>New Instruction</TableCell>
                                    <TableCell colSpan='3' className='text-center'>Report Issued</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>Report</TableCell>
                                    <TableCell className='text-center'>Approval</TableCell>
                                    <TableCell className='text-center'>Total</TableCell>
                                    <TableCell className='text-center'>Report</TableCell>
                                    <TableCell className='text-center'>Approval</TableCell>
                                    <TableCell className='text-center'>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.data &&
                            <TableBody>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/1'} target='_blank'>Januari</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jan ? this.state.jan.report_new ? this.state.jan.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jan ? this.state.jan.approval_new ? this.state.jan.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jan ? this.state.jan.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jan ? this.state.jan.report_issued ? this.state.jan.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jan ? this.state.jan.approval_issued ? this.state.jan.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jan ? this.state.jan.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='1'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/2'} target='_blank'>Februari</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.feb ? this.state.feb.report_new ? this.state.feb.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.feb ? this.state.feb.approval_new ? this.state.feb.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.feb ? this.state.feb.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.feb ? this.state.feb.report_issued ? this.state.feb.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.feb ? this.state.feb.approval_issued ? this.state.feb.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.feb ? this.state.feb.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='2'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/3'} target='_blank'>Maret</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.mar ? this.state.mar.report_new ? this.state.mar.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.mar ? this.state.mar.approval_new ? this.state.mar.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.mar ? this.state.mar.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.mar ? this.state.mar.report_issued ? this.state.mar.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.mar ? this.state.mar.approval_issued ? this.state.mar.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.mar ? this.state.mar.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='3'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/4'} target='_blank'>April</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.apr ? this.state.apr.report_new ? this.state.apr.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.apr ? this.state.apr.approval_new ? this.state.apr.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.apr ? this.state.apr.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.apr ? this.state.apr.report_issued ? this.state.apr.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.apr ? this.state.apr.approval_issued ? this.state.apr.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.apr ? this.state.apr.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='4'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/5'} target='_blank'>Mei</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.may ? this.state.may.report_new ? this.state.may.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.may ? this.state.may.approval_new ? this.state.may.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.may ? this.state.may.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.may ? this.state.may.report_issued ? this.state.may.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.may ? this.state.may.approval_issued ? this.state.may.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.may ? this.state.may.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='5'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/6'} target='_blank'>Juni</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jun ? this.state.jun.report_new ? this.state.jun.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jun ? this.state.jun.approval_new ? this.state.jun.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jun ? this.state.jun.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jun ? this.state.jun.report_issued ? this.state.jun.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jun ? this.state.jun.approval_issued ? this.state.jun.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jun ? this.state.jun.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='6'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/7'} target='_blank'>Juli</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jul ? this.state.jul.report_new ? this.state.jul.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jul ? this.state.jul.approval_new ? this.state.jul.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jul ? this.state.jul.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jul ? this.state.jul.report_issued ? this.state.jul.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jul ? this.state.jul.approval_issued ? this.state.jul.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.jul ? this.state.jul.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='7'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/8'} target='_blank'>Agustus</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.aug ? this.state.aug.report_new ? this.state.aug.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.aug ? this.state.aug.approval_new ? this.state.aug.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.aug ? this.state.aug.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.aug ? this.state.aug.report_issued ? this.state.aug.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.aug ? this.state.aug.approval_issued ? this.state.aug.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.aug ? this.state.aug.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='8'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/9'} target='_blank'>September</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.sep ? this.state.sep.report_new ? this.state.sep.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.sep ? this.state.sep.approval_new ? this.state.sep.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.sep ? this.state.sep.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.sep ? this.state.sep.report_issued ? this.state.sep.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.sep ? this.state.sep.approval_issued ? this.state.sep.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.sep ? this.state.sep.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='9'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/10'} target='_blank'>Oktober</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.okt ? this.state.okt.report_new ? this.state.okt.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.okt ? this.state.okt.approval_new ? this.state.okt.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.okt ? this.state.okt.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.okt ? this.state.okt.report_issued ? this.state.okt.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.okt ? this.state.okt.approval_issued ? this.state.okt.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.okt ? this.state.okt.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='10'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/11'} target='_blank'>November</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.nov ? this.state.nov.report_new ? this.state.nov.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.nov ? this.state.nov.approval_new ? this.state.nov.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.nov ? this.state.nov.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.nov ? this.state.nov.report_issued ? this.state.nov.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.nov ? this.state.nov.approval_issued ? this.state.nov.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.nov ? this.state.nov.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='11'>
                                    <TableCell>
                                        <a href={'/operation-statement/'+this.state.year+'/12'} target='_blank'>Desember</a>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.dec ? this.state.dec.report_new ? this.state.dec.report_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.dec ? this.state.dec.approval_new ? this.state.dec.approval_new.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.dec ? this.state.dec.total_new : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.dec ? this.state.dec.report_issued ? this.state.dec.report_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.dec ? this.state.dec.approval_issued ? this.state.dec.approval_issued.value : '' : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.dec ? this.state.dec.total_issued : '' }
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='12'
                                className='bold'>
                                    <TableCell>TOTAL</TableCell>
                                    <TableCell>
                                        {this.state.total_year ? this.state.total_year.total_report_new  : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.total_year ? this.state.total_year.total_approval_new  : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.total_year ? this.state.total_year.total_new  : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.total_year ? this.state.total_year.total_report_issued  : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.total_year ? this.state.total_year.total_approval_issued  : '' }
                                    </TableCell>
                                    <TableCell>
                                        {this.state.total_year ? this.state.total_year.total_issued  : '' }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            }

                        </Table>
                    </div>
                </div>

            </div>

        )
    }
}

export default ContentDashboard;