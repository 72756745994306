import moment from "moment";

class CommonHelper {

    static getFileName = (file, defaultName = 'file') => {
        return (file?.name) ? file.name.substr(0, file.name.lastIndexOf('.')) : defaultName;
    }

    static getFileExtension = (file) => {
        return file.name.substr(file.name.lastIndexOf('.') + 1);
    }

    static getFileNameWithTimestamp = (file) => {
        const name = this.getFileName(file);
        const extension = this.getFileExtension(file);

        return `${name}_${moment().format('YYYYMMDDhhmmssSSS')}.${extension}`;
    }
}

export default CommonHelper;