import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {history} from "../../../shared/configure-store";
import NumberFormat from "react-number-format";
import img from "../../../images/home.png";
import iconApproval from "../../../images/icon-4.png";
import Skeleton from "@material-ui/lab/Skeleton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Select from "react-select";

require('dotenv').config();

class OSClients extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            clients: [],
            showDialog: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            broker: [],
            adjuster: [],
            shipowner: [],
            underwriter: [],
            totalCase: 0,
            totalOnprogress: 0,
            totalFollowup: 0,
            pendingIdr: 0,
            pendingUsd: 0,
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            client_id: '',

            isEdit: false,
            isDelete: false,
            isCreate: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - OS Client';


        this.__fetchData(false);

        Api.get('/clients?limit=0').then(resp => {

            if (resp.data) {

                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.name,
                    }
                });

                this.setState({
                    clients: data,
                })

            }

        }).catch(err => {
            console.log(err);
        });


    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __fetchData = update => {

        this._isMounted = true;

        let route = '/reports/os-clients';

        if(this.state.client_id) {
            route = '/reports/os-clients?name='+this.state.client_id;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {

                    this.setState({
                        data: resp.data,
                        broker: resp.data.broker,
                        adjuster: resp.data.adjuster,
                        shipowner: resp.data.shipowner,
                        underwriter: resp.data.underwriter,
                        totalCase: resp.data.total_all.total_case,
                        totalOnprogress: resp.data.total_all.total_onprogress,
                        totalFollowup: resp.data.total_all.total_followup,
                        pendingIdr: resp.data.total_all.pending_idr,
                        pendingUsd: resp.data.total_all.pending_usd,
                        loading: false
                    })

                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleChangeClient = (e) => {
        this.setState({
            client_id: e.value,
        },() => {
            this.__fetchData(false);
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">OS Clients List</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">OS Clients</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md'>
                                        <div className="card-info green">
                                            <label className="label-grey">Total <br />Case</label>
                                            <p className="number">{this.state.totalCase && this.state.totalCase}</p>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="card-info green">
                                            <label className="label-grey">On Progress <br />Case</label>
                                            <p className="number">{this.state.totalOnprogress && this.state.totalOnprogress}</p>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="card-info green">
                                            <label className="label-grey">Follow Up <br />Case</label>
                                            <p className="number">{this.state.totalFollowup && this.state.totalFollowup}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md'>
                                        <div className="card-info yellow">
                                            <label className="label-grey">Total Not Paid <br /> Invoice IDR</label>
                                            <p className="number">
                                                <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.pendingIdr && this.state.pendingIdr} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="card-info yellow">
                                            <label className="label-grey">Total Not Paid <br /> Invoice USD</label>
                                            <p className="number">
                                                <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.pendingUsd && this.state.pendingUsd} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Client</label>
                                    <Select options = {this.state.clients}
                                            onChange={(e) => this.handleChangeClient(e,'client_id')}
                                            value={this.state.client_id}
                                    />
                                    <div className='text-danger'>{this.state.errors.client_id}</div>
                                </div>
                            </div>
                        </div>
                        <h2 className='section-title'>BROKER</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>No</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Client</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Type</TableCell>
                                    <TableCell colSpan='3' className='text-center'>Case</TableCell>
                                    <TableCell colSpan='2' className='text-center'>Pending Payment All</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Comment</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>Total Case</TableCell>
                                    <TableCell className='text-center'>On Progress Case</TableCell>
                                    <TableCell className='text-center'>Follow Up Case</TableCell>
                                    <TableCell className='text-center' style={{minWidth:200}}>Total OS Invoice <br />(IDR)</TableCell>
                                    <TableCell className='text-center'>Total OS Invoice (USD)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.broker.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No OS client</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.broker.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={'/os-clients/'+row.id} target='_blank' className='text-uppercase'>{row.name === null ? 'NA' : row.name}</a>
                                                </TableCell>
                                                <TableCell>{row.type === null ? 'NA' : row.type}</TableCell>
                                                <TableCell>{row.total_case === null ? 'NA' : row.total_case}</TableCell>
                                                <TableCell>{row.case_onprogress === null ? 'NA' : row.case_onprogress}</TableCell>
                                                <TableCell>{row.case_followup === null ? 'NA' : row.case_followup}</TableCell>
                                                <TableCell>
                                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_idr === null ? '-' : row.pending_idr} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>

                                                    $ <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_usd === null ? '-' : row.pending_usd} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>{row.last_comment === null ? '-' : row.last_comment}
                                                    <Link to={'/clients/edit/' + row.id} target='_blank' className='btn-icon mr-2'>
                                                        <i className='fas fa-edit'> </i>
                                                    </Link></TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>

                        <hr />
                        <h2 className='section-title'>ADJUSTER</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>No</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Client</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Type</TableCell>
                                    <TableCell colSpan='3' className='text-center'>Case</TableCell>
                                    <TableCell colSpan='2' className='text-center'>Pending Payment All</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Comment</TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>Total Case</TableCell>
                                    <TableCell className='text-center'>On Progress Case</TableCell>
                                    <TableCell className='text-center'>Follow Up Case</TableCell>
                                    <TableCell className='text-center' style={{minWidth:200}}>Total OS Invoice <br />(IDR)</TableCell>
                                    <TableCell className='text-center'>Total OS Invoice (USD)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.adjuster.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No OS client</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.adjuster.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={'/os-clients/'+row.id} target='_blank' className='text-uppercase'>{row.name === null ? 'NA' : row.name}</a>
                                                </TableCell>
                                                <TableCell>{row.type === null ? 'NA' : row.type}</TableCell>
                                                <TableCell>{row.total_case === null ? 'NA' : row.total_case}</TableCell>
                                                <TableCell>{row.case_onprogress === null ? 'NA' : row.case_onprogress}</TableCell>
                                                <TableCell>{row.case_followup === null ? 'NA' : row.case_followup}</TableCell>
                                                <TableCell>
                                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_idr === null ? '-' : row.pending_idr} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>

                                                    $ <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_usd === null ? '-' : row.pending_usd} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>{row.last_comment === null ? '-' : row.last_comment}
                                                    <Link to={'/clients/edit/' + row.id} target='_blank' className='btn-icon mr-2'>
                                                        <i className='fas fa-edit'> </i>
                                                    </Link></TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                        <hr />
                        <h2 className='section-title'>SHIPOWNER</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>No</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Client</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Type</TableCell>
                                    <TableCell colSpan='3' className='text-center'>Case</TableCell>
                                    <TableCell colSpan='2' className='text-center'>Pending Payment All</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Comment</TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>Total Case</TableCell>
                                    <TableCell className='text-center'>On Progress Case</TableCell>
                                    <TableCell className='text-center'>Follow Up Case</TableCell>
                                    <TableCell className='text-center' style={{minWidth:200}}>Total OS Invoice <br />(IDR)</TableCell>
                                    <TableCell className='text-center'>Total OS Invoice (USD)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.shipowner.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No OS client</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.shipowner.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={'/os-clients/'+row.id} target='_blank' className='text-uppercase'>{row.name === null ? 'NA' : row.name}</a>
                                                </TableCell>
                                                <TableCell>{row.type === null ? 'NA' : row.type}</TableCell>
                                                <TableCell>{row.total_case === null ? 'NA' : row.total_case}</TableCell>
                                                <TableCell>{row.case_onprogress === null ? 'NA' : row.case_onprogress}</TableCell>
                                                <TableCell>{row.case_followup === null ? 'NA' : row.case_followup}</TableCell>
                                                <TableCell>
                                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_idr === null ? '-' : row.pending_idr} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>

                                                    $ <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_usd === null ? '-' : row.pending_usd} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>{row.last_comment === null ? '-' : row.last_comment}
                                                    <Link to={'/clients/edit/' + row.id} target='_blank' className='btn-icon mr-2'>
                                                        <i className='fas fa-edit'> </i>
                                                    </Link></TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                        <hr />
                        <h2 className='section-title'>UNDERWRITER</h2>
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>No</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Client</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Type</TableCell>
                                    <TableCell colSpan='3' className='text-center'>Case</TableCell>
                                    <TableCell colSpan='2' className='text-center'>Pending Payment All</TableCell>
                                    <TableCell rowSpan='2' className='text-center'>Comment</TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>Total Case</TableCell>
                                    <TableCell className='text-center'>On Progress Case</TableCell>
                                    <TableCell className='text-center'>Follow Up Case</TableCell>
                                    <TableCell className='text-center' style={{minWidth:200}}>Total OS Invoice <br />(IDR)</TableCell>
                                    <TableCell className='text-center'>Total OS Invoice (USD)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.underwriter.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No OS client</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.underwriter.map((row, key) => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <a href={'/os-clients/'+row.id} target='_blank' className='text-uppercase'>{row.name === null ? 'NA' : row.name}</a>
                                                </TableCell>
                                                <TableCell>{row.type === null ? 'NA' : row.type}</TableCell>
                                                <TableCell>{row.total_case === null ? 'NA' : row.total_case}</TableCell>
                                                <TableCell>{row.case_onprogress === null ? 'NA' : row.case_onprogress}</TableCell>
                                                <TableCell>{row.case_followup === null ? 'NA' : row.case_followup}</TableCell>
                                                <TableCell>
                                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_idr === null ? '-' : row.pending_idr} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>

                                                    $ <NumberFormat thousandSeparator={true} decimalScale={2} value={row.pending_usd === null ? '-' : row.pending_usd} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </TableCell>
                                                <TableCell>{row.last_comment === null ? '-' : row.last_comment}
                                                    <Link to={'/clients/edit/' + row.id} target='_blank' className='btn-icon mr-2'>
                                                        <i className='fas fa-edit'> </i>
                                                    </Link>
                                                </TableCell>

                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                </div>



                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default OSClients;
