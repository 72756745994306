import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import _ from 'lodash';

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});


class ClientsAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            type: '',
            detail_client_type: '',
            name: '',
            email: '',
            telephone: '',
            fax: '',
            address: '',
            city: '',
            province: '',
            zip: '',
            country: '',
            notes: '',
            active: '',
            pic: [],
            npwp_no: '',
            npwp_company_name: '',
            npwp_address: '',
            director_name: '',
            company_phone: '',
            company_email: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Add Client';

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let send = [];

        if(this.state.pic.length > 0) {
            _.forEach(this.state.pic, function(v){
                send.push(v)
            });
        }


        let params = {
            type : this.state.type,
            detail_client_type : this.state.detail_client_type,
            name : this.state.name,
            email : this.state.email,
            telephone : this.state.telephone,
            fax : this.state.fax,
            address : this.state.address,
            city : this.state.city,
            province : this.state.province,
            zip : this.state.zip,
            country : this.state.country,
            website : this.state.notes,
            active : this.state.active,
            pic: send,
            npwp_no: this.state.npwp_no,
            npwp_company_name: this.state.npwp_company_name,
            npwp_address: this.state.npwp_address,
            director_name: this.state.director_name,
            company_phone: this.state.company_phone,
            company_email: this.state.company_email,
        };

        Api.post('/clients', params, false).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            history.push('/clients');

            this.showMessage(true, 'Client added');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });


    };

    handleAdd = (e) => {
        e.preventDefault();

        this.setState({
                errors: {},
            }
        );

        let exp = this.state.pic;
        let temp = {
            id : '',
            name : '',
            phone : '',
            email : '',
        };

        exp.push(temp);
        this.setState({
            pic: exp,
            }
        );

    };

    handleDelete = (id) => {
        this.setState({
                errors: {},
            }
        );

        let data = this.state.pic.filter((e, idx) =>idx !== id);

        this.setState({
            pic: data,
        });
    };

    handleChangePIC = (e, prop, id) => {
        let data = this.state.pic;
        _.forEach(data, function(v,k){

            console.log(k)
            if(k === id){
                v[prop] = e.target.value;
            }
        });

        this.setState({
            errors: {},
            pic: data,
        });
    };


    handleGo  = (link) => {
        history.push(link);
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Create Client</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/clients" >Clients</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Create</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <form name="add" id="addClient" className="row" noValidate>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Client Type</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="type"
                                               name="type"
                                               label="Type"
                                               onChange={(e) => this.handleChange(e, 'type')}
                                               value={this.state.type}
                                               fullWidth
                                    >
                                        <MenuItem value="Underwriter">
                                            Underwriter
                                        </MenuItem>
                                        <MenuItem value="Broker">
                                            Broker
                                        </MenuItem>
                                        <MenuItem value="Shipowner">
                                            Shipowner
                                        </MenuItem>
                                        <MenuItem value="Adjuster">
                                            Adjuster
                                        </MenuItem>
                                    </TextField>
                                    {validator.message('type', this.state.type, 'required')}
                                    <div className='text-danger'>{this.state.errors.type}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Detail Client Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="detail_client_type"
                                               name="detail_client_type"
                                               label="Detail Type"
                                               onChange={(e) => this.handleChange(e, 'detail_client_type')}
                                               value={this.state.detail_client_type}
                                               fullWidth
                                    >
                                        <MenuItem value="H&M">
                                            H&M
                                        </MenuItem>
                                        <MenuItem value="P&I">
                                            P&I
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.detail_client_type}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status</label>
                                    <TextField variant="outlined"
                                               select
                                               id="active"
                                               name="active"
                                               label="Status"
                                               onChange={(e) => this.handleChange(e, 'active')}
                                               value={this.state.active}
                                               fullWidth
                                    >
                                        <MenuItem value="1">
                                            Active
                                        </MenuItem>
                                        <MenuItem value="0">
                                            Inactive
                                        </MenuItem>
                                    </TextField>
                                    {validator.message('active', this.state.active, 'required')}
                                    <div className='text-danger'>{this.state.errors.active}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Company</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Name</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='name'
                                               name="name"
                                               label="Company Name"
                                               onChange={(e) => this.handleChange(e, 'name')}
                                               value={this.state.name}
                                               fullWidth
                                    />
                                    {validator.message('name', this.state.name, 'required')}
                                    <div className='text-danger'>{this.state.errors.name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='email'
                                               name="email"
                                               label="Email"
                                               onChange={(e) => this.handleChange(e, 'email')}
                                               value={this.state.email}
                                               fullWidth
                                    />
                                    {validator.message('email', this.state.email, 'email')}
                                    <div className='text-danger'>{this.state.errors.email}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='address'
                                               name="address"
                                               label="Address"
                                               onChange={(e) => this.handleChange(e, 'address')}
                                               value={this.state.address}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.address}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>City</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='city'
                                               name="city"
                                               label="City"
                                               onChange={(e) => this.handleChange(e, 'city')}
                                               value={this.state.city}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.city}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Province</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='province'
                                               name="province"
                                               label="Province"
                                               onChange={(e) => this.handleChange(e, 'province')}
                                               value={this.state.province}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.province}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Zip</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='zip'
                                               name="zip"
                                               label="Zip"
                                               onChange={(e) => this.handleChange(e, 'zip')}
                                               value={this.state.zip}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.zip}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Country</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='country'
                                               name="country"
                                               label="Country"
                                               onChange={(e) => this.handleChange(e, 'country')}
                                               value={this.state.country}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.country}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Telephone</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='telephone'
                                               name="telephone"
                                               label="Telephone"
                                               onChange={(e) => this.handleChange(e, 'telephone')}
                                               value={this.state.telephone}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.telephone}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Fax</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='fax'
                                               name="fax"
                                               label="Fax"
                                               onChange={(e) => this.handleChange(e, 'fax')}
                                               value={this.state.fax}
                                               fullWidth
                                    />

                                    <div className='text-danger'>{this.state.errors.fax}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Notes</label>
                                    <TextField 
                                        className='multiline-no-padding'
                                        variant="outlined"
                                        type='text'
                                        id='notes'
                                        name="notes"
                                        label="Notes"
                                        onChange={(e) => this.handleChange(e, 'notes')}
                                        value={this.state.notes}
                                        inputProps={{ maxLength: 1500 }}
                                        fullWidth
                                        multiline
                                    />
                                    <div className='text-danger'>{this.state.errors.notes}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>PIC</h3>
                                </div>
                                <button onClick={this.handleAdd} className='btn-link px-0'>Add More PIC</button>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>PIC Name | Bagian | Tahun</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>PIC Phone</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">    
                                    <label>PIC Email</label>
                                </div>
                            </div>
                            <div className="col-12">
                                {
                                    this.state.pic.length > 0 &&
                                    this.state.pic.map(function (item, idx) {
                                        return <div className="row" key={idx}>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <TextField
                                                        className='multiline-no-padding'
                                                        variant="outlined"
                                                        type='text'
                                                        id={'name-'+idx}
                                                        name={'name-'+idx}
                                                        label="PIC Name | Bagian | Tahun"
                                                        onChange={(e) => this.handleChangePIC(e, 'name', idx)}
                                                        value={item.name}
                                                        inputProps={{ maxLength: 1500 }}
                                                        fullWidth
                                                        multiline
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <TextField variant="outlined"
                                                               type='text'
                                                               id={'phone-'+idx}
                                                               name={'phone-'+idx}
                                                               label="PIC Phone"
                                                               onChange={(e) => this.handleChangePIC(e, 'phone', idx)}
                                                               value={item.phone}
                                                               fullWidth
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <TextField variant="outlined"
                                                               type='text'
                                                               id={'email-'+idx}
                                                               name={'email-'+idx}
                                                               label="PIC Email"
                                                               onChange={(e) => this.handleChangePIC(e, 'email', idx)}
                                                               value={item.email}
                                                               fullWidth
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }.bind(this))
                                }
                            </div>





                            <div className="col-12">
                                <div className="group-title">
                                    <h3>NPWP</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>No NPWP</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='npwp_no'
                                               name="npwp_no"
                                               label="No NPWP"
                                               onChange={(e) => this.handleChange(e, 'npwp_no')}
                                               value={this.state.npwp_no}
                                               fullWidth
                                    />
                                    {validator.message('npwp_no', this.state.npwp_no, 'numeric')}
                                    <div className='text-danger'>{this.state.errors.npwp_no}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>NPWP Company Name</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='npwp_company_name'
                                               name="npwp_company_name"
                                               label="Company Name"
                                               onChange={(e) => this.handleChange(e, 'npwp_company_name')}
                                               value={this.state.npwp_company_name}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.npwp_company_name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>NPWP Address</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='npwp_address'
                                               name="npwp_address"
                                               label="NPWP Address"
                                               onChange={(e) => this.handleChange(e, 'npwp_address')}
                                               value={this.state.npwp_address}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.npwp_address}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Director</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Director Name</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='director_name'
                                               name="director_name"
                                               label="Director Name"
                                               onChange={(e) => this.handleChange(e, 'director_name')}
                                               value={this.state.director_name}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.director_name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='company_phone'
                                               name="company_phone"
                                               label="Phone"
                                               onChange={(e) => this.handleChange(e, 'company_phone')}
                                               value={this.state.company_phone}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.director_name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='company_email'
                                               name="company_email"
                                               label="Email"
                                               onChange={(e) => this.handleChange(e, 'company_email')}
                                               value={this.state.company_email}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.director_name}</div>
                                </div>
                            </div>


                            <div className="col-12 text-center">
                                <Button
                                    variant="contained"
                                    className="mr-3"
                                    onClick={() => this.handleGo('/clients')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=""
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && 'disabled' }
                                >
                                    Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default ClientsAdd;
