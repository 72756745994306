import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

require('dotenv').config();

class Roles extends Component {
    _isMounted = false;

    constructor(props) {

        //let currentDate = new Date();

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            idDelete: '',
            currDelete: '',

            display_name: '',
            description: '',
            roleId: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Roles';

        this.__fetchData(false);

    }

    __fetchData = update => {
        this._isMounted = true;

        let route = '/roles?limit=0';

        Api.get(route).then(resp => {
            if (resp.data) {

                console.log(resp.data);
                this.setState({
                    rows: resp.data,
                    loading: false,
                })

            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/roles/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                    showDialog: false,
                    }
                );

                this.showMessage(true, 'Role successfully deleted');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                    showDialog: false
                    }
                );

                this.showMessage(true, 'Role successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h2 className="page-title">ROLES</h2>
                </div>
                <div className="col-12 mt-3">
                    <div className="table-wrapper">
                        <div className="text-right">
                            <TextField
                                id="input-with-icon-textfield"
                                variant="outlined"
                                className="search-field"
                                onChange={(e) => this.handleChange(e, 'searchBy')}
                                onKeyDown={this.handleKeyDown}
                                onBlur={this.handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Search click"
                                                onClick={this.handleSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className="clickable" onClick={() => this.handleSort('display_name')} >Name
                                    </TableCell>
                                    <TableCell className="clickable" onClick={() => this.handleSort('description')} >Description
                                    </TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={5} style={{ textAlign: "center" }}>No role</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.map(row => (
                                            <TableRow
                                                key={row.id}
                                                selected={row.selected}>
                                                <TableCell>{row.display_name === null ? 'NA' : row.display_name}</TableCell>
                                                <TableCell>{row.description === null ? 'NA' : row.description}</TableCell>
                                                <TableCell>
                                                    <Link to={"/roles/permission/"+row.id} className="btn-icon mr-2">
                                                        <i className="fas fa-cogs"> </i>
                                                    </Link>
                                                    {/*<button className="btn-icon" onClick={() => this.handleOpen(row)}>
                                                        <i className="fas fa-trash-alt"> </i>
                                                    </button>*/}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">DELETE ROLE: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wanna delete this user?</p>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleClose}>
                            Cancel
                        </button>
                        <button className="btn btn-blue" onClick={this.handleDelete}>
                            Delete
                        </button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />

            </div>
        )
    }
}

export default Roles;
