export const PROJECT_TYPE_IDS = {
    report: '647bbf15-c72a-4cdc-bace-464c5368e325',
    approval: '80e0e031-4932-466d-93c5-107ffd361218',
};

export const TO_DO_LIST_COLORS = {
    eventOpen: '#ff9f00',
    eventClosed: '#1ebead',
    buttonDelete: 'red',
    buttonReopenEvent: '#ff9f00',
    buttonCloseEvent: '#1ebead',
};

export const TO_DO_LIST_OVERVIEW_ROLES = [
    'it',
    'super',
    'management',
];

export default {
    PROJECT_TYPE_IDS,
    TO_DO_LIST_COLORS,
    TO_DO_LIST_OVERVIEW_ROLES,
};