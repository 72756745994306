import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AsyncPaginate as SelectPaginate } from 'react-select-async-paginate';
import moment from "moment";
import _ from "lodash";
import Select from 'react-select';
import SimpleReactValidator from "simple-react-validator";

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileIcon from '@material-ui/icons/Description';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Api from "../../../libraries/api";
import MomentUtils from '@date-io/moment';
import NumberFormat from 'react-number-format';
import CommonHelper from "../../../libraries/common-helper";
import ErrorHandling from "../../../libraries/error-handling";
import { toast, ToastContainer } from "react-toastify";
import { history } from "../../../shared/configure-store";
import { PROJECT_TYPE_IDS } from '../../../constants/global';

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorObj = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorRepair = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class ProjectsEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},
            showConfirmDelete: false,

            types: [],
            detailTypes: [],
            approvalCategories: [],
            underwriters: [],
            brokers: [],
            adjusters: [],
            shipowners: [],
            surveyors: [],
            adminslist: [],
            finalReviewers: [],
            case_number: '',
            project_number: '',
            ext_project_number: '',
            case_type: '',
            type_id: '',
            detail_type_id: '',
            approval_category_id: '',
            vessel_name: '',
            vessel_type: '',
            vessel_grt: '',
            vessel_yob: '',
            broker: '',
            adjuster: '',
            shipowner: '',
            underwriter: [],
            casualty: '',
            place_of_accident: '',
            instruction_date: null,
            date_of_accident: null,
            date_hard_copy: null,
            instruction_by: '',
            pic_case: '',
            currency: '',
            instructor: '',
            estimation_cost: '',
            place_of_repair: '',
            total_account_submitted: '',
            total_account_approved: '',
            total_account_approved_for_cost: '',
            total_account_approved_with_comment: '',
            total_account_not_approved: '',
            residual_scrap_value: '',
            repairs: [],
            select_file_upload: null,
            select_file_delete: null,
            files: [],

            surveyor: [],
            admins: [],
            finalReviewer: [],
            under: '',
            soft_copy_data: 0,
            hard_copy_report: 0,
            hard_copy_invoice: 0,

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleDel = this.handleDel.bind(this);
        this.handleSurvey = this.handleSurvey.bind(this);
        this.handleAdmin = this.handleAdmin.bind(this);
        this.handleReview = this.handleReview.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        document.title = 'Abadi - Edit Project';

        Api.get('/types').then(resp => {
            if (resp.data) this.setState({ types: resp.data });
        });

        Api.get('/detailtypes').then(resp => {
            if (resp.data) this.setState({ detailTypes: resp.data });
        });

        Api.get('/approval_categories').then(resp => {
            if (resp.data) this.setState({ approvalCategories: resp.data });
        });

        Api.get('/clients?type=Underwriter&limit=0').then(resp => {
            if (resp.data) {
                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });

                this.setState({
                    underwriters: data,
                })
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/clients?type=Broker&limit=0').then(resp => {

            if (resp.data) {

                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });

                this.setState({
                    brokers: data,
                })

            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/clients?type=Adjuster&limit=0').then(resp => {

            if (resp.data) {

                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });

                this.setState({
                    adjusters: data,
                })

            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/clients?type=Shipowner&limit=0').then(resp => {

            if (resp.data) {

                let data = resp.data.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });

                this.setState({
                    shipowners: data,
                })

            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/users?roles=surveyor&limit=0').then(resp => {

            if (resp.data) {

                this.setState({
                    surveyors: resp.data,
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/users?additional=final-reviewer&limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    finalReviewers: resp.data,
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/projects/'+id).then(resp => {

            if (resp.data) {
                let data = resp.data;
                let typeProject = '';
                if(data.underWriters.length > 0) {
                    _.forEach(data.underWriters, function(v){
                      v.id = v.clients_id;
                    });
                }
                if (data.type) {
                    if (data.type.name === "Report") {
                        typeProject = 'adm-report';
                    } else if(data.type.name === "Approval"){
                        typeProject = 'adm-approval';
                    }

                    Api.get('/users?additional='+typeProject+'&limit=0').then(resp => {
                        if (resp.data) this.setState({ adminslist: resp.data });
                    });
                }
                if(data.admins.length > 0) {
                    _.forEach(data.admins, function(v){
                        v.id = v.user_id
                    });
                }
                if(data.surveyor.length > 0) {
                    _.forEach(data.surveyor, function(v){
                        v.id = v.user_id
                    });
                }
                if(data.finalReviewer.length > 0) {
                    _.forEach(data.finalReviewer, function(v){
                        v.id = v.user_id
                    });
                }

                let brk = {};
                if(data.broker) {
                    brk = {
                        label: data.broker.name,
                        value: data.broker.id,
                    }
                }

                let adj = {};
                if(data.adjuster) {
                    adj = {
                        label: data.adjuster.name,
                        value: data.adjuster.id,
                    }
                }

                let shp = {};
                if(data.shipowner) {
                    shp = {
                        label: data.shipowner.name,
                        value: data.shipowner.id,
                    }
                }

                let instructor = {};
                if (data?.instructor) {
                    instructor = {
                        label: data?.instructor?.name,
                        value: data?.instructor?.id,
                    };
                }

                this.setState({
                    case_number: data.caseNumber ? data.caseNumber : '',
                    project_number: data.projectNumber ? data.projectNumber : '',
                    ext_project_number: data.extProjectNumber ? data.extProjectNumber : '',
                    case_type: data.caseType ? data.caseType : '',
                    type_id: data.type ? data.type.id : '',
                    detail_type_id: data.detailType ? data.detailType.id :'',
                    approval_category_id: data.approvalCategory ? data.approvalCategory.id :'',
                    vessel_name: data.vesselName ? data.vesselName : '',
                    vessel_type: data.vesselType ? data.vesselType : '',
                    vessel_grt: data.vesselGrt ? data.vesselGrt : '',
                    vessel_yob: data.vesselYob ? data.vesselYob : '',
                    broker: data.broker ? brk : '',
                    adjuster: data.adjuster ? adj : '',
                    shipowner: data.shipowner ? shp : '',
                    underwriter: data.underWriters ? data.underWriters : [],
                    casualty: data.casualty ? data.casualty : '',
                    place_of_accident: data.placeOfAccident ? data.placeOfAccident : '',
                    instruction_date: data.instructionDate ? data.instructionDate : null,
                    date_of_accident: data.dateOfAccident ? data.dateOfAccident : null,
                    instruction_by: data.instructionBy ? data.instructionBy : '',
                    pic_case: data.picCase ? data.picCase : '',
                    currency: data.currency ? data.currency : '',
                    surveyor: data.surveyor ? data.surveyor : [],
                    finalReviewer: data.finalReviewer ? data.finalReviewer : [],
                    admins: data.admins ? data.admins : [],
                    hard_copy_invoice: data.hardCopyInvoice ? data.hardCopyInvoice : '',
                    hard_copy_report: data.hardCopyReport ? data.hardCopyReport : '',
                    soft_copy_data: data.softCopyData ? data.softCopyData : '',
                    date_hard_copy: data.dateHardCopy ? data.dateHardCopy : null,
                    instructor: data?.instructor ? instructor : '',
                    estimation_cost: data.estimationCost && data.estimationCost !== "null" ? data.estimationCost : '',
                    place_of_repair: data?.place_of_repair || '',
                    total_account_submitted: data?.total_account_submitted || '',
                    total_account_approved: data?.total_account_approved || '',
                    total_account_approved_for_cost: data?.total_account_approved_for_cost || '',
                    total_account_approved_with_comment: data?.total_account_approved_with_comment || '',
                    total_account_not_approved: data?.total_account_not_approved || '',
                    residual_scrap_value: data?.residual_scrap_value || '',
                    repairs: data?.repairs && data.repairs.length > 0 ? data?.repairs : [],
                    files: data?.files && data.files.length > 0 ? data?.files : [],
                });
            }

        }).catch(err => {
            console.log(err);
        });
    }

    refetchProjectFiles = () => {
        const id = this.props.match.params.id;
        Api.get(`/projects/${id}`).then(resp => {
            const data = resp?.data;
            if (data) this.setState({ files: data?.files && data.files.length > 0 ? data?.files : [] });
        });
    }

    loadOptionsInstructor = async (search, _loadedOptions, { page }) => {
        let data = [];

        await Api.get(`/clients?search=${search}&limit=10&page=${page}`).then(resp => {
            if (resp?.data?.length > 0) {
                data = resp.data.map((item)=> ({ label: item.name, value: item.id }));
            }
        }).catch((err) => {
            console.log(err);
        });

        return {
            options: data,
            hasMore: data.length > 0 ? true : false,
            additional: { page: page + 1 },
        };
    };

    handleType (e, prop){
        Api.get('/detailtypes?search=type_id:'+e.target.value).then(resp => {
            if (resp.data) {
                this.setState({ detailTypes: resp.data });
            }
        }).catch(err => {
            console.log(err);
        });

        this.setState({ [prop]: e.target.value });
    };

    handleChange (e, prop){
        this.setState({ [prop]: e.target.value });
    };

    handleChangeClient (e, prop){
        this.setState({ [prop]: e });
    };

    handleAdmin (e, values) {
        console.log(values);
        this.setState({
            admins: values
        }, () => {
            console.log(this.state.admins);
        });

    };

    handleReview (e, values) {
        this.setState({
            finalReviewer: values
        }, () => {
            console.log(this.state.finalReviewer);
        });

    };

    handleSurvey (e, values) {
        this.setState({
            surveyor: values
        }, () => {
            console.log(this.state.surveyor);
        });

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        }, () => {

        });

    };

    handleNewRepair = (e) => {
        this.setState({ errors: {} });

        let repairs = this.state.repairs;
        let temp = {
            currency : '',
            item : '',
            amount : '',
        };

        repairs.push(temp);
        this.setState({ repairs });
    };

    handleChangeRepair = (e, prop, id) => {
        let data = this.state.repairs;

        _.forEach(data, function(v,k){
            if (k === id) v[prop] = e.target.value;
        });

        this.setState({
            errors: {},
            repairs: data,
        });
    };

    handleDelRepair (e, id){
        e.preventDefault();
        this.setState({ errors: {} });

        let data = this.state.repairs.filter((e, idx) => idx !== id);
        this.setState({ repairs: data });
    };

    handleSelectFile = e => {
        this.setState({ select_file_upload: e?.target?.files?.[0] || null });
    };

    handleUploadFile = e => {
        const projectId = this.props.match.params.id;
        const file = this.state.select_file_upload;

        if (file) {
            const fileNameWithTimeStamp = CommonHelper.getFileNameWithTimestamp(file);
            const newFile = new File([file], fileNameWithTimeStamp, { type: file.type });
    
            let formData = new FormData();
            formData.append('file', newFile);
    
            Api.putFile(`/project/${projectId}/files/create`, {
                method: 'POST',
                body: formData
            }).then(() => {
                this.showMessage(true, 'File upload sukses');
                this.refetchProjectFiles();
            }).catch(() => {
                this.showMessage(false, 'Gagal mengupload file');
            });
        } else {
            this.showMessage(false, 'Pilih file terlebih dahulu');
        }
    };

    handleSelectDeleteFile = (fileId) => {
        this.setState({ showConfirmDelete: true, select_file_delete: fileId || null });
    };

    handleDeleteFile = () => {
        const fileId = this.state.select_file_delete;
        if (fileId) {
            Api.delete(`/projects/files/${fileId}/delete`, '', false).then(() => {
                this.setState({ showConfirmDelete: false, select_file_delete: null });
                this.showMessage(true, 'File dihapus');
                this.refetchProjectFiles();
            }).catch(err => {
                this.setState({ showConfirmDelete: false, select_file_delete: null });
                this.showMessage(false, 'Gagal menghapus file');
            });
        } else {
            this.setState({ showConfirmDelete: false, select_file_delete: null });
            this.showMessage(false, 'File yang akan dihapus tidak ditemukan');
        }
    }
    
    handleCloseConfirmDelete = () => {
        this.setState({ showConfirmDelete: false, select_file_delete: null });
    };

    showMessage = (status, message) => {
        if (status) toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        else toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {
            this.setState({ errors: validator.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingButton: true });

        let temp = [];
        if(this.state.underwriter.length > 0) {
            _.forEach(this.state.underwriter, function(v){
                temp.push(v.id)
            });
        }

        let surveyor = [];
        _.forEach(this.state.surveyor, function(v){
            surveyor.push(v.id)
        });

        let admin = [];
        let isDelete = false;
        _.forEach(this.state.admins, function(v){
            admin.push(v.id)
        });
        let finalReviewer = [];
        if(this.state.finalReviewer.length > 0) {
            _.forEach(this.state.finalReviewer, function(v){
                finalReviewer.push(v.id)
            });
        }else {
            isDelete = true;
        }

        let params = {
            case_number : this.state.case_number,
            project_number : this.state.project_number,
            ext_project_number : this.state.ext_project_number,
            case_type : this.state.case_type,
            type_id : this.state.type_id,
            detail_type_id : this.state.detail_type_id,
            approval_category_id : this.state.approval_category_id,
            vessel_name : this.state.vessel_name,
            vessel_type : this.state.vessel_type,
            vessel_grt : this.state.vessel_grt,
            vessel_yob : this.state.vessel_yob,
            broker : this.state.broker ? this.state.broker.value : null,
            adjuster : this.state.adjuster && this.state.adjuster.value,
            shipowner : this.state.shipowner && this.state.shipowner.value,
            underwriter : temp,
            surveyor : surveyor,
            admins : admin,
            finalReviewer : finalReviewer,
            deleteFinalReviewer: isDelete,
            casualty: this.state.casualty,
            place_of_accident: this.state.place_of_accident,
            instruction_date: this.state.instruction_date === null ? null : moment(this.state.instruction_date).format('YYYY-MM-DD'),
            date_of_accident: this.state.date_of_accident === null ? null : moment(this.state.date_of_accident).format('YYYY-MM-DD'),
            instruction_by: this.state.instruction_by,
            pic_case: this.state.pic_case,
            currency: this.state.currency,
            hard_copy_invoice: this.state.hard_copy_invoice,
            hard_copy_report: this.state.hard_copy_report,
            soft_copy_data: this.state.soft_copy_data,
            date_hard_copy: this.state.date_hard_copy === null ? null : moment(this.state.date_hard_copy).format('YYYY-MM-DD'),
            instructor : this.state?.instructor?.value || "null",
            estimation_cost: this.state.estimation_cost || "null",
            place_of_repair: this.state.place_of_repair,
            total_account_submitted: this.state.total_account_submitted,
            total_account_approved: this.state.total_account_approved,
            total_account_approved_for_cost: this.state.total_account_approved_for_cost,
            total_account_approved_with_comment: this.state.total_account_approved_with_comment,
            total_account_not_approved: this.state.total_account_not_approved,
            residual_scrap_value: this.state.residual_scrap_value,
            repairs: this.state.repairs && this.state.repairs.length > 0 ? this.state.repairs : [],
            files: this.state.files && this.state.files.length > 0 ? this.state.files : [],
        };

        Api.patch('/projects/'+this.props.match.params.id, params, false).then(resp => {
            this.setState({ loadingButton: false });
            this.showMessage(true, 'Project updated');
        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)) {

            } else {
                this.setState({ errors: err.errors, loadingButton: false });
                this.showMessage(false, 'Invalid format data');
            }
        });
    };

    handleAdd = (e) => {

        e.preventDefault();

        if (!validatorObj.allValid()) {

            this.setState({
                    errors: validatorObj.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
            }
        );

        let params = {
            id : this.state.under.value,
            name : this.state.under.label,
        };


        this.state.underwriter.push(params);

        this.setState({
                id: '',
                name: '',
            }
        );
    };

    handleDel(id) {
        let data = this.state.underwriter.filter((item) => item.id !== id);

        this.setState({
                underwriter: data
            }
        );

    };

    handleDelSelect(prop) {
        this.setState({

            [prop]: ''

        })

    };

    handleDelDate(prop) {
        this.setState({

            [prop]: null

        })

    };


    filterName = (id) => {
        let all = this.state.underwriters.filter((item) => item.id === id);
        return all[0].name;
    };

    handleGo  = (link) => {

        history.push(link);
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                decimalScale={2}
                isNumericString
            />
        );
    };

    renderReportOrApproval = (type_id) => {
        if (type_id === PROJECT_TYPE_IDS.report) { // Report
            return (
                <>
                    <div className="col-12">
                        <div className="group-title">
                            <h3>Report Estimate Cost of Repair</h3>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='table-wrapper detail table-exp'>
                            <div className='row align-items-center mb-3 mx-0'>
                                <div className='col-md-6'>
                                    <h2 className='title-wrap mb-0'> Repair </h2>
                                </div>
                                <div className='col-md-6 text-right'>
                                    <Button className='mr-2' variant='contained' color='secondary' onClick={this.handleNewRepair}><i className="fas fa-plus"></i>&nbsp; Add Repair</Button>
                                </div>
                            </div>
                            <div className='row head-form'>
                                <div className='col-12 col-xl-1'><strong>Action</strong></div>
                                <div className='col-12 col-xl-2 d-none d-xl-block' ><strong>Currency</strong></div>
                                <div className='col-12 col-xl-6 d-none d-xl-block' ><strong>Item (Remarks)</strong></div>
                                <div className='col-12 col-xl-3 d-none d-xl-block' ><strong>Amount / Value</strong></div>
                            </div>
                            {
                                this.state.repairs.length > 0 && this.state.repairs.map(function (item, idx) {
                                    return (
                                        <div className='row align-items-center' key={idx}>
                                            <div className='col-6 col-md-4 col-xl-1 text-center'>
                                                <button className='btn-icon' onClick={(e) => this.handleDelRepair(e, idx)}>
                                                    <i className='fas fa-trash-alt'> </i>
                                                </button>
                                            </div>
                                            <div className='col-6 col-md-4 col-xl-2'>
                                                <div className='form-group'>
                                                    <TextField 
                                                        variant='outlined'
                                                        select
                                                        id={'currency-'+idx}
                                                        name={'currency-'+idx}
                                                        label='Currency'
                                                        onChange={(e) => this.handleChangeRepair(e, 'currency', idx)}
                                                        value={item.currency}
                                                        fullWidth
                                                    >
                                                        <MenuItem value='IDR'>IDR</MenuItem>
                                                        <MenuItem value='USD'>USD</MenuItem>
                                                        <MenuItem value='SGD'>SGD</MenuItem>
                                                    </TextField>
                                                    {validatorRepair.message('currency-'+idx, item.currency, 'required')}
                                                    <div className='text-danger'>{this.state.errors['currency-'+idx]}</div>
                                                </div>
                                            </div>
                                            <div className='col-6 col-md-4 col-xl-6'>
                                                <div className='form-group'>
                                                    <TextField 
                                                        variant='outlined'
                                                        type='text'
                                                        id={'item-'+idx}
                                                        name={'item-'+idx}
                                                        label='Item (Remarks)'
                                                        onChange={(e) => this.handleChangeRepair(e, 'item', idx)}
                                                        value={item.item ? item.item : ''}
                                                        fullWidth
                                                    />
                                                    <div className='text-danger'>{this.state.errors['item-'+idx]}</div>
                                                </div>
                                            </div>
                                            <div className='col-6 col-md-4 col-xl-3'>
                                                <div className='form-group'>
                                                    <TextField
                                                        variant='outlined'
                                                        type='text'
                                                        id={'amount-'+idx}
                                                        name={'amount-'+idx}
                                                        label='Amount / Value'
                                                        value={item.amount ? item.amount : 0}
                                                        onChange={(e) => this.handleChangeRepair(e, 'amount', idx)}
                                                        InputProps={{ inputComponent: this.NumberFormatCustom }}
                                                        fullWidth
                                                    />
                                                    {validatorRepair.message('amount-'+idx, item.amount, 'required')}
                                                    <div className='text-danger'>{this.state.errors['amount-'+idx]}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }.bind(this))
                            }

                        </div>
                    </div>

                </>
            );
        } else if (type_id === PROJECT_TYPE_IDS.approval) { // Approval
            return (
                <>
                    <div className="col-12">
                        <div className="group-title">
                            <h3>Summary of Claim / Approval</h3>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Place of Repair / Shipyard Location</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='place_of_repair'
                                name="place_of_repair"
                                label="Place of Repair / Shipyard Location"
                                onChange={(e) => this.handleChange(e, 'place_of_repair')}
                                value={this.state.place_of_repair}
                                fullWidth
                            />
                            <div className='text-danger'>{this.state.errors.place_of_repair}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Estimate Cost of Repair in Report</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='estimation_cost'
                                name="estimation_cost"
                                label="Estimate Cost of Repair in Report"
                                onChange={(e) => this.handleChange(e, 'estimation_cost')}
                                value={this.state.estimation_cost}
                                // InputProps={{ inputComponent: this.NumberFormatCustom }}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.estimation_cost}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Total Account Submitted</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='total_account_submitted'
                                name="total_account_submitted"
                                label="Total Account Submitted"
                                onChange={(e) => this.handleChange(e, 'total_account_submitted')}
                                value={this.state.total_account_submitted}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.total_account_submitted}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Total Account Approved</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='total_account_approved'
                                name="total_account_approved"
                                label="Total Account Approved"
                                onChange={(e) => this.handleChange(e, 'total_account_approved')}
                                value={this.state.total_account_approved}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.total_account_approved}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Total Account Approved For Cost Only</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='total_account_approved_for_cost'
                                name="total_account_approved_for_cost"
                                label="Total Account Approved For Cost Only"
                                onChange={(e) => this.handleChange(e, 'total_account_approved_for_cost')}
                                value={this.state.total_account_approved_for_cost}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.total_account_approved_for_cost}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Total Account Approved With Comment</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='total_account_approved_with_comment'
                                name="total_account_approved_with_comment"
                                label="Total Account Approved With Comment"
                                onChange={(e) => this.handleChange(e, 'total_account_approved_with_comment')}
                                value={this.state.total_account_approved_with_comment}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.total_account_approved_with_comment}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Total Account Not Approved</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='total_account_not_approved'
                                name="total_account_not_approved"
                                label="Total Account Not Approved"
                                onChange={(e) => this.handleChange(e, 'total_account_not_approved')}
                                value={this.state.total_account_not_approved}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.total_account_not_approved}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Residual / Scrap Value</label>
                            <TextField 
                                variant="outlined"
                                type='text'
                                id='residual_scrap_value'
                                name="residual_scrap_value"
                                label="Residual / Scrap Value"
                                onChange={(e) => this.handleChange(e, 'residual_scrap_value')}
                                value={this.state.residual_scrap_value}
                                fullWidth
                                multiline
                            />
                            <div className='text-danger'>{this.state.errors.residual_scrap_value}</div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="col-12">
                        <div className="group-title">
                            <h3>Estimation Cost of Repair</h3>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Currency</label>
                            <TextField variant="outlined"
                                    select
                                    id="currency"
                                    name="currency"
                                    label="Currency"
                                    onChange={(e) => this.handleChange(e, 'currency')}
                                    value={this.state.currency}
                                    fullWidth
                            >
                                <MenuItem value="IDR">IDR</MenuItem>
                                <MenuItem value="USD">USD</MenuItem>
                                <MenuItem value="SGD">SGD</MenuItem>
                            </TextField>
                            <div className='text-danger'>{this.state.errors.currency}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Estimate Cost</label>
                            <TextField variant="outlined"
                                    type='text'
                                    id='estimation_cost'
                                    name="estimation_cost"
                                    label="Estimate Cost"
                                    onChange={(e) => this.handleChange(e, 'estimation_cost')}
                                    value={this.state.estimation_cost}
                                    // InputProps={{ inputComponent: this.NumberFormatCustom }}
                                    fullWidth
                            />
                            <div className='text-danger'>{this.state.errors.estimation_cost}</div>
                        </div>
                    </div>
                </>
            );
        }
    }

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Edit Project</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/projects" >Project</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-md-9 mt-3 pl-lg-5">
                    <div className="table-wrapper">
                        <form name="edit" id="editProject" className="row" noValidate>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Internal Data</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Case No.</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='case_number'
                                               name="case_number"
                                               label="Case No."
                                               onChange={(e) => this.handleChange(e, 'case_number')}
                                               value={this.state.case_number}
                                               fullWidth
                                    />
                                    {validator.message('case_number', this.state.case_number, 'required')}
                                    <div className='text-danger'>{this.state.errors.case_number}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Project No.</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='project_number'
                                               name="project_number"
                                               label="Project No."
                                               onChange={(e) => this.handleChange(e, 'project_number')}
                                               value={this.state.project_number}
                                               fullWidth
                                    />
                                    {validator.message('project_number', this.state.project_number, 'required')}
                                    <div className='text-danger'>{this.state.errors.project_number}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Ext Project No.</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='ext_project_number'
                                               name="ext_project_number"
                                               label="Ext Project No."
                                               onChange={(e) => this.handleChange(e, 'ext_project_number')}
                                               value={this.state.ext_project_number}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.ext_project_number}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Case Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="case_type"
                                               name="case_type"
                                               label="Case Type"
                                               onChange={(e) => this.handleChange(e, 'case_type')}
                                               value={this.state.case_type}
                                               fullWidth
                                    >
                                        <MenuItem value='H&M'>H&M</MenuItem>
                                        <MenuItem value='P&I'>P&I</MenuItem>
                                        <MenuItem value='Shipowner'>Shipowner</MenuItem>
                                        <MenuItem value='Cargo'>Cargo</MenuItem>
                                    </TextField>
                                    {validator.message('case_type', this.state.case_type, 'required')}
                                    <div className='text-danger'>{this.state.errors.case_type}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Project Type</label>
                                    <TextField 
                                        variant="outlined"
                                        select
                                        id="type_id"
                                        name="type_id"
                                        label="Project Type"
                                        onChange={(e) => this.handleType(e, 'type_id')}
                                        minLength={4}
                                        maxLength={50}
                                        value={this.state.type_id}
                                        fullWidth
                                    >
                                        {this.state.types.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                    </TextField>
                                    {validator.message('type_id', this.state.type_id, 'required')}
                                    <div className='text-danger'>{this.state.errors.type_id}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Project Detail Type</label>
                                    <TextField variant="outlined"
                                               select
                                               id="detail_type_id"
                                               name="detail_type_id"
                                               label="Project Detail Type"
                                               onChange={(e) => this.handleChange(e, 'detail_type_id')}
                                               minLength={4}
                                               maxLength={50}
                                               value={this.state.detail_type_id}
                                               fullWidth
                                    >
                                        {this.state.detailTypes.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('detail_type_id', this.state.detail_type_id, 'required')}
                                    <div className='text-danger'>{this.state.errors.detail_type_id}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Approval Category</label>
                                    <TextField 
                                        variant='outlined'
                                        select
                                        id='approval_category_id'
                                        name='approval_category_id'
                                        label='Approval Category'
                                        onChange={(e) => this.handleChange(e, 'approval_category_id')}
                                        value={this.state.approval_category_id}
                                        fullWidth
                                    >
                                        {this.state.approvalCategories.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.approval_category_id}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label>Instructor</label>
                                    <SelectPaginate
                                        value={this.state.instructor}
                                        loadOptions={this.loadOptionsInstructor}
                                        onChange={(e) => this.handleChangeClient(e,'instructor')}
                                        additional={{ page: 1 }}
                                        debounceTimeout={500}
                                    />
                                    {this.state.instructor &&
                                        <button
                                            type='button'
                                            onClick={() => this.handleDelSelect('instructor')}
                                            className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                        </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.instructor}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Vessel Particular</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Vessel Name</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='vessel_name'
                                               name="vessel_name"
                                               label="Vessel Name"
                                               onChange={(e) => this.handleChange(e, 'vessel_name')}
                                               value={this.state.vessel_name}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Vessel Type</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='vessel_type'
                                               name="vessel_type"
                                               label="Vessel Type"
                                               onChange={(e) => this.handleChange(e, 'vessel_type')}
                                               value={this.state.vessel_type}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_type}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Vessel GRT</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='vessel_grt'
                                               name="vessel_grt"
                                               label="Vessel GRT"
                                               onChange={(e) => this.handleChange(e, 'vessel_grt')}
                                               value={this.state.vessel_grt}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.vessel_grt}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Vessel YOB</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='vessel_yob'
                                               name="vessel_yob"
                                               label="Vessel YOB"
                                               onChange={(e) => this.handleChange(e, 'vessel_yob')}
                                               value={this.state.vessel_yob}
                                               fullWidth
                                    />
                                    {validator.message('vessel_yob', this.state.vessel_yob, 'numeric')}
                                    <div className='text-danger'>{this.state.errors.vessel_yob}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Clients</h3>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Underwriter</label>
                                    <Select
                                        options = {this.state.underwriters}
                                        onChange={(e) => this.handleChangeClient(e,'under')}
                                        value={this.state.under}
                                    />
                                    {validatorObj.message('under', this.state.under, 'required')}
                                    <div className='text-danger'>{this.state.errors.under}</div>
                                    <button
                                        onClick={this.handleAdd}
                                        className='btn-link'
                                    >
                                        <AddCircleIcon /> Add Underwriter
                                    </button>
                                    {
                                        this.state.underwriter.length > 0 &&
                                        (<div className='card-temp mt-3'>
                                                {
                                                    this.state.underwriter.map(function (objectKey, index) {
                                                        return <div className='list-temp' key={index}>
                                                            <button
                                                                type='button'
                                                                onClick={() => this.handleDel(objectKey.id)}
                                                                className='btn-row min'><i className='fas fa-times-circle'> </i>
                                                            </button>
                                                            <span>{objectKey.name}</span>
                                                        </div>
                                                    }.bind(this))
                                                }
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group position-relative'>
                                    <label>Broker</label>
                                    <Select options = {this.state.brokers}
                                            onChange={(e) => this.handleChangeClient(e,'broker')}
                                            value={this.state.broker}
                                    />
                                    {this.state.broker &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelSelect('broker')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.broker}</div>
                                </div>
                                <div className='form-group position-relative'>
                                    <label>Adjuster</label>
                                    <Select options = {this.state.adjusters}
                                            onChange={(e) => this.handleChangeClient(e,'adjuster')}
                                            value={this.state.adjuster}
                                    />
                                    {this.state.adjuster &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelSelect('adjuster')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.adjuster}</div>
                                </div>
                                <div className='form-group position-relative'>
                                    <label>Shipowner</label>
                                    <Select options = {this.state.shipowners}
                                            onChange={(e) => this.handleChangeClient(e,'shipowner')}
                                            value={this.state.shipowner}
                                    />
                                    {this.state.shipowner &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelSelect('shipowner')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.shipowner}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Survey Data Internal</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Surveyor</label>
                                    <Autocomplete
                                        multiple
                                        options={this.state.surveyors}
                                        getOptionLabel={(option) => option.name}
                                        value={this.state.surveyor}
                                        filterSelectedOptions
                                        onChange={this.handleSurvey}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="filterSelectedOptions"
                                                placeholder="choose"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Admin</label>
                                    <Autocomplete
                                        multiple
                                        options={this.state.adminslist}
                                        getOptionLabel={(option) => option.name}
                                        value={this.state.admins}
                                        filterSelectedOptions
                                        onChange={this.handleAdmin}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="filterSelectedOptions"
                                                placeholder="choose"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Final Reviewer</label>
                                    <Autocomplete
                                        multiple
                                        options={this.state.finalReviewers}
                                        getOptionLabel={(option) => option.name}
                                        value={this.state.finalReviewer}
                                        filterSelectedOptions
                                        onChange={this.handleReview}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="filterSelectedOptions"
                                                placeholder="choose"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Accident</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Casualties</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='casualty'
                                               name="casualty"
                                               label="Casualties"
                                               onChange={(e) => this.handleChange(e, 'casualty')}
                                               value={this.state.casualty}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.casualty}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Place of Accident</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='place_of_accident'
                                               name="place_of_accident"
                                               label="Place of Accident"
                                               onChange={(e) => this.handleChange(e, 'place_of_accident')}
                                               value={this.state.place_of_accident}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.place_of_accident}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group position-relative">
                                    <label>Date of Accident</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Date of Accident"
                                            value={this.state.date_of_accident}
                                            onChange={(date) => this.handleDate(date, 'date_of_accident')}
                                            format={'dddd, MMMM DD YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {this.state.date_of_accident &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelDate('date_of_accident')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.date_of_accident}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Instruction Date</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Instruction Date"
                                            value={this.state.instruction_date}
                                            onChange={(date) => this.handleDate(date, 'instruction_date')}
                                            format={'dddd, MMMM DD YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {this.state.instruction_date &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelDate('instruction_date')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.instruction_date}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Instruction By</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='instruction_by'
                                               name="instruction_by"
                                               label="Instruction By"
                                               onChange={(e) => this.handleChange(e, 'instruction_by')}
                                               value={this.state.instruction_by}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.instruction_by}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>PIC Case</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='pic_case'
                                               name="pic_case"
                                               label="PIC Case"
                                               onChange={(e) => this.handleChange(e, 'pic_case')}
                                               value={this.state.pic_case}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.pic_case}</div>
                                </div>
                            </div>
                            {
                                this.renderReportOrApproval(this.state.type_id)
                            }
                            <div className="col-12">
                                <div className="group-title">
                                    <h3>Filling Admin</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <FormControl component="fieldset" className="col ">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.hard_copy_invoice === 1} onChange={this.handleChecked('hard_copy_invoice')} value="hard_copy_invoice" />}
                                                label="Hard copy invoice sudah di print"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.hard_copy_report === 1} onChange={this.handleChecked('hard_copy_report')} value="hard_copy_report" />}
                                                label="Hard copy report sudah di print"
                                            />

                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.soft_copy_data === 1} onChange={this.handleChecked('soft_copy_data')} value="soft_copy_data" />}
                                                label="Softcopy data sudah tersedia"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Hard Copy Dikirimkan Pada Tanggal</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Tanggal pengiriman"
                                            value={this.state.date_hard_copy}
                                            onChange={(date) => this.handleDate(date, 'date_hard_copy')}
                                            format={'dddd, MMMM DD YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {this.state.date_hard_copy &&
                                    <button
                                        type='button'
                                        onClick={() => this.handleDelDate('date_hard_copy')}
                                        className='btn btn-delete-select'><i className='fas fa-times-circle'> </i>
                                    </button>
                                    }
                                    <div className='text-danger'>{this.state.errors.date_hard_copy}</div>
                                </div>
                                <div className="form-group">
                                    <label>Upload Files</label>
                                    {
                                        this.state.files.length === 5 ? (
                                            <div className="d-flex">
                                                Maksimum upload &nbsp;<b>( 5 )</b>&nbsp; file
                                            </div>
                                        ) : (
                                            <div className="d-flex">
                                                <input
                                                    className="flex-grow-1"
                                                    id="files"
                                                    name="files"
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf,.txt,.csv"
                                                    onChange={this.handleSelectFile}
                                                />
                                                <Button
                                                    className="ml-2 p-2"
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={this.handleUploadFile}
                                                >
                                                    <i className="fas fa-upload"></i>&nbsp; Upload
                                                </Button>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.files.length > 0 &&
                                        (
                                            <div className='card-temp mt-3'>
                                                {
                                                    this.state.files.map(function (item) {
                                                        return (
                                                            <div className='list-temp d-flex justify-content-between align-items-center border-0' key={item?.id}>
                                                                <a 
                                                                    href={process.env.REACT_APP_API_STORAGE_PATH + item.file_name}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ width: '100%', color: 'black' }}
                                                                >
                                                                    <FileIcon className="mr-2" />
                                                                    <span className="w-100">{item?.file_name}</span>
                                                                </a>

                                                                <button type="button" className="btn-icon m-auto ml-2" onClick={() => this.handleSelectDeleteFile(item?.id)}>
                                                                    <i className="fas fa-trash-alt"> </i>
                                                                </button>
                                                            </div>
                                                        );
                                                    }.bind(this))
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="col-12 text-center mt-4">
                                <Button
                                    variant="contained"
                                    className="mr-3"
                                    onClick={() => this.handleGo('/projects/detail/'+this.props.match.params.id)}
                                >
                                    Back to Detail
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=""
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && true }
                                >
                                    Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showConfirmDelete}
                    onClose={this.handleCloseConfirmDelete}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Konfirmasi</DialogTitle>
                    <DialogContent>
                        <p>Anda yakin akan menghapus file ini ?</p>
                    </DialogContent>
                    <DialogActions className="p-4">
                        <Button
                            variant="contained"
                            className="mr-3"
                            onClick={this.handleCloseConfirmDelete}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="danger"
                            onClick={this.handleDeleteFile}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default ProjectsEdit;
