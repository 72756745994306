import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Api from "../../../libraries/api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from 'moment/moment';
import _ from 'lodash';

class ContentDashboardDetail extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,

            data: {},
            month: '',
            year: '',

            reportIssued: [],
            reportApproval: [],
            instructionReport: [],
            instructionApproval: [],
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Statement Detail';
        this.__fetchData();
    }

    filter = month => {
        let m = '';
        switch(month) {
            case "1":
                m = "Januari";
                break;
            case "2":
                m = "Februari";
                break;
            case "3":
                m = "Maret";
                break;
            case "4":
                m = "April";
                break;
            case "5":
                m = "Mei";
                break;
            case "6":
                m = "Juni";
                break;
            case "7":
                m = "Juli";
                break;
            case "8":
                m = "Agustus";
                break;
            case "9":
                m = "September";
                break;
            case "10":
                m = "Oktober";
                break;
            case "11":
                m = "November";
                break;
            case "12":
                m = "Desember";
                break;
            default:
                m = "";
        }
        return m;
    };

    __fetchData = () => {
        this.setState({
            loading: true,
        });
        const month = this.props.match.params.month;
        const year = this.props.match.params.year;
        Api.get('/detail/statement?year='+year+'&month='+month).then(resp => {

            if (resp.data) {
                this.setState({
                    data: resp.data,
                    year: year,
                    month: this.filter(month),
                    reportIssued: resp.data.report_issued,
                    reportApproval: resp.data.report_approval,
                    instructionReport: resp.data.instruction_report,
                    instructionApproval: resp.data.instruction_approval,
                    loading: false
                })

            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false
            })
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Operation Statement - {this.state.month} {this.state.year}</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Operation Statement</li>                        </ol>
                    </nav>
                    <h3 className='title-wrap mt-3 font-weight-bold'>New Instruction Report</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>INSTRUCTION DATE</TableCell>
                                    <TableCell className='text-center'>TYPE OF FILE</TableCell>
                                    <TableCell className='text-center'>CASE NO</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.instructionReport &&
                            <TableBody>
                                {this.state.instructionReport.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>{item.instruction_date && moment(item.instruction_date,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>{item.type_of_file}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.project_type === 'Report' ? item.client_underwriter : item.client_adjuster}</TableCell>

                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap mt-3 font-weight-bold'>New Instruction Approval</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>INSTRUCTION DATE</TableCell>
                                    <TableCell className='text-center'>TYPE OF FILE</TableCell>
                                    <TableCell className='text-center'>CASE NO</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.instructionApproval &&
                            <TableBody>
                                {this.state.instructionApproval.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>{item.instruction_date && moment(item.instruction_date,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>{item.type_of_file}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.project_type === 'Report' ? item.client_underwriter : item.client_adjuster}</TableCell>

                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap font-weight-bold'>Report Issued</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TYPE OF FILE</TableCell>
                                    <TableCell className='text-center'>CASE NO</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.reportIssued &&
                            <TableBody>
                                {this.state.reportIssued.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>{item.date_status && moment(item.date_status,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>{item.type_of_file}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.project_type === 'Report' ? item.client_underwriter : item.client_adjuster}</TableCell>

                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap mt-3 font-weight-bold'>Approval Issued</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TYPE OF FILE</TableCell>
                                    <TableCell className='text-center'>CASE NO</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.reportApproval &&
                            <TableBody>
                                {this.state.reportApproval.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>{item.date_status && moment(item.date_status,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>{item.type_of_file}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.project_type === 'Report' ? item.client_underwriter : item.client_adjuster}</TableCell>

                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                </div>

            </div>

        )

    }
}

export default ContentDashboardDetail;