import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Api from "../../../libraries/api";
import Collapse from '@material-ui/core/Collapse';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import moment from "moment";
import {history} from "../../../shared/configure-store";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


require('dotenv').config();

class IssuedMonth extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,

            open: '',

            case_number: '',
            project_number: '',
            ext_project_number: '',
            vessel_name: '',
            casualty: '',
            client_id: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            projectStatus: '',
            surveyorId: '',

            clients: [],
            project_status: [],
            surveyors: [],
            report_status: [],
            approval_status: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Issued Invoice This Month';

        Api.get('/clients?limit=0').then(resp => {

            if (resp.data) {

                this.setState({
                    clients: resp.data,
                })

            }

        }).catch(err => {
            console.log(err);
        });


        this.__fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    filterName = (id) => {
        let all = this.state.clients.filter((item) => item.id === id);
        if(all.length > 0)
            return all[0].name;
    };

    __fetchData = update => {
        this._isMounted = true;

        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let sort = this.state.orderBy ? '&orderBy=' + this.state.orderBy + '&sortedBy=' + this.state.sortedBy : '';
        let search = this.state.searchBy ? '&search=' + this.state.searchBy : '';
        let route = '';

        if(search) {
            route = '/invoices?dashboard=InvoiceIssuedMonth&limit='+this.state.perPage+sort+search;
        }else {
            route = '/invoices?dashboard=InvoiceIssuedMonth&limit='+this.state.perPage+'&page='+page+sort+search;
        }


        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {

                    this.setState({
                        rows: resp.data,
                        perPage: resp.meta.pagination.per_page,
                        currentPage: resp.meta.pagination.current_page,
                        currentPageTable: resp.meta.pagination.current_page - 1,
                        total: resp.meta.pagination.total,
                        loading: false,
                        loadingButton: false,
                        showDialogFilter: false,
                        isEdit: resp.meta.custom.isEdit,
                        isDelete: resp.meta.custom.isDelete,
                        isCreate: resp.meta.custom.isCreate,
                    })

                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            Api.delete('/projects/'+this.state.idDelete, '', false).then(resp => {

                this.setState({
                    showDialog: false,
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                    showDialog: false
                    }
                );

                this.showMessage(true, 'Project successfully deleted');
                this.__fetchData(false);
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    setOpen = (index) => {
        if(this.state.open === index) {
            this.setState({
                open : false,
            });
        }else {
            this.setState({
                open : index,
            });
        }

    };

    filterStatus = (name) => {
        let text = '';
        console.log(name)
        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Issued Invoice This Month</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Finance</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <TableContainer>
                            <Table className="table-list" size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell> </TableCell>
                                        <TableCell className="clickable" >Case No
                                            {/*{this.state.orderBy === 'caseNumber' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}*/}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('invoiceNumber')} >Invoice Number
                                            {this.state.orderBy === 'invoiceNumber' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('dateIssued')} >Date Issued
                                            {this.state.orderBy === 'dateIssued' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>

                                        <TableCell className="clickable" onClick={() => this.handleSort('clientId')} >Company
                                            {this.state.orderBy === 'clientId' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>

                                        <TableCell className="clickable" onClick={() => this.handleSort('sharePercentage')} >Shared
                                            {this.state.orderBy === 'sharePercentage' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={6} style={{ textAlign: "center" }}>No invoice</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map((row,idx) => (
                                                <React.Fragment key={idx}>
                                                    <TableRow
                                                        key={row.id}
                                                        selected={row.selected}>
                                                        <TableCell>
                                                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen('open-'+idx)}>
                                                                {this.state.open === 'open-'+idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={"/projects/finance/" + row.project.id} target="_blank">
                                                                {row.project === null ? '' : row.project.case_number}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{row.invoiceNumber === null ? '' : row.invoiceNumber}</TableCell>
                                                        <TableCell>{row.dateIssued === null ? '' : moment(row.dateIssued).format('DD MMM YYYY')}</TableCell>
                                                        <TableCell>{this.filterName(row.clientId)}</TableCell>
                                                        <TableCell>{row.sharePercentage === null ? '' : row.sharePercentage+'%'}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}  colSpan={6}>
                                                            <Collapse in={this.state.open === 'open-'+idx} timeout="auto" >
                                                                <div className='table-card text-left'>
                                                                    <p><strong>Currency: </strong>{row.currency}</p>
                                                                    <p><strong>Amount: </strong>{row.totalAmount === null ? '' : row.totalAmount}</p>
                                                                    <p><strong>Status: </strong>
                                                                        {row.paymentDate === null ?
                                                                            <span className='label-status label-yellow d-inline mr-2'>Pending</span> :
                                                                            <span className='label-status label-green d-inline mr-2'>Paid</span>
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>

                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <ToastContainer autoClose={false} />

            </div>
        )
    }
}

export default IssuedMonth;
