import React,{Component} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment'
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker , MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment/build/index";
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import _ from "lodash";

const localizer = momentLocalizer(moment); // or globalizeLocalizer
const validatorModal = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});


class Events extends Component {
    constructor(props) {

        super(props);

        this.state = {
            showDialog: false,
            loadingButton: false,
            errors: {},

            users: [],

            date: null,
            start: null,
            end: null,
            title: '',
            desc: '',
            user_id: '',
            events: [
                {
                    title: 'All Day Event very long title',
                    allDay: true,
                    start: new Date(2015, 3, 0),
                    end: new Date(2015, 3, 1)
                },
                {
                    'title': 'Long Event',
                    'start': new Date(2015, 3, 7),
                    'end': new Date(2015, 3, 10)
                },

                {
                    'title': 'DTS STARTS',
                    'start': new Date(2016, 2, 13, 0, 0, 0),
                    'end': new Date(2016, 2, 20, 0, 0, 0)
                },

                {
                    'title': 'DTS ENDS',
                    'start': new Date(2016, 10, 6, 0, 0, 0),
                    'end': new Date(2016, 10, 13, 0, 0, 0)
                },

                {
                    'title': 'Some Event',
                    'start': new Date(2015, 3, 9, 0, 0, 0),
                    'end': new Date(2015, 3, 9, 0, 0, 0)
                },
                {
                    'title': 'Conference',
                    'start': new Date(2015, 3, 11),
                    'end': new Date(2015, 3, 13),
                    'desc': 'Big conference for important people'
                },
                {
                    'title': 'Meeting',
                    'start': new Date(2015, 3, 12, 10, 30, 0, 0),
                    'end': new Date(2015, 3, 12, 12, 30, 0, 0),
                    desc: 'Pre-meeting meeting, to prepare for the meeting'
                },
                {
                    'title': 'Lunch',
                    'start': new Date(2015, 3, 12, 12, 0, 0, 0),
                    'end': new Date(2015, 3, 12, 13, 0, 0, 0),
                    desc: 'Power lunch'
                },
                {
                    'title': 'Meeting',
                    'start': new Date(2015, 3, 12, 14, 0, 0, 0),
                    'end': new Date(2015, 3, 12, 15, 0, 0, 0)
                },
                {
                    'title': 'Happy Hour',
                    'start': new Date(2015, 3, 12, 17, 0, 0, 0),
                    'end': new Date(2015, 3, 12, 17, 30, 0, 0),
                    desc: 'Most important meal of the day'
                },
                {
                    'title': 'Dinner',
                    'start': new Date(2015, 3, 12, 20, 0, 0, 0),
                    'end': new Date(2015, 3, 12, 21, 0, 0, 0)
                },
                {
                    'title': 'Birthday Party',
                    'start': new Date(2015, 3, 13, 7, 0, 0),
                    'end': new Date(2015, 3, 13, 10, 30, 0)
                },
                {
                    'title': 'Birthday Party 2',
                    'start': new Date(2015, 3, 13, 7, 0, 0),
                    'end': new Date(2015, 3, 13, 10, 30, 0)
                },
                {
                    'title': 'Birthday Party 3',
                    'start': new Date(2015, 3, 13, 7, 0, 0),
                    'end': new Date(2015, 3, 13, 10, 30, 0)
                },
                {
                    'title': 'Late Night Event',
                    'start': new Date(2015, 3, 17, 19, 30, 0),
                    'end': new Date(2015, 3, 18, 2, 0, 0)
                },
                {
                    'title': 'Multi-day Event',
                    'start': new Date(2015, 3, 20, 19, 30, 0),
                    'end': new Date(2015, 3, 22, 2, 0, 0)
                }
            ]
            ,
        };

    }

    componentDidMount() {

        Api.get('/users').then(resp => {

            if (resp.data) {

                this.setState({
                    users: resp.data,
                })

            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/events').then(resp => {

            let temp = [];
            if (resp.data.length > 0) {
                _.forEach(resp.data, function(v){

                    v.start = new Date(v.start);
                    v.end = new Date(v.end);
                    temp.push(v)
                });

                this.setState({
                    events: temp,
                })

            }

        }).catch(err => {
            console.log(err);
        });
    }


    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validatorModal.allValid()) {

            this.setState({
                    errors: validatorModal.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            start: moment(this.state.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(this.state.end).format('YYYY-MM-DD HH:mm:ss'),
            user_id: this.state.user_id,
            title: this.state.title,
            desc: this.state.desc,
        };

        Api.post('/events', params, false).then(resp => {

            console.log(resp);

            this.setState({
                    loadingButton: false,
                    showDialog :false,
                }
            );

            this.showMessage(true, 'Event added');

        }).catch(err => {
            console.log(err);

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleOpen = () => {
        this.setState({
            showDialog :true,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        return (

            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Events</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Events</li>
                        </ol>
                    </nav>

                    <div className="table-wrapper detail mb-3">
                        <div className="col-12 pr-0 text-right mb-3">
                            <Button
                                variant="contained"
                                color="secondary"
                                className="mb-3 mb-md-0"
                                onClick={() => this.handleOpen()}
                            >
                                Create Event
                            </Button>
                        </div>
                    <Calendar
                        localizer={localizer}
                        events={this.state.events}
                        step={60}
                        defaultDate={new Date()}
                    />
                    </div>
                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create Event</DialogTitle>
                    <DialogContent>
                        <form name="invoice" id="newInvoice" className="row" noValidate>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>User</label>
                                    <TextField variant="outlined"
                                               select
                                               id="type_id"
                                               name="type_id"
                                               label="User"
                                               onChange={(e) => this.handleChange(e, 'user_id')}
                                               minLength={4}
                                               maxLength={50}
                                               value={this.state.user_id}
                                               fullWidth
                                    >
                                        {this.state.users.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validatorModal.message('user_id', this.state.user_id, 'required')}
                                    <div className='text-danger'>{this.state.errors.user_id}</div>
                                </div>
                            </div>
                            <div className="col-md-6"> </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Start Date Time</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            label="Start Date Time"
                                            value={this.state.start}
                                            onChange={(date) => this.handleDate(date, 'start')}
                                            format={'DD MMM YYYY, HH:mm'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {validatorModal.message('start', this.state.start, 'required')}
                                    <div className='text-danger'>{this.state.errors.start}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>End Date Time</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            label="end Date Time"
                                            value={this.state.end}
                                            onChange={(date) => this.handleDate(date, 'end')}
                                            format={'DD MMM YYYY, HH:mm'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {validatorModal.message('end', this.state.end, 'required')}
                                    <div className='text-danger'>{this.state.errors.end}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='title'
                                               name="title"
                                               label="Title"
                                               onChange={(e) => this.handleChange(e, 'title')}
                                               value={this.state.title}
                                               fullWidth
                                    />
                                    {validatorModal.message('title', this.state.title, 'required')}
                                    <div className='text-danger'>{this.state.errors.title}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Detail</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='desc'
                                               name="desc"
                                               label="Detail"
                                               onChange={(e) => this.handleChange(e, 'desc')}
                                               value={this.state.desc}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.desc}</div>
                                </div>
                            </div>


                        </form>

                    </DialogContent>
                    <DialogActions className="justify-content-center pb-3">
                        <Button
                            variant="contained"
                            className="mr-3"
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className=""
                            onClick={this.handleSubmit}
                            disabled={this.state.loadingButton}
                        >
                            Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                        </Button>
                    </DialogActions>
                </Dialog>
                <ToastContainer autoClose={3000} />
            </div>

        )

    }
}

export default Events;