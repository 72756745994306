import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import img from '../../../images/home.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import iconReport from '../../../images/icon-3.png';
import iconApproval from '../../../images/icon-4.png';
import AdminIconWhite from "../../../images/admin-icon-white.png";
import ScheduleIconWhite from "../../../images/schedule-icon-white.png";
import ShipIconWhite from "../../../images/ship-icon-white.png";
import WarningIconWhite from "../../../images/warning-icon-white.png";
import QuestionIconWhite from "../../../images/question-icon-white.png";
import ChecklistIconWhite from "../../../images/checklist-icon-white.png";
import CheckListIconWhite2 from '../../../images/check-list-white.png';
import Skeleton from '@material-ui/lab/Skeleton';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Api from "../../../libraries/api";
import { PROJECT_TYPE_IDS } from '../../../constants/global';


class ContentDashboard extends Component {
    constructor(props) {

        super(props);
        this.state = {
            case_status: [],
            survey_status: [],

            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            loading6: false,
            loading7: false,
            loading8: false,
            loading9: false,
            loading10: false,
            loading11: false,
            loading12: false,
            loadingSurveyStatus: false,
            loadingCaseStatus: false,

            data1: 0,
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            data6: 0,
            data7: 0,
            data8: 0,
            data9: 0,
            data10: 0,
            data11: 0,
            data12a: 0,
            data12b: 0,
            dataSurveyStatus: null,
            dataCaseStatus: null,
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Dashboard';

        this.setState({
            loading1: true,
            loading2: true,
            loading3: true,
            loading4: true,
            loading5: true,
            loading12: true,
        });

        Api.get('/survey/status?limit=0').then(resp => {
            if (resp.data) {
                let survey_status = [];
                if (resp.data.length > 0) {
                    _.forEach(resp.data, function(item) {
                        if (item?.name !== 'Survey Done') { // Add every survey status except "Survey Done"
                            survey_status.push(item);
                        }
                    });
                }
                this.setState({ survey_status });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/cases-status?limit=0').then(resp => {
            if (resp.data) {
                let case_status = [];
                if (resp.data.length > 0) {
                    _.forEach(resp.data, function(item) {
                        case_status.push(item);
                    });
                }
                this.setState({ case_status });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/dashboards/pending-issued-invoice').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data1: resp.data[0].total, loading1: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading1: false });
        });

        Api.get('/dashboards/pending-payment-invoice').then(resp => {
            if (resp.data) {
                this.setState({ data2: resp.data[0].total, loading2: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading2: false });
        });

        Api.get('/dashboards/invoice-issued-week').then(resp => {
            if (resp.data) {
                this.setState({ data3: resp.data[0].total, loading3: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading3: false });
        });

        Api.get('/dashboards/invoice-issued-month').then(resp => {
            if (resp.data) {
                this.setState({ data4: resp.data[0].total, loading4: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading4: false });
        });

        Api.get('/dashboards/complete-payment').then(resp => {
            if (resp.data) {
                this.setState({ data5: resp.data[0].total, loading5: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading5: false });
        });

        Api.get('/dashboards/case-this-week').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data6: resp.data[0].total, loading6: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading6: false });
        });

        Api.get('/dashboards/case-this-month').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data7: resp.data[0].total, loading7: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading7: false });
        });

        Api.get('/dashboards/issue-report-week').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data8: resp.data[0].total, loading8: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading8: false });
        });

        Api.get('/dashboards/issue-report-month').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data9: resp.data[0].total, loading9: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading9: false });
        });

        Api.get('/dashboards/count-report').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data10: resp.data[0].total, loading10: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading10: false });
        });

        Api.get('/dashboards/count-approval').then(resp => {
            if (resp.data.length > 0) {
                this.setState({ data11: resp.data[0].total, loading11: false });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading11: false });
        });

        Api.get('/dashboards/follow-up').then(resp => {
            if (resp.data) {
                this.setState({
                    data12a: resp.data.report,
                    data12b: resp.data.approval,
                    loading12: false
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading12: false });
        });

        Api.get('/dashboards/count-survey-status').then(resp => {
            if (resp.data.length > 0) {
                let dataSurveyStatus = {};
                _.forEach(resp.data, function(item) {
                    dataSurveyStatus[item.name] = item;
                });

                this.setState({ 
                    dataSurveyStatus,
                    loadingSurveyStatus: false,
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loadingSurveyStatus: false });
        });

        Api.get('/dashboards/count-case-status').then(resp => {
            if (resp.data.length > 0) {
                let dataCaseStatus = {};
                _.forEach(resp.data, function(item) {
                    dataCaseStatus[item.name] = item;
                });

                this.setState({ 
                    dataCaseStatus,
                    loadingSurveyStatus: false,
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loadingCaseStatus: false });
        });
    }

    renderFloatingButtonMyTask = () => {
        let redirectUrl = '/my';
        
        return (
            <Link to={redirectUrl} target='_blank'>
                <Tooltip title="Pergi ke My Task" arrow>
                    <Fab className="fab-my-task" color="secondary" >
                        <img src={CheckListIconWhite2} alt='todo' width={35} />
                    </Fab>
                </Tooltip>
            </Link>
        );
    };

    render() {
        return (
            <div className="row main-content">
                
                {this.renderFloatingButtonMyTask()}

                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Dashboard</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Dashboard</li>                        </ol>
                    </nav>

                    <div className="card-home mb-3">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-7 ">
                                <blockquote>Welcome Back!</blockquote>
                                <p>Selalu langsung cek "My Task" masing - masing, update setiap project yang ada. Buat apa yang akan dikerjakan di event setiap pagi sebelum bekerja.</p>
                            </div>
                            <div className="col-md-5 col-lg-4">
                                <img src={img} alt='welcome' className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className='col-md-3'>
                            <h3 className='title-wrap'>PROJECT SUMMARY</h3>
                        </div>
                        <div className='col-md-3'>
                            <h3 className='title-wrap'>PROJECT STATUS</h3>
                        </div>
                        <div className='col-md-3'>
                            <h3 className='title-wrap'>SURVEY STATUS</h3>
                        </div>
                        <div className='col-md-3'>
                            <h3 className='title-wrap'>CASE STATUS</h3>
                        </div>
                    </div>
                    <div className="row">

                        {/* Project Summary */}
                        <div className="col-md-3">
                            <Link to="/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=onp&title=Report On Progress" target='_blank'>
                                <div className="card-info green">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap square">
                                                <img src={iconReport} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Report On Progress</label>
                                            {this.state.loading10 ? <Skeleton variant="text" /> : <p className="number">{this.state.data10}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=onp&title=Approval On Progress" target='_blank'>
                                <div className="card-info green">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap square">
                                                <img src={iconApproval} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Approval On Progress</label>
                                            {this.state.loading11 ? <Skeleton variant="text" /> : <p className="number">{this.state.data11}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/* Project Status */}
                        <div className="col-md-3">
                            <Link to="/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=fol&title=Follow Up Approval" target='_blank'>
                                <div className="card-info yellow">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap d-flex justify-content-center align-items-center">
                                                <img src={iconApproval} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Follow Up Approval</label>
                                            {this.state.loading12 ? <Skeleton variant="text" /> : <p className="number">{this.state.data12b}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/* Survey Status */}
                        <div className="col-md-3">
                            {/* {this.state.survey_status.map((item) => (
                                <Link key={item?.id} to={`/projects-filter?survey=${item?.id}`} target='_blank'>
                                    <div className="card-info yellow">
                                        <div className="row align-items-center">
                                            <div className="col-3 pr-0">
                                                <div className="wrap d-flex justify-content-center align-items-center">
                                                    <img src={DASHBOARD_PROJECT_SURVEY_STATUS_ICONS?.[item?.name] || ''} alt='dashboard' className="img-icon" />
                                                </div>
                                            </div>
                                            <div className="col-8 col-md-10 col-xl-8">
                                                <label className="label-grey">{item?.name}</label>
                                                {this.state.loadingSurveyStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataSurveyStatus?.[item.name]?.total || 0}</p>}
                                            </div>
                                            <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                        </div>
                                    </div>
                                </Link>          
                            ))} */}

                            <Link to={`/projects-filter?survey=${this.state.dataSurveyStatus?.['Waiting for PIC']?.survey_status_id}&typeId=${PROJECT_TYPE_IDS.report}&title=Waiting for PIC`} target='_blank'>
                                <div className="card-info yellow">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap p-1 d-flex justify-content-center align-items-center">
                                                <img src={AdminIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Waiting For PIC </label>
                                            {this.state.loadingSurveyStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataSurveyStatus?.['Waiting for PIC']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?survey=${this.state.dataSurveyStatus?.['Waiting for Survey Arrangement']?.survey_status_id}&typeId=${PROJECT_TYPE_IDS.report}&title=Waiting for Survey Arrangement`} target='_blank'>
                                <div className="card-info yellow">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap d-flex justify-content-center align-items-center">
                                                <img src={ScheduleIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Waiting For Survey Arrangement</label>
                                            {this.state.loadingSurveyStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataSurveyStatus?.['Waiting for Survey Arrangement']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?survey=${this.state.dataSurveyStatus?.['Waiting for Follow Up Survey']?.survey_status_id}&typeId=${PROJECT_TYPE_IDS.report}&title=Waiting for Follow Up Survey`} target='_blank'>
                                <div className="card-info yellow">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap d-flex justify-content-center align-items-center">
                                                <img src={iconReport} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Waiting For Follow Up Survey</label>
                                            {this.state.loadingSurveyStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataSurveyStatus?.['Waiting for Follow Up Survey']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?survey=${this.state.dataSurveyStatus?.['Waiting for Documents']?.survey_status_id}&typeId=${PROJECT_TYPE_IDS.report}&title=Waiting for Documents`} target='_blank'>
                                <div className="card-info yellow">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap d-flex justify-content-center align-items-center">
                                                <img src={iconReport} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Waiting For Documents</label>
                                            {this.state.loadingSurveyStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataSurveyStatus?.['Waiting for Documents']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/* Case Status */}
                        <div className="col-md-3">
                            {/* {this.state.case_status.map((item) => (
                                <Link key={item?.id} to={`/projects-filter?case_status_id=${item?.id}`} target='_blank'>
                                    <div className="card-info blue">
                                        <div className="row align-items-center">
                                            <div className="col-3 pr-0">
                                                <div className="wrap d-flex justify-content-center align-items-center">
                                                    <img src={iconApproval} alt='dashboard' className="img-icon" />
                                                </div>
                                            </div>
                                            <div className="col-8 col-md-10 col-xl-8">
                                                <label className="label-grey">{item?.name}</label>
                                                {this.state.loadingCaseStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataCaseStatus?.[item.name]?.total || 0}</p>}
                                            </div>
                                            <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                        </div>
                                    </div>
                                </Link>          
                            ))} */}

                            <Link to={`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'Waiting for supporting data')?.id || null}&title=Waiting for Supporting Data`} target='_blank'>
                                <div className="card-info blue">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap p-3 d-flex justify-content-center align-items-center">
                                                <img src={ChecklistIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Waiting for Supporting Data </label>
                                            {this.state.loadingCaseStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataCaseStatus?.['Waiting for supporting data']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'URGENT')?.id || null}&title=URGENT`} target='_blank'>
                                <div className="card-info blue">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap p-2 d-flex justify-content-center align-items-center">
                                                <img src={WarningIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">URGENT</label>
                                            {this.state.loadingCaseStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataCaseStatus?.['URGENT']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'Vessel being salvage')?.id || null}&title=Vessel Being Salvaged`} target='_blank'>
                                <div className="card-info blue">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap p-2 d-flex justify-content-center align-items-center">
                                                <img src={ShipIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Vessel being Salvaged</label>
                                            {this.state.loadingCaseStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataCaseStatus?.['Vessel being salvage']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'Vessel being repair')?.id || null}&title=Vessel Being Repaired`} target='_blank'>
                                <div className="card-info blue">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap p-2 d-flex justify-content-center align-items-center">
                                                <img src={ShipIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Vessel being Repaired</label>
                                            {this.state.loadingCaseStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataCaseStatus?.['Vessel being repair']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'Inquiry')?.id || null}&title=Inquiry`} target='_blank'>
                                <div className="card-info blue">
                                    <div className="row align-items-center">
                                        <div className="col-3 pr-0">
                                            <div className="wrap p-3 d-flex justify-content-center align-items-center">
                                                <img src={QuestionIconWhite} alt='dashboard' className="img-icon" />
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-xl-8">
                                            <label className="label-grey">Inquiry ?</label>
                                            {this.state.loadingCaseStatus ? <Skeleton variant="text" /> : <p className="number">{this.state.dataCaseStatus?.['Inquiry']?.total || 0}</p>}
                                        </div>
                                        <div className="col-1 pl-0"><ChevronRightIcon /></div>
                                    </div>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContentDashboard;