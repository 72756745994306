import React,{ Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../images/logo-small.png';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import WarningIconBlack from "../../../images/warning-icon-black.png";
import QuestionIconBlack from "../../../images/question-icon-black.png";
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";
import moment from "moment/moment";
import Api from "../../../libraries/api";
import _ from "lodash";
import AuthHelper from "../../../libraries/auth-helper";
import user from "../../../images/user.png";
import home from "../../../images/dashboard.png";
import approval from "../../../images/exam.png";
import report from "../../../images/ship.png";
import client from "../../../images/network.png";
import finance from "../../../images/growth.png";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PROJECT_TYPE_IDS } from '../../../constants/global';

class Navbar extends Component {

    constructor(props) {

        super(props);
        this.state = {
            photo: user,
            userName: '',
            permissions: [],
            case_status: [],
        };

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }
    componentDidMount() {

        Api.get('/current').then(resp => {

            if(resp.data) {
                let data = resp.data;
                let perms = [];

                if(data.roles.data[0]) {
                    _.forEach(data.roles.data[0].permissions.data, function(v, i){
                        perms.push(v.name)
                    });
                }
                data.permissions = perms;

                AuthHelper.setProfile(data);

                this.props.setProfile(data);

                this.setState({
                    loading: false,
                    photo: resp.data.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+resp.data.avatar : user,
                    userName: resp.data.name,
                    userRole: resp.data.roles ? resp.data.roles.data[0].display_name : '',
                    permissions: resp.data.roles ? resp.data.roles.data[0].permissions.data : [],
                })
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/cases-status?limit=0').then(resp => {
            if (resp.data && resp.data.length > 0) {
                let case_status = [];
                _.forEach(resp.data, (item) =>  case_status.push(item));
                this.setState({ case_status });
            }
        });

    }

    handleToggleSidebar(){
        if (this.props.toggle_sidebar_state) this.props.setHideSidebar();
        else this.props.setShowSidebar();
    }

    checkPermission = (name) => {
        let all = this.state.permissions.filter((item) => item.name === name);
        if(all.length > 0)
            return true;
    };

    handleGo  = (link) => {
        history.push(link);
    };

    render() {
        const date = Date.now();
        return(

            <header className="header-nav ">
                <nav className="navbar justify-content-start">
                    <Link to="/" className="navbar-brand m-0 p-0">
                        <img src={Logo} alt="abadi" className="logo" />
                    </Link>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        className='m-0'
                        onClick={this.handleToggleSidebar}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <div className="date d-none d-xl-block">
                        <p className="mb-0 mr-3">{moment(date).format('DD MMM YYYY, dddd')}</p>
                    </div>

                    <Button
                        onClick={() => this.handleGo('/projects')}
                        variant="contained"
                        color="primary"
                        className="mr-3"
                    >
                        <SearchIcon />
                    </Button>
                    <Button
                        onClick={() => this.handleGo('/projects/add')}
                        variant="contained"
                        color="primary"
                        className="mr-3"
                    >
                        <DescriptionIcon />
                    </Button>

                    <IconButton
                        onClick={() => this.handleGo('/dashboard')}
                        className="ml-auto d-none d-lg-block icon-head"
                    >
                        <img src={home} alt="dashboard" />
                    </IconButton>
                    <IconButton
                        onClick={() => this.handleGo('/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=onp')}
                        className=" d-none d-lg-block icon-head"
                    >
                        <img src={report} alt="report" />
                    </IconButton>
                    <IconButton
                        onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=onp')}
                        className=" d-none d-lg-block icon-head"
                    >
                        <img src={approval} alt="approval" />
                    </IconButton>
                    {this.checkPermission('list-invoices') &&
                    <IconButton
                        onClick={() => this.handleGo('/dashboard-finance')}
                        className=" d-none d-lg-block icon-head"
                    >
                        <img src={finance} alt="finance" />
                    </IconButton>
                    }
                    {this.checkPermission('list-invoices') &&
                    <IconButton
                        onClick={() => this.handleGo('/os-clients')}
                        className=" d-none d-lg-block icon-head"
                    >

                        <img src={client} alt="client" />
                    </IconButton>
                    }
                    {this.checkPermission('list-projects') &&
                        <IconButton
                            onClick={() => this.handleGo(`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'URGENT')?.id || null}`)}
                            className=" d-none d-lg-block icon-head"
                        >
                            <img src={WarningIconBlack} alt="urgent" />
                        </IconButton>
                    }
                    {this.checkPermission('list-projects') &&
                        <IconButton
                            onClick={() => this.handleGo(`/projects-filter?case_status_id=${this.state.case_status.find((item) => item.name === 'Inquiry')?.id || null}`)}
                            className=" d-none d-lg-block icon-head"
                        >
                            <img src={QuestionIconBlack} alt="inquiry" />
                        </IconButton>
                    }

                    <div className="nav-item profile-picture mb-0">
                        <Link to="/profile" className="d-flex align-items-center">
                            <img src={this.state.photo} alt="user" className="user-photo mr-2 mt-0"/>
                            <div className="content d-none d-xl-block">
                                <h3>{this.state.userName}</h3>
                                <span>{this.state.userRole}</span>
                            </div>
                        </Link>
                    </div>
                </nav>
            </header>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);