import React,{Component} from 'react';
import img from '../../../images/home.png';
import {Link} from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import iconPending from '../../../images/yellow-1.png';
import iconPendingP from '../../../images/yellow-2.png';
import iconIssuedW from '../../../images/blue-1.png';
import iconIssuedM from '../../../images/blue-0.png';
import iconComplete from '../../../images/green-1.png';
import iconNewW from '../../../images/blue-2.png';
import iconNewM from '../../../images/blue-3.png';
import iconReport from '../../../images/icon-3.png';
import iconApproval from '../../../images/icon-4.png';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import Api from "../../../libraries/api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

class ContentDashboard extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            years: [],
            year: '',
            total_year: '',

            data: {},
            jan: {},
            feb: {},
            mar: {},
            apr: {},
            may: {},
            jun: {},
            jul: {},
            aug: {},
            sep: {},
            okt: {},
            nov: {},
            dec: {},

            dataIn: {},
            janIn: {},
            febIn: {},
            marIn: {},
            aprIn: {},
            mayIn: {},
            junIn: {},
            julIn: {},
            augIn: {},
            sepIn: {},
            oktIn: {},
            novIn: {},
            decIn: {},
            total_year_in: '',
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Dashboard Finance';

        this.setState({
            loading: true,
        });

        let rows = [];
        let year = moment().year();
        for (let i=2002; i <= year; i++) {
            rows.push(i);
        }
        this.__fetchData();

        this.setState({
            years: rows,
            year: year,
        })

    }

    handleChange (e, prop){

        this.setState({
            loading: true,
            year: e.target.value
        }, () => {
            this.__fetchData();
        });

    };

    __fetchData = () => {
        Api.get('/reports/financial-statement?year='+this.state.year).then(resp => {

            if (resp.data) {
                this.setState({
                    data: resp.data,
                    jan: resp.data.Jan,
                    feb: resp.data.Feb,
                    mar: resp.data.Mar,
                    apr: resp.data.Apr,
                    may: resp.data.Mei,
                    jun: resp.data.Jun,
                    jul: resp.data.Jul,
                    aug: resp.data.Agu,
                    sep: resp.data.Sep,
                    okt: resp.data.Okt,
                    nov: resp.data.Nov,
                    dec: resp.data.Des,
                    total_year: resp.data.total_year,
                    loading: false
                })

            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false
            })
        });

        Api.get('/reports/invoice-statement?year='+this.state.year).then(resp => {

            if (resp.data) {
                this.setState({
                    dataIn: resp.data,
                    janIn: resp.data.Jan,
                    febIn: resp.data.Feb,
                    marIn: resp.data.Mar,
                    aprIn: resp.data.Apr,
                    mayIn: resp.data.Mei,
                    junIn: resp.data.Jun,
                    julIn: resp.data.Jul,
                    augIn: resp.data.Agu,
                    sepIn: resp.data.Sep,
                    oktIn: resp.data.Okt,
                    novIn: resp.data.Nov,
                    decIn: resp.data.Des,
                    total_year_in: resp.data.total_year,
                    loading: false
                })

            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false
            })
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Dashboard Finance</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Dashboard Finance</li>                        </ol>
                    </nav>
                    <h3 className='title-wrap'>DASHBOARD FINANCE</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label>Pilih Tahun</label>
                                <TextField variant="outlined"
                                           select
                                           id="year"
                                           name="year"
                                           label="Tahun"
                                           onChange={(e) => this.handleChange(e, 'year')}
                                           value={this.state.year}
                                           fullWidth
                                >

                                    {this.state.years.map(option => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    </div>

                    <div className="table-wrapper mb-3">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan='9' className='text-center'>
                                        <h2 className='table-heading'>ABADI FINANCIAL STATEMENT REPORT & APPROVAL</h2>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>Month</TableCell>
                                    <TableCell colSpan='2' className='text-center'>TOTAL INVOICING</TableCell>
                                    <TableCell colSpan='2' className='text-center'>TOTAL OUTSTANDING INVOICE<br/> (berdasarkan tanggal terbit invoice) </TableCell>
                                    <TableCell colSpan='2' className='text-center'>TOTAL PAID INVOICE</TableCell>
                                    <TableCell colSpan='2' className='text-center'>TOTAL OPERATION CASH FLOW </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>USD</TableCell>
                                    <TableCell className='text-center'>Rupiah</TableCell>
                                    <TableCell className='text-center'>USD</TableCell>
                                    <TableCell className='text-center'>Rupiah</TableCell>
                                    <TableCell className='text-center'>USD</TableCell>
                                    <TableCell className='text-center'>Rupiah</TableCell>
                                    <TableCell className='text-center'>Cash Advance</TableCell>
                                    <TableCell className='text-center'>Expenses</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.data &&
                            <TableBody>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/1'} target='_blank'>Januari</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jan?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/2'} target='_blank'>Februari</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.feb?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/3'} target='_blank'>Maret</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.mar?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/4'} target='_blank'>April</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.apr?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/5'} target='_blank'>Mei</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.may?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/6'} target='_blank'>Juni</a>
                                    </TableCell>
                                    <TableCell>
                                        $<NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jun?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/7'} target='_blank'>Juli</a>

                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.jul?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/8'} target='_blank'>Agustus</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.aug?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/9'} target='_blank'>September</a>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp<NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.sep?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/10'} target='_blank'>Oktober</a>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.okt?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/11'} target='_blank'>November</a>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.nov?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/'+this.state.year+'/12'} target='_blank'>Desember</a>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.total_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.total_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.out_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.out_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.paid_invoice_usd?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.paid_invoice_idr?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.cash_advance?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                       Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.dec?.expenses?.value || 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='0'
                                className='bold'>
                                    <TableCell>TOTAL</TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_total_invoice_usd || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_total_invoice_idr || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_out_invoice_usd || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_out_invoice_idr || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        $ <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_paid_invoice_usd || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_paid_invoice_idr || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_cash_advance || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                    <TableCell>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={this.state.total_year?.gd_expenses || 0 } displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            }

                        </Table>
                    </div>

                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan='9' className='text-center'>
                                        <h2 className='table-heading'>ABADI INVOICE STATEMENT</h2>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell rowSpan='2' className='text-center'>Month</TableCell>
                                    <TableCell colSpan='3' className='text-center'>INVOICE ISSUED</TableCell>
                                    <TableCell colSpan='3' className='text-center'>PENDING ISSUED IVOICE </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='text-center'>Report</TableCell>
                                    <TableCell className='text-center'>Approval</TableCell>
                                    <TableCell className='text-center'>Total</TableCell>
                                    <TableCell className='text-center'>Report</TableCell>
                                    <TableCell className='text-center'>Approval</TableCell>
                                    <TableCell className='text-center'>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.dataIn &&
                            <TableBody>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/1'} target='_blank'>Januari</a>
                                    </TableCell>
                                    <TableCell>{this.state.janIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell>{this.state.janIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell>{this.state.janIn?.total_issued || 0}</TableCell>
                                    <TableCell>{this.state.janIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell>{this.state.janIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell>{this.state.janIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/2'} target='_blank'>Februari</a></TableCell>
                                    <TableCell> {this.state.febIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.febIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.febIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.febIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.febIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.febIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/3'} target='_blank'>Maret</a></TableCell>
                                    <TableCell> {this.state.marIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.marIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.marIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.marIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.marIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.marIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/4'} target='_blank'>April</a></TableCell>
                                    <TableCell> {this.state.aprIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.aprIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.aprIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.aprIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.aprIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.aprIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/5'} target='_blank'>Mei</a></TableCell>
                                    <TableCell> {this.state.mayIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.mayIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.mayIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.mayIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.mayIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.mayIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/6'} target='_blank'>Juni</a></TableCell>
                                    <TableCell> {this.state.junIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.junIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.junIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.junIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.junIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.junIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/7'} target='_blank'>Juli</a></TableCell>
                                    <TableCell> {this.state.julIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.julIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.julIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.julIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.julIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.julIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/8'} target='_blank'>Agustus</a></TableCell>
                                    <TableCell> {this.state.augIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.augIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.augIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.augIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.augIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.augIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/9'} target='_blank'>September</a></TableCell>
                                    <TableCell> {this.state.sepIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.sepIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.sepIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.sepIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.sepIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.sepIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/10'} target='_blank'>Oktober</a></TableCell>
                                    <TableCell> {this.state.oktIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.oktIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.oktIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.oktIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.oktIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.oktIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/11'} target='_blank'>November</a></TableCell>
                                    <TableCell> {this.state.novIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.novIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.novIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.novIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.novIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.novIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0'>
                                    <TableCell>
                                        <a href={'/dashboard-finance/invoice/'+this.state.year+'/12'} target='_blank'>Desember</a></TableCell>
                                    <TableCell> {this.state.decIn?.report_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.decIn?.approval_issued?.value || 0}</TableCell>
                                    <TableCell> {this.state.decIn?.total_issued || 0}</TableCell>
                                    <TableCell> {this.state.decIn?.report_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.decIn?.approval_pending?.value || 0}</TableCell>
                                    <TableCell> {this.state.decIn?.total_pending || 0}</TableCell>
                                </TableRow>
                                <TableRow key='0' className='bold'>
                                    <TableCell>TOTAL</TableCell>
                                    <TableCell>{this.state.total_year_in?.total_report_issued || 0}</TableCell>
                                    <TableCell>{this.state.total_year_in?.total_approval_issued || 0}</TableCell>
                                    <TableCell>{this.state.total_year_in?.total_issued || 0}</TableCell>
                                    <TableCell>{this.state.total_year_in?.total_report_pending || 0}</TableCell>
                                    <TableCell>{this.state.total_year_in?.total_approval_pending || 0}</TableCell>
                                    <TableCell>{this.state.total_year_in?.total_pending || 0}</TableCell>
                                </TableRow>
                            </TableBody>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContentDashboard;