import React,{Component} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../../libraries/api';
import {toast, ToastContainer} from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import {history} from '../../../shared/configure-store';

require('dotenv').config();


const styleWidth = {
    minWidth: 'none',
};

const styleWidth1 = {
    minWidth: 'none',
};

const styleMax = {
    width: 'auto',
};

class Admin extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            orderBy: '',
            orderByStatus: '',
            sortedBy: '',
            searchBy: '',

            roles: [],
            showDialog: false,
            showDialogFilter: false,
            idDelete: '',
            currDelete: '',

            name: '',
            detail: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            isEdit: false,
            isDelete: false,
            isCreate: false,

            case_number: '',
            project_number: '',
            ext_project_number: '',
            vessel_name: '',
            casualty: '',
            client_id: '',
            typeId: '',
            projectStatusReportId: '',
            projectStatusApprovalId: '',
            projectStatus: '',
            surveyorId: '',

            types: [],
            admins: [
                {
                    "object": "User",
                    "id": "ed69795b-658d-4241-919d-ab846fd893cd",
                    "name": "Tania Dwiretno",
                    "email": "tania@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eee41-b2ac-11ea-9f52-0242ac180002",
                    "name": "Ibadurrahman",
                    "email": "Ibad@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ef15d-b2ac-11ea-9f52-0242ac180002",
                    "name": "Nadhia Tiara Astari",
                    "email": "nadhia@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eea5a-b2ac-11ea-9f52-0242ac180002",
                    "name": "Zulfah Zikrina",
                    "email": "zikrina@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee788-b2ac-11ea-9f52-0242ac180002",
                    "name": "Clarima Flourenza",
                    "email": "clarimaflo@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee3dd-b2ac-11ea-9f52-0242ac180002",
                    "name": "Rasyid Irham",
                    "email": "rasyid.irham@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ef090-b2ac-11ea-9f52-0242ac180002",
                    "name": "Nilam Sari Octaviani",
                    "email": "nilam.octaviani@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eebf0-b2ac-11ea-9f52-0242ac180002",
                    "name": "Muhammad Ryan",
                    "email": "ryan@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee5f0-b2ac-11ea-9f52-0242ac180002",
                    "name": "Iqbal Nashirudin",
                    "email": "iqbal.nash@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eef02-b2ac-11ea-9f52-0242ac180002",
                    "name": "Anindia Setyo Widiani",
                    "email": "anindia.setyo@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eea9d-b2ac-11ea-9f52-0242ac180002",
                    "name": "Iskandar",
                    "email": "iskandarzulkarnain@abadisurvey.com",
                }
            ],
            project_status: [],
            surveyors: [
                {
                    "name": "Abdul Hakim",
                    "id": "fa5eecbd-b2ac-11ea-9f52-0242ac180002",
                    "object": "User",
                    "email": "ABDULHAKIM@ABADISURVEY.COM",
                },
                {
                    "object": "User",
                    "id": "fa5eec7c-b2ac-11ea-9f52-0242ac180002",
                    "name": "Afuza Asyraf",
                    "email": "afuza@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee6c5-b2ac-11ea-9f52-0242ac180002",
                    "name": "Ahmad Fauzi",
                    "email": "ahmadfauzi@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee80f-b2ac-11ea-9f52-0242ac180002",
                    "name": "Aji Pamungkas",
                    "email": "ajipamungkas@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee997-b2ac-11ea-9f52-0242ac180002",
                    "name": "Alif Hastono",
                    "email": "alifhastono@abadisurvey.com",
                    "phone": "0811154396",
                },
                {
                    "object": "User",
                    "id": "fa5ee919-b2ac-11ea-9f52-0242ac180002",
                    "name": "Andri Astono",
                    "email": "andriastono@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee706-b2ac-11ea-9f52-0242ac180002",
                    "name": "Anwar Hendarto",
                    "email": "anwarhen@gmail.com",
                },
                {
                    "object": "User",
                    "id": "fa5eeec0-b2ac-11ea-9f52-0242ac180002",
                    "name": "Bimantara Haryudanto P",
                    "email": "bima.haryudanto@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eed00-b2ac-11ea-9f52-0242ac180002",
                    "name": "Bram Sebastian",
                    "email": "Bram@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee7cc-b2ac-11ea-9f52-0242ac180002",
                    "name": "Dimas Yansetyo Akbar",
                    "email": "dimas.y@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee682-b2ac-11ea-9f52-0242ac180002",
                    "name": "Fendi Aji Muliana",
                    "email": "fendimuliana@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee8d9-b2ac-11ea-9f52-0242ac180002",
                    "name": "Franz W L",
                    "email": "franzwl@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee899-b2ac-11ea-9f52-0242ac180002",
                    "name": "Heru Sudibyo",
                    "email": "herusudibyo@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eefce-b2ac-11ea-9f52-0242ac180002",
                    "name": "Muh Hisyam Khorudin",
                    "email": "hisyamkhoirudin@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee851-b2ac-11ea-9f52-0242ac180002",
                    "name": "Okti",
                    "email": "okti@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee958-b2ac-11ea-9f52-0242ac180002",
                    "name": "Pudjo Kristanto",
                    "email": "pudjo.k@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee638-b2ac-11ea-9f52-0242ac180002",
                    "name": "Rafli Alfazer",
                    "email": "rafli.alfazer@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eedc2-b2ac-11ea-9f52-0242ac180002",
                    "name": "Rizky Hidayat Putra",
                    "email": "rizky@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee747-b2ac-11ea-9f52-0242ac180002",
                    "name": "Tomy Wijaya",
                    "email": "tomywijaya@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5ee5a2-b2ac-11ea-9f52-0242ac180002",
                    "name": "Yuniva Eka Nugroho",
                    "email": "yunivanugroho@abadisurvey.com",
                },
                {
                    "object": "User",
                    "id": "fa5eee81-b2ac-11ea-9f52-0242ac180002",
                    "name": "Yusro Fahmi",
                    "email": "yusro@abadisurvey.com",
                }
            ],
            report_status: [],
            approval_status: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi - Taks';




        let admins = [];
        Api.get('/users?additional=adm-report&limit=0').then(resp => {

            if (resp.data) {
                _.forEach(resp.data, function(v,i){
                    admins.push(v)
                });
                Api.get('/users?additional=adm-approval&limit=0').then(resp => {

                    if (resp.data) {
                        _.forEach(resp.data, function(v,i){
                            admins.push(v)
                        });
                        this.setState({
                            admins: admins,
                        },() => {
                            this.__fetchData();
                        });
                        console.log(admins)
                    }

                }).catch(err => {
                    console.log(err);
                });

            }

        }).catch(err => {
            console.log(err);
        });
    }

    __fetchData = () => {

        let all = [];
        _.forEach(this.state.admins, function(v,i){
            let filter = '&projectStatus=On%20Progress+Follow%20Up&adminId='+v.id;
            let params = {
                projectStatus: ['On Progress', 'Follow Up'],
                adminId: v.id
            };
            let route = '/projects?limit=0';

            Api.post(route, params).then(resp => {
                if (resp.data) {
                    all.push({
                        name: v.name,
                        data: resp.data
                    })
                }
            }).catch(err => {
                console.log(err);
            });
        });

        setTimeout(function(){
            this.setState({
                rows: all,
            })
        }.bind(this), 5000)
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;
        let sortStatus = '';

        if(val === 'projectStatusBar'){
            sortStatus = 'projectStatusBar';
        }else if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderByStatus: sortStatus,
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChangeClient (e, prop){

        this.setState({

            [prop]: e

        });

    };
    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleOpenFilter = () => {
        this.setState({
            showDialogFilter :true,
        })
    };

    handleCloseFilter = () => {
        this.setState({
            showDialogFilter :false,
        })
    };

    handleSubmitFilter = () => {

        this.__fetchData(false);

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    filterStatus = (name) => {
        let text = '';

        switch(name) {
            case 'Follow Up':
                text = 'yellow';
                break;
            case 'On Progress':
                text = 'blue';
                break;
            case 'Finish':
                text = 'purple';
                break;
            case 'Completed':
                text = 'green';
                break;
            case 'Cancel':
                text = 'red';
                break;
            case 'Pending':
                text = 'yellow';
                break;
            case 'Partial Payment':
                text = 'green';
                break;
            case 'Complete Payment':
                text = 'blue';
                break;
            default:
                text = 'yellow';
        }

        return text;
    };

    render() {
    console.log(this.state.rows)
        return (
            <div className='row main-content'>
                <div className='col-12 px-lg-5'>
                    <h1 className='page-title'>Admin On Going</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><Link to='/' >Home</Link></li>
                            <li className='breadcrumb-item active' aria-current='page'>Admin On Going</li>
                        </ol>
                    </nav>

                </div>
                <div className='col-12 mt-3 px-lg-5'>
                    <div className='table-wrapper'>
                        {this.state.rows.length === 0 ? (
                            <TableContainer>
                                <Table className='table-list' size='small' >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Case No</TableCell>
                                            <TableCell style={styleWidth1}>Underwritter</TableCell>
                                            <TableCell>Project Type</TableCell>
                                            <TableCell>Vessel</TableCell>
                                            <TableCell style={styleMax} className='clickable'>Casualties</TableCell>
                                            <TableCell style={styleWidth1}>Survey Status</TableCell>
                                            <TableCell style={styleWidth}>Project Status</TableCell>
                                            <TableCell style={styleMax}>Last Status </TableCell>
                                            <TableCell >Days
                                            </TableCell>
                                            <TableCell style={styleWidth}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableRow style={{ height: 33 * 3}}>
                                        <TableCell colSpan={10} align='center' className='py-5'>
                                            <CircularProgress color='primary'  />
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </TableContainer>
                        ) : this.state.rows.map(row => (
                            row.data.length === 0 ?
                                '' :
                                <TableContainer className='border-top pt-3'>
                                    <h2 className='title-wrap'>{row.name} ({row.data.length})</h2>
                                    <Table className='table-list' size='small' >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Case No</TableCell>
                                                <TableCell style={styleWidth1}>Underwritter</TableCell>
                                                <TableCell>Project Type</TableCell>
                                                <TableCell>Vessel</TableCell>
                                                <TableCell style={styleMax} >Casualties</TableCell>
                                                <TableCell style={styleWidth1}>Survey Status</TableCell>
                                                <TableCell style={styleWidth}>Project Status</TableCell>
                                                <TableCell style={styleMax}>Last Status </TableCell>
                                                <TableCell >Days
                                                </TableCell>
                                                <TableCell style={styleWidth}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.data.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>
                                                        <Link to={'/projects/detail/' + row.id} target='_blank'>
                                                            {row.caseNumber === null ? '' : row.caseNumber}{row.projectNumber === null ? '' : '['+row.projectNumber+']'}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.underWriters &&
                                                        row.underWriters.map((row, id) => (
                                                            <small key={id}>{row.name}, </small>
                                                        ))
                                                        }
                                                    </TableCell>
                                                    <TableCell>{row.type === null ? '' : row.type.name}{row.detailType === null ? '' : '['+row.detailType.name+']'}</TableCell>
                                                    <TableCell>{row.vesselName === null ? '' : row.vesselName}</TableCell>
                                                    <TableCell>{row.casualty === null ? '' : row.casualty}</TableCell>
                                                    <TableCell>
                                                        {row.surveyStatus &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.surveyStatus !== null && row.surveyStatus.project_status)}>{row.surveyStatus === null ? '' : row.surveyStatus.name}</span>
                                                            {row.dateSurveyStatus === null ? '' : moment(row.dateSurveyStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.projectStatus &&
                                                        <small>
                                                            <span className={'label-status label-'+this.filterStatus(row.projectStatus !== null && row.projectStatus.project_status)}>{row.projectStatus === null ? '' : row.projectStatus.name}</span>
                                                            {row.dateStatus === null ? '' : moment(row.dateStatus).format('DD MMM YYYY')}
                                                        </small>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.comment === null ? '' : row.comment.length > 0 && row.comment[0].comment.substring(0, 50)}
                                                        <small className='d-block'>{row.updated_at === null ? '' : moment(row.updated_at).format('DD MMM YYYY')}</small>
                                                    </TableCell>
                                                    <TableCell>{row.numberOfDays}</TableCell>
                                                    <TableCell>
                                                        <div className="d-flex">
                                                            <Link to={'/projects/detail/' + row.id} className='btn-icon mr-2'>
                                                                <i className='fas fa-eye'> </i>
                                                            </Link>
                                                            <Link to={'/projects/edit/' + row.id} className='btn-icon mr-2'>
                                                                <i className='fas fa-edit'> </i>
                                                            </Link>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                        ))
                        }
                    </div>
                </div>

                <ToastContainer autoClose={3000} />

            </div>
        )
    }
}

export default Admin;
