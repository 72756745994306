import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from "lodash";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class RolesEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            roles: [],
            initialPermissions: [],
            permissions: [],
            permissionsAll: [],
            checkedPermission: [],

            id: '',
            displayName: '',
            name: '',
        };
        this.handleChange = this.handleChange.bind(this);   
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        document.title = 'Abadi - Edit Permission';

        Api.get('/roles?limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    roles: resp.data 
                }, () => {
                    this.getCurrent(id);
                    this.getCurrent(id, true);
                });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/permissions?limit=0').then(resp => {
            let templist = [];
            if (resp.data) {
                _.forEach(resp.data,function(v){
                    v.checked = false;
                    templist.push(v.id);
                });
                this.setState({ permissionsAll: resp.data });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){
        this.getCurrent(e.target.value);
        this.setState({ [prop]: e.target.value });
    }

    getCurrent(id, isInitialPermissions = false) {
        let list = [];

        if (this.state.roles.length > 0) {
            let found = this.state.roles.filter(function(no) {
                return no.id === id;
            });

            let data = found[0].permissions.data;

            if (data) {
                _.forEach(data,function(v){
                    list.push(v.id);
                });
            }

            if (isInitialPermissions) {
                this.setState({ initialPermissions: list });
            } else {
                this.setState({
                    displayName: found[0].display_name,
                    name: found[0].id,
                    permissions: list,
                });
            }
        } else {
            return '';
        }
    }

    getChecked(name) {
        if(this.state.permissions.length > 0) {
            let found = this.state.permissions.includes(name);
            return found;
        }else {
            return false
        }
    }

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    handleChecked = name => event => {
        let temp = this.state.permissions;
        let isChecked = event.target.checked;

        if (isChecked) {
            temp.push(name);
        } else {
            let index = temp.indexOf(name);
            if (index > -1) temp.splice(index, 1);
        }

        this.setState({ permissions: temp });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {
            this.setState({ errors: validator.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingButton: true });

        let detach_ids = (this.state.initialPermissions).filter((x) => !(this.state.permissions).includes(x));
        let params = {
            role_id : this.state.name,
            permissions_ids : this.state.permissions,
            ...(detach_ids.length > 0 ? { detach_ids } : {}),
        };

        Api.post('/permissions/attach', params, false).then(resp => {
            this.setState({ loadingButton: false });
            this.showMessage(true, 'Roles updated');
        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)){

            } else{
                this.setState({ errors: err.errors, loadingButton: false });
                this.showMessage(false, 'Invalid format data');
            }
        });
    }

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h2 className="page-title">Permission</h2>
                </div>
                <div className="col-12 mt-3">
                    <div className="table-wrapper">
                        <form name="edit" id="editPermission" className="row" noValidate>
                            <div className="col-12">
                                <h2 className="page-title">{this.state.displayName}</h2>
                                <div className="row mb-4">
                                    {
                                        this.state.permissionsAll.map((item, key) => {
                                            return (
                                                <FormGroup key={key} className="col-6">
                                                    {
                                                        this.state.permissions.length >= 0 ?
                                                            (
                                                                <FormControlLabel
                                                                    color="default"
                                                                    control={<Checkbox
                                                                    checked={this.getChecked(item.id)}
                                                                    onChange={this.handleChecked(item.id)}
                                                                    value={item.id}/>}
                                                                    label={item.name}
                                                                    className="mb-0"
                                                                />
                                                            )
                                                            : ''
                                                    }
                                                </FormGroup>
                                            )
                                        })
                                    }
                                </div>
                                <Link to="/roles" className="btn btn-blue-trans mr-3">
                                    Cancel
                                </Link>
                                <button
                                    className="btn btn-blue"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && 'disabled' }
                                >
                                    Save { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer autoClose={3000}/>
            </div>
        )
    }
}

export default RolesEdit;
