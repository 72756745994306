import React, { useEffect, useState, createRef } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment/moment';
import SimpleReactValidator from 'simple-react-validator';
import MomentUtils from '@date-io/moment/build/index';

import FullCalendar from '@fullcalendar/react';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Tooltip } from 'bootstrap';
import Select from 'react-select';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.css';
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import { TO_DO_LIST_COLORS, TO_DO_LIST_OVERVIEW_ROLES } from '../../../constants/global';


let validatorModal = new SimpleReactValidator({ locale: process.env.REACT_APP_LOCALE });

const ToDoList = () => {
    const calendarRef = createRef();
    const [enableOverview, setEnableOverview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModalForm, setShowModalForm] = useState(false);
    const [events, setEvents] = useState([]);
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [filterUser, setFilterUser] = useState(null);
    const [filterStart, setFilterStart] = useState('');
    const [filterEnd, setFilterEnd] = useState('');
    const [authUserId, setAuthUserId] = useState(null);

    const [modalFormState, setModalFormState] = useState('create');
    const [eventId, setEventId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [title, setTitle] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [desc, setDesc] = useState(null);
    const [allDay, setAllDay] = useState(false);
    const [status, setStatus] = useState(null);
    let tooltipInstance = null;

    useEffect(() => {
        fetchUserProfile();
    }, []);

    useEffect(() => {
        if ((users && users.length) || filterUser?.value) {
            fetchEvents();
        }
    }, [users, filterUser?.value]);

    useEffect(() => {
        if (enableOverview) fetchUsers();
    }, [enableOverview]);

    const fetchUserProfile = () => {
        const profile = AuthHelper.getProfile();

        if (TO_DO_LIST_OVERVIEW_ROLES.includes(profile?.role)) {
            setEnableOverview(true);
        } else if (profile?.id) {
            setAuthUserId(profile.id);
            setFilterUser({ label: '', value: profile.id});
        }
    }

    const fetchUsers = () => {
        Api.get('/users?limit=0').then(resp => {
            if (resp?.data) {
                const data = (resp?.data || []).map((item) => ({ value: item?.id, label: item?.name }));
                setUsers(data);
            }
        });
    }

    const fetchEvents = () => {
        setLoading(true);

        let url = `/events?start=${filterStart}&end=${filterEnd}`;
        if (filterUser?.value) url += `&user_id=${filterUser?.value}`;

        Api.get(url).then(resp => {
            let mappedEvents = [];
            (resp?.data || []).forEach((item) => {
                if (item?.all_day || !item?.end) {
                    item.start = moment(item?.start).format('YYYY-MM-DD');
                    item.end = null;
                } else {
                    item.start = moment(item?.start).toISOString();
                    item.end = moment(item?.end).toISOString();
                }

                item.user_id = item?.user?.id || null;
                item.user_name = item?.user?.name || 'User not found';
                item.color = item.status === 1 ? TO_DO_LIST_COLORS.eventClosed : TO_DO_LIST_COLORS.eventOpen;

                mappedEvents.push(item);
            });
            setEvents(mappedEvents);
            setLoading(false);
        });
    }

    const fetchCalendarActiveRange = () => {
        let calendarApi = calendarRef?.current?.getApi() || null;

        let calendarStart = calendarApi?.currentData?.viewApi?.activeStart;
        let calendarEnd = calendarApi?.currentData?.viewApi?.activeEnd;

        if (calendarStart) calendarStart = moment(calendarStart);
        if (calendarEnd) calendarEnd = moment(calendarEnd);

        setFilterStart(calendarStart);
        setFilterEnd(calendarEnd);
    }
    
    const showMessage = (status, message) => {
        if (status) toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        else toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div className='d-flex justify-content-between align-items-center  px-1'>
                <div>
                    <b>{eventInfo.timeText}</b>&nbsp;
                    <b>{eventInfo.event.title}</b>
                </div>
                <Checkbox
                    size='small'
                    color='default'
                    checked={!!eventInfo.event.all_day}
                    value={eventInfo.event.all_day}
                    onChange={(a,b,c) => console.log(a,b,c)}
                    disableRipple
                    disableFocusRipple
                />
            </div>
        );
    }

    const renderEventClassnames = (arg) => {
        let classes = [];

        if (arg?.event?.extendedProps?.all_day) classes.push('all-day');
        else classes.push('reg');

        if (arg?.event?.extendedProps?.status === 1) classes.push('closed');
        else classes.push('open');

        return classes;
    }

    const handleDateSelect = (selectInfo) => {
        const viewType = selectInfo.view.type;

        if (viewType === 'dayGridMonth') {
            const initialStartDate = moment(selectInfo.startStr).set({ hour: 8, minute:0 });
            const initialEndDate = moment(initialStartDate).add(1, 'hour');
            
            handleToggleAllDay(null, false);
            setStartDate(initialStartDate);
            setEndDate(initialEndDate);
        }

        setModalFormState('create');
        setShowModalForm(true);
    }

    const handleMouseEnter = (info) => {
        clearTooltip();

        if (info.view.type !== 'listMonth') {
            if (info.event.extendedProps.desc) {
              tooltipInstance = new Tooltip(info.el, {
                title: info.event?.extendedProps?.user_name || 'User not found',
                html: true,
                placement: 'top',
                trigger: 'hover',
                container: 'body',
              });
        
              tooltipInstance.show();
            }
        }
    }
    
    const handleMouseLeave = (info) => {
        clearTooltip();
    }

    const clearTooltip = () => {
        if (tooltipInstance) {
            tooltipInstance.dispose();
            tooltipInstance = null;
        }
    }

    const handleEventClick = (clickInfo) => {
        const detail = clickInfo?.event;
        const extendedProps = clickInfo?.event?.extendedProps; // miscellaneous other properties from backend non standard with Fullcalendar plugin (other than: id,start,end)
        const allDayProps = extendedProps?.all_day || (!detail?.end ? true : false);

        setEventId(detail?.id);
        setUserId({ value: extendedProps?.user?.id, label: extendedProps?.user?.name });
        setTitle(detail?.title);
        setStartDate(detail?.start);
        setEndDate(detail?.end || null);
        setDesc(extendedProps?.desc);
        setStatus(extendedProps?.status);
        handleToggleAllDay(null, allDayProps);

        setModalFormState('edit');
        setShowModalForm(true);
        clearTooltip();
    }

    const handleToggleAllDay = (e, forcedValue) => {
        const value = forcedValue ? forcedValue : e?.target?.checked;
        if (value) validatorModal.purgeFields();
        setAllDay(value);
    }

    const handleChangeStartDateTimePicker = (date) => {
        setStartDate(date);

        const autoSelectNextHour = moment(date).add(1, 'hour');
        setEndDate(autoSelectNextHour);
    }

    const handleResetForm = () => {
        setUserId(null);
        setEventId(null);
        setTitle(null);
        setStartDate(null);
        setEndDate(null);
        setDesc(null);
        handleToggleAllDay(null, false);
        setStatus(null);
    }

    const handleFilter = (field, selected) => {
        switch (field) {
            case 'filterUser':
                setFilterUser(selected || null);
                break;
            default:
                break;
        }
    }
    
    const handleCreateEvent = () => {
        setModalFormState('create');
        setShowModalForm(true);
    }

    const handleCloseModalForm = () => {
        handleResetForm();
        setShowModalForm(false);
    }

    const handleEventDragChange = (item) => {
        console.log('handleEventDragChange => item =>', item);
    }

    const handleDelete = (e) => {
        e.preventDefault();

        if (eventId) {
            Api.delete('/events/'+eventId).then(() => {
                showMessage(true, 'Item successfully deleted');
                fetchEvents();
            }).catch(() => {
                showMessage(false, 'Failed to delete item');
            });

            setShowModalForm(false);
            handleResetForm();
        }
    }

    const handleSubmitUpdateStatus = (e) => {
        e.preventDefault();
        setLoading(true);

        let params = {
            status: status === 0 ? 1 : 0,
        };

        if (eventId) {
            Api.patch(`/events/${eventId}/status`, params, false).then((resp) => {
                setLoading(false);
                setShowModalForm(false);
                showMessage(true, 'Item status successfully updated');
                fetchEvents();
            }).catch((_err) => {
                showMessage(true, 'Failed to update item status');
            });
        } else {
            showMessage(false, 'Failed to edit item, missing ID parameter');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validatorModal.allValid()) {
            setErrors(validatorModal.getErrorMessages());
            return false;
        }

        // Check start/end date param
        if (!allDay) {
            if (moment(endDate).diff(moment(startDate)) <= 0)  {
                showMessage(false, 'End date must be later than Start date');
                return false;
            }
        }

        setErrors({});
        setLoading(true);

        const userParam = !enableOverview && authUserId 
            ? authUserId
            : (userId ? userId.value : null);
        const startDateParam = allDay 
            ? moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
            : moment(startDate).format('YYYY-MM-DD HH:mm:ss');

        let params = {
            user_id: userParam,
            start: startDateParam,
            end: allDay ? null : moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            title,
            desc,
            all_day: allDay,
        };

        if (modalFormState === 'edit') {
            if (eventId) {
                Api.patch(`/events/${eventId}`, params, false).then(resp => {
                    setLoading(false);
                    setShowModalForm(false);
                    showMessage(true, 'Item successfully updated');
                    handleResetForm();
                    fetchEvents();
                }).catch((_err) => {
                    showMessage(false, 'Failed to edit item');
                });
            } else {
                showMessage(false, 'Failed to edit item, missing ID parameter');
            }
        } else {
            Api.post('/events', params, false).then(resp => {
                setLoading(false);
                setShowModalForm(false);
                showMessage(true, 'Item successfully added');
                handleResetForm();
                fetchEvents();
            }).catch((_err) => {
                showMessage(false, 'Failed to add item');
            });
        }
    }

    const renderModalForm = (modalState) => {
        const disableForm = modalState === 'detail' ? true : false;
        const editForm = modalState === 'edit' ? true : false;
        return (
            <Dialog
                maxWidth='xs'
                aria-labelledby="form-dialog-title"
                open={showModalForm}
                fullWidth
                onClose={handleCloseModalForm}
            >
                <DialogTitle id="form-dialog-title">
                    {modalState === 'create' ? 'Create Item' : (modalState === 'edit' ? 'Edit Item' : 'Detail Item')}
                </DialogTitle>
                <DialogContent>
                    <form name="create" id="create-to-do" className="row" noValidate>
                        {enableOverview && (
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>User</label>
                                    <Select
                                        options={users}
                                        onChange={(selected) => setUserId(selected)}
                                        value={userId || null}
                                        isDisabled={disableForm}
                                        styles={{
                                            menu: (baseStyles) => ({ ...baseStyles, zIndex: 3 }),
                                        }}
                                        isClearable
                                        isSearchable
                                    />
                                    {validatorModal.message('userId', (userId?.value || null), 'required')}
                                    <div className='text-danger'>{errors?.userId || ''}</div>
                                </div>
                            </div>
                        )}
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Title</label>
                                <TextField
                                    id='title'
                                    name="title"
                                    label="Title"
                                    variant="outlined"
                                    type='text'
                                    onChange={(e) => setTitle(e?.target?.value)}
                                    value={title || ''}
                                    disabled={disableForm}
                                    fullWidth
                                    autoFocus
                                />
                                {validatorModal.message('title', title, 'required')}
                                <div className='text-danger'>{errors?.title || ''}</div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>All Day &nbsp;</label>
                                <Checkbox
                                    checked={!!allDay}
                                    value={allDay}
                                    onChange={handleToggleAllDay}
                                    disabled={disableForm}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>{allDay ? 'Date' : 'Start From'}</label>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    {
                                        allDay ? (
                                            <DatePicker
                                                label='Date'
                                                value={startDate || null}
                                                onChange={(date) => setStartDate(date)}
                                                format='DD MMM YYYY'
                                                cancelLabel="BATAL"
                                                inputVariant="outlined"
                                                autoOk
                                                disabled={disableForm}
                                            />
                                        ) : (
                                            <DateTimePicker
                                                label='Start from'
                                                value={startDate || null}
                                                onChange={(date) => handleChangeStartDateTimePicker(date)}
                                                format='DD MMM YYYY, HH:mm'
                                                cancelLabel="BATAL"
                                                inputVariant="outlined"
                                                disabled={disableForm}
                                                ampm={false}
                                                minutesStep={5}
                                                maxDate={endDate || undefined}
                                            />
                                        )
                                    }
                                </MuiPickersUtilsProvider>
                                {validatorModal.message('startDate', startDate, 'required')}
                                <div className='text-danger'>{errors?.startDate || ''}</div>
                            </div>
                        </div>
                        {!allDay && (
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Until</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            label="Until"
                                            value={endDate || null}
                                            onChange={(date) => setEndDate(date)}
                                            format='DD MMM YYYY, HH:mm'
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                            disabled={disableForm}
                                            ampm={false}
                                            minutesStep={5}
                                            minDate={startDate}
                                            initialFocusedDate={startDate || undefined}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {validatorModal.message('endDate', endDate, 'required')}
                                    <div className='text-danger'>{errors?.endDate || ''}</div>
                                </div>
                            </div>
                        )}
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Detail</label>
                                <TextField
                                    id='desc'
                                    name="desc"
                                    variant="outlined"
                                    type='text'
                                    label="Detail"
                                    onChange={(e) => setDesc(e?.target?.value)}
                                    value={desc || ''}
                                    disabled={disableForm}
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="justify-content-between pb-4 px-4">
                    <div>
                        <Button
                            variant="contained"
                            className="mr-2"
                            onClick={handleCloseModalForm}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={loading}
                        >

                            {loading ? <CircularProgress size={15} /> : <SaveIcon/>}
                            &nbsp;&nbsp;
                            {editForm ? 'Save' : 'Add Item'}
                        </Button>
                    </div>
                    {editForm && (
                        <div>
                            <IconButton
                                className="mr-2"
                                onClick={handleDelete}
                                style={{ color: TO_DO_LIST_COLORS.buttonDelete }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton
                                onClick={handleSubmitUpdateStatus}
                                style={{
                                    ...(status === 1 ? { color: TO_DO_LIST_COLORS.buttonReopenEvent } : { color: TO_DO_LIST_COLORS.buttonCloseEvent }),
                                }}
                            >
                                {status === 1 ? <ClearIcon/> : <CheckIcon/>}
                            </IconButton>
                        </div>
                    )}
                </DialogActions>
            </Dialog>
        );
    }


    return (
        <>
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">To Do List</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">To Do List</li>
                        </ol>
                    </nav>

                    <div className="table-wrapper">
                        <div className="row mb-3">
                            <div className="col-sm-12 col-md-6" >
                                {enableOverview && (
                                    <div className='form-group'>
                                        <label>Filter by User</label>
                                        <Select
                                            options={users}
                                            onChange={(selected) => handleFilter('filterUser', selected)}
                                            value={filterUser || null}
                                            styles={{
                                                menu: (baseStyles) => ({ ...baseStyles, zIndex: 3 }),
                                            }}
                                            isClearable
                                            isSearchable
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 pr-0 text-right mb-3 d-flex justify-content-end align-items-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="mb-3 mb-md-0"
                                    onClick={handleCreateEvent}
                                >
                                    <EditIcon/> &nbsp; Add To Do List
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <FullCalendar
                                    ref={calendarRef}
                                    plugins={[bootstrapPlugin, momentPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                    themeSystem='bootstrap'
                                    initialView='dayGridMonth'
                                    editable={true}
                                    selectable={true}
                                    selectMirror={true}
                                    dayMaxEvents={true} // allow "more" link when too many events
                                    navLinks={true}
                                    weekends={true}
                                    eventLimit={true}
                                    loading={loading}
                                    lazyFetching={true}
                                    events={events}
                                    height={800}
                                    contentHeight={780}
                                    aspectRatio={3}
                                    headerToolbar={{
                                        left: 'prev,next,today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                                    }}
                                    eventTimeFormat={{
                                        hour: 'numeric',
                                        minute: '2-digit',
                                        omitZeroMinute: false,
                                        meridiem: false,
                                        hour12: false,
                                    }}
                                    buttonText={{
                                        today: 'Today',
                                        month:'Monthly',
                                        week: 'Weekly',
                                        day: 'Daily',
                                        list: 'List',
                                    }}
                                    // eventContent={renderEventContent}
                                    eventClassNames={renderEventClassnames}
                                    eventClick={handleEventClick}
                                    eventChange={handleEventDragChange}
                                    eventMouseEnter={handleMouseEnter}
                                    eventMouseLeave={handleMouseLeave}
                                    select={handleDateSelect}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderModalForm(modalFormState)}
            <ToastContainer autoClose={3000} />
        </>
    );
};

export default ToDoList;