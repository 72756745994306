import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Api from "../../../libraries/api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import NumberFormat from "react-number-format";
import moment from "moment";

class ContentDashboardDetail extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,

            data: {},
            month: '',
            year: '',

            totalInvoiceUsd: [],
            totalInvoiceIdr: [],
            outInvoiceUsd: [],
            outInvoiceIdr: [],
            paidInvoiceUsd: [],
            paidInvoiceIdr: [],
            cashAdvance: [],
            expenses: [],
        }
    }

    componentDidMount() {
        document.title = 'Abadi - Finance Detail';
        this.__fetchData();
    }

    filter = month => {
        let m = '';
        switch(month) {
            case "1":
                m = "Januari";
                break;
            case "2":
                m = "Februari";
                break;
            case "3":
                m = "Maret";
                break;
            case "4":
                m = "April";
                break;
            case "5":
                m = "Mei";
                break;
            case "6":
                m = "Juni";
                break;
            case "7":
                m = "Juli";
                break;
            case "8":
                m = "Agustus";
                break;
            case "9":
                m = "September";
                break;
            case "10":
                m = "Oktober";
                break;
            case "11":
                m = "November";
                break;
            case "12":
                m = "Desember";
                break;
            default:
                m = "";
        }
        return m;
    };

    __fetchData = () => {
        this.setState({
            loading: true,
        });
        const month = this.props.match.params.month;
        const year = this.props.match.params.year;
        Api.get('/detail/financial-statement?year='+year+'&month='+month).then(resp => {

            if (resp.data) {
                this.setState({
                    data: resp.data,
                    year: year,
                    month: this.filter(month),
                    totalInvoiceUsd: resp.data.total_invoice_usd,
                    totalInvoiceIdr: resp.data.total_invoice_idr,
                    outInvoiceUsd: resp.data.out_invoice_usd,
                    outInvoiceIdr: resp.data.out_invoice_idr,
                    paidInvoiceUsd: resp.data.paid_invoice_usd,
                    paidInvoiceIdr: resp.data.paid_invoice_idr,
                    cashAdvance: resp.data.cash_advance,
                    expenses: resp.data.expenses,
                    loading: false
                })

            }

        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false
            })
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Finance Statement - {this.state.month} {this.state.year}</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Operation Statement</li>                        </ol>
                    </nav>
                    <h2 className='section-title'>TOTAL INVOICING</h2>
                    <h3 className='title-wrap font-weight-bold'>USD</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>INVOICE NO</TableCell>
                                    <TableCell className='text-center'>BANK RECEIVER</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TOTAL INVOICE</TableCell>
                                    <TableCell className='text-center'>REMARKS</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.totalInvoiceUsd &&
                            <TableBody>
                                {this.state.totalInvoiceUsd.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.invoice_number}</TableCell>
                                        <TableCell>{item.bank_receiver}</TableCell>
                                        <TableCell>{item.date_issued && moment(item.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            $ <NumberFormat thousandSeparator={true} decimalScale={2} value={item.total_amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                        <TableCell>{item.remarks}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap mt-3 font-weight-bold'>IDR</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>INVOICE NO</TableCell>
                                    <TableCell className='text-center'>BANK RECEIVER</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TOTAL INVOICE</TableCell>
                                    <TableCell className='text-center'>REMARKS</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.totalInvoiceIdr &&
                            <TableBody>
                                {this.state.totalInvoiceIdr.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.invoice_number}</TableCell>
                                        <TableCell>{item.bank_receiver}</TableCell>
                                        <TableCell>{item.date_issued && moment(item.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={item.total_amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                        <TableCell>{item.remarks}</TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>

                    <h2 className='section-title mt-3'>TOTAL OUTSTANDING INVOICE (berdasarkan tanggal terbit invoice) </h2>
                    <h3 className='title-wrap font-weight-bold'>USD</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>INVOICE NO</TableCell>
                                    <TableCell className='text-center'>BANK RECEIVER</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TOTAL INVOICE</TableCell>
                                    <TableCell className='text-center'>REMARKS</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.outInvoiceUsd &&
                            <TableBody>
                                {this.state.outInvoiceUsd.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.invoice_number}</TableCell>
                                        <TableCell>{item.bank_receiver}</TableCell>
                                        <TableCell>{item.date_issued && moment(item.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            $ <NumberFormat thousandSeparator={true} decimalScale={2} value={item.total_amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                        <TableCell>{item.remarks}</TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap mt-3 font-weight-bold'>IDR</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>INVOICE NO</TableCell>
                                    <TableCell className='text-center'>BANK RECEIVER</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TOTAL INVOICE</TableCell>
                                    <TableCell className='text-center'>REMARKS</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.outInvoiceIdr &&
                            <TableBody>
                                {this.state.outInvoiceIdr.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.invoice_number}</TableCell>
                                        <TableCell>{item.bank_receiver}</TableCell>

                                        <TableCell>{item.date_issued && moment(item.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={item.total_amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                        <TableCell>{item.remarks}</TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>

                    <h2 className='section-title mt-3'>TOTAL PAID INVOICE (berdasarkan tanggal terbit invoice) </h2>
                    <h3 className='title-wrap font-weight-bold'>USD</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>INVOICE NO</TableCell>
                                    <TableCell className='text-center'>BANK RECEIVER</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TOTAL INVOICE</TableCell>
                                    <TableCell className='text-center'>REMARKS</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.paidInvoiceUsd &&
                            <TableBody>
                                {this.state.paidInvoiceUsd.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.invoice_number}</TableCell>
                                        <TableCell>{item.bank_receiver}</TableCell>
                                        <TableCell>{item.date_issued && moment(item.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            $ <NumberFormat thousandSeparator={true} decimalScale={2} value={item.total_amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                        <TableCell>{item.remarks}</TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap mt-3 font-weight-bold'>IDR</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>INVOICE NO</TableCell>
                                    <TableCell className='text-center'>BANK RECEIVER</TableCell>
                                    <TableCell className='text-center'>DATE ISSUED</TableCell>
                                    <TableCell className='text-center'>TOTAL INVOICE</TableCell>
                                    <TableCell className='text-center'>REMARKS</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.paidInvoiceIdr &&
                            <TableBody>
                                {this.state.paidInvoiceIdr.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.invoice_number}</TableCell>
                                        <TableCell>{item.bank_receiver}</TableCell>
                                        <TableCell>{item.date_issued && moment(item.date_issued,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={item.total_amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                        <TableCell>{item.remarks}</TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>

                    <h2 className='section-title mt-3'>TOTAL OPERATION CASH FLOW </h2>
                    <h3 className='title-wrap font-weight-bold'>EXPENSES</h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>PLACE OF SURVEY</TableCell>
                                    <TableCell className='text-center'>DATE SURVEY</TableCell>
                                    <TableCell className='text-center'>TOTAL EXPENSES</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.expenses &&
                            <TableBody>
                                {this.state.expenses.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                        <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client_underwriter}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.survey_comment}</TableCell>
                                        <TableCell>{item.date_survey_status && moment(item.date_survey_status,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={item.amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                    <h3 className='title-wrap mt-3 font-weight-bold'>CASH ADVANCE </h3>
                    <div className="table-wrapper ">
                        <Table className="table-list" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell className='text-center'>NO</TableCell>
                                    <TableCell className='text-center'>NO CASE</TableCell>
                                    <TableCell className='text-center'>PROJECT TYPE</TableCell>
                                    <TableCell className='text-center'>CLIENT</TableCell>
                                    <TableCell className='text-center'>VESSEL</TableCell>
                                    <TableCell className='text-center'>CASUALTY</TableCell>
                                    <TableCell className='text-center'>PLACE OF SURVEY</TableCell>
                                    <TableCell className='text-center'>DATE SURVEY</TableCell>
                                    <TableCell className='text-center'>CASH ADVANCE</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.cashAdvance &&
                            <TableBody>
                                {this.state.cashAdvance.map((item, i) => (
                                    <TableRow
                                        key={i}>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>
                                            <a href={'/projects/detail/'+item.project_id} target='_blank'>
                                                {item.project_number}
                                            </a>
                                        </TableCell>
                                         <TableCell>{item.project_type}[{item.type_of_file}]</TableCell>
                                        <TableCell>{item.client_underwriter}</TableCell>
                                        <TableCell>{item.vessel_name}</TableCell>
                                        <TableCell>{item.casualty}</TableCell>
                                        <TableCell>{item.survey_comment}</TableCell>
                                        <TableCell>{item.date_survey_status && moment(item.date_survey_status,'YYYY-MM-DD').format("DD MMM YYYY")}</TableCell>
                                        <TableCell>
                                            Rp <NumberFormat thousandSeparator={true} decimalScale={2} value={item.amount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                            }

                        </Table>
                    </div>
                </div>

            </div>

        )

    }
}

export default ContentDashboardDetail;